import React from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';



const MuseumResourcesBooks = () => {
  return (
    <Tab.Pane eventKey="ResourceseBooks">
    {/* Images Large Start */}
    <section className="scroll-section" id="imagesLarge">
    <h2 className="cta-2">Resources & eBooks</h2>
    <div className="mb-n2">
         
          <Card className="mb-2">
            <Row className="g-0 sh-20">
              <Col xs="auto" className="h-100">
                <img src="/img/product/small/Af_Fest-2012-the-mask-with-stilts_thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
              </Col>
              <Col>
              <NavLink to="/TheTallMask?activeTab=Exhibitions">
                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    The Tall Mask – Ivory Coast
                    </Button>
                    <div className="text-muted text-truncate">
                    A member of the African Ivorian dance company of Yahayaha Koumate was performing the mask kwuya gblen-gbe ‘the tall mask’, the mask with stilts. He obviously was not dancing in his village in Ivory Coast for his fellow initiates in the men’s secret kono society ...
                      </div>
                  </div>
                </Card.Body>
                </NavLink>
              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-20">
              <Col xs="auto" className="h-100">
                <img src="/img/product/small/GuideToCollectionVolume1.png" alt="product-1" className="card-img card-img-horizontal sw-20" />
              </Col>
              <Col>
              <NavLink to="/GuidetoCollections?activeTab=Exhibitions">
                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Guide to Collections – Volume I, The SMA African Art Museum. (eBook)
                    </Button>
                    <div className="text-muted text-truncate">
                    The first in the series – I. The West Guinea Coast: Liberia, Sierra Leone, Ivory Coast, Guinea and Guinea Bissau, – is now on the web site and will be available in hard copy in July, 2012...
                      </div>
                  </div>
                </Card.Body>
                </NavLink>
              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-20">
              <Col xs="auto" className="h-100">
                <img src="/img/product/small/AfricanSculptureFromTheCollection_SMA-thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
              </Col>
              <Col>
              <NavLink to="/AfricanSculpture?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    African Sculpture from the Collection of the Society of African Missions. SMA Fathers, 1980. (eBook)
                    </Button>
                    <div className="text-muted text-truncate">
                    At first thought Museums and Missionaries seem an unlikely combination. The latter’s other activities and divergent interests would seem to leave them little time either to appreciate or collect the art objects of other cultures.
    It must be admitted that until the end of the 19th century and even into this century many Christian missionaries regarded the peoples and cultures among whom they worked as inferior to those of the West...
                      </div>
                  </div>
                
                </Card.Body>
                </NavLink>
              </Col>
            </Row>
          </Card>
    
          <Card className="mb-2">
            <Row className="g-0 sh-10">
    
              <Col>
              <NavLink to="/AfricanArtandEuropeanArtists?activeTab=Exhibitions">
                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Michel Leiris and Jacqueline Delange. African Art and European Artists.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>
              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
    
              <Col>
              <NavLink to="/ACulturalHeritagethroughArts?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Charles Bordogna.A Cultural Heritage through Arts.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
    
              <Col>
              <NavLink to="/BeautyandtheBeasts?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
    Robert J. Koenig. Beauty and the Beasts: Kifwebe Masks of the Songye, Luba and Related People.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
    
              <Col>
              <NavLink to="/IbejiTwinChildren?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
     Ibeji Twin Children
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
    
              <Col>
              <NavLink to="/GeledeMasks?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Gelede Masks, To Protect Man from Witches
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheLegendofChiWara?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    The Legend of Chi Wara
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
    
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/AkuaBaTheFertilityDolls?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
    Akua Ba The Fertility Dolls 
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/BonduMask?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Robert Farris Thompson. Bondu Mask – Learning from Beauty.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/BeadedArtistry?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Beaded Artistry
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheIvoirianBeads?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    Thimothy F.Garrand. The Ivoirian Beads.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">

              <Col>
              <NavLink to="/TheBlackStoolSymbolofPower?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    The Black Stool Symbol of Power
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheArtistryofTraditionalAfricanSculpture?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    The Artistry of Traditional African Sculpture
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheLegacyofAfricanArts?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    The Legacy of African Arts
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheAfricanArt?activeTab=Exhibitions">
                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                    William Siegman. The African Art.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
          <Card className="mb-2">
            <Row className="g-0 sh-10">
              <Col>
              <NavLink to="/TheSMAFathersandtheMuseumofAfricanArt?activeTab=Exhibitions">

                <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                  <div className="d-flex flex-column">
                    <Button variant="link" className="p-0 stretched-link text-start text-large">
                Fr. Thomas Wright. The SMA Fathers and the Museum of African Art.
                    </Button>
                  </div>
                </Card.Body>
                </NavLink>

              </Col>
            </Row>
          </Card>
        
        </div>
        </section>
        </Tab.Pane>
    
  );
};

export default MuseumResourcesBooks;

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import useCustomLayout from 'hooks/useCustomLayout';
import CarouselAutoplay from './CarouselAutoplay';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import Holiday from './Holiday';

const FooterBarHoliday = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'Holiday';
  const breadcrumbs = [{ to: '', text: 'Home for the Holidays' }];


  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });
return (
    <>

      <Row>
        <Col>
          {/* Carousel Start */}
          <section className="scroll-section" id="carousel">
          <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* Product End */}
                    {/* Product Start */}
                    <section className="scroll-section" id="Holiday">
                    <div className="mb-5">
            <h2 className="cta-2 small-title">Home for the Holidays.</h2>
            </div>
            <div className="cta-2 mb-5">
              <Holiday />
            </div>
          </section>
          {/* Product End */}
        </Col>


      </Row>
    </>
  );

};

export default FooterBarHoliday;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase19Content = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
   
 
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports – Case XIX</h4>
     
    

     </Card.Body>
   </Col>
 </Row>
      <Row className="g-0 align-items-center">
      <Col xs="12" md="4" className="ps-md-3 ps-0">
     <Card.Body>
          
          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case4-1.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>

         </Card.Body>
          </Col>
    
 
   <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       
     <h4 className="mb-4 fs-6 lead fw-bold">Bassa</h4>
       <p className="fs-5">
       This is a Bassa masquette of unusually dynamic form. Here, as in its large counterparts, the surface is eaten away by insects that live under the materials on the forehead. The asymmetrical eyes and the refined cheekbones, together with the angular sloping of the forehead in contrast to the rest of the facial structure, make this one of my treasured Bassas.
The Bassa of Liberia are best known for their nah wede mask, the ‘country devil’. It is marked by the opposition of the upper and lower parts of the head, the line of the forehead and coiffure forming an obtuse angle with the lower face and jaw. In modern examples this may almost be a right angle; in older examples, the angle is less marked. The coiffure is elaborate and always carved into a series of wide plaits or braided roles covering the head. There is a fine old full-sized nah wede in Case XIII.

       </p>
    

     </Card.Body>
   </Col>
 </Row>

      
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case4-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
             
                   <p className="fs-5">
                   The mask in the center is a good example of natural erosion due to age and/or use. A forger can produce imitations of wear by various means, so the collector must be wary. Notice the vertical ridge typical of Bassa masks on the center of the forehead.
</p>
      
                   </Card.Body>
                 </Col>
              </Row>

              <Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
  <p className="fs-5">
  I call this large family mask my ‘Elie Nadelman’ after the famous American sculptor who was avidly collected by the cosmetician Helena Rubenstein. Mme Rubenstein was a pioneer collector of African art.
</p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCase19Content;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const  NyonyosiStoneSculptureXIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XII: Nyonyosi Stone Sculpture</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">‘Nioniossi (sic) stone sculptures have been dated to the fourteenth century AD on the basis of oral history, with corroboration from inscriptions on the tombs of rulers. A people called Kurumba had established a state, Lurum, in about AD 1350 in the northern Yatenga region of what is now Burkina Faso. When the Mossi invaded the area, many peoples, including the Dogon, fled. The Kurumba remained in their ancestral homeland. They were recognized by the Mossi as ‘Nioniossi’ or ‘the settled people’, masters of the land. Sculptures like these were owned by families or clans. With the exception of the phallic sculptures, the stone figures are abstract or symbolic representations of a deceased person. They provide a home for the soul ‘that which returns’.
      </p>
       <p className="fs-6"> Werner Gillon. A Short History of African Art. chapter 5. p 87</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default NyonyosiStoneSculptureXIIcontent;

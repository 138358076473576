import React from 'react';
import { Card } from 'react-bootstrap';
import Glide from 'components/carousel/Glide';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const CarouselAutoplay = () => {
  return (
    <Glide
      noControls
      options={{
        gap: 0,
        rewind: false,
        
        type: 'carousel',
        autoplay: 7000,
        perView: 1,

        breakpoints: {
                    400: { perView: 1 }
        },
      }}
    >
 
        <Glide.Item key="smallGutters.1">
            <Card className=" sh-40 ">
               <img src="/img/banner/banner_new.webp" className="card-img h-100" alt="card image" />
             </Card>
          </Glide.Item>
    
             <Glide.Item key="smallGutters.3">
               <Card className=" sh-40 ">
                  <img src="/img/banner/banner_new_final_2.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.4">
               <Card className="sh-40 ">
                  <img src="/img/banner/banner_new_final_4.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.5">
               <Card className="sh-40 ">
                  <img src="/img/banner/huts_Niger_sma.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.6">
               <Card className="sh-40 ">
                  <img src="/img/banner/banner_new_2.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item> 
             <Glide.Item key="smallGutters.7">
               <Card className=" sh-40 ">
                  <img src="/img/banner/church_banner.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.8">
               <Card className=" sh-40 ">
                  <img src="/img/banner/banner_zamb.webp" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             
              </Glide>
  );
};

export default CarouselAutoplay;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const AnimalSpiritsVIIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case VIII: Animal Spirits</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-VIII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <p className="fs-6">The spirit world of African Animism is a continuum. Spirit, from God, is in human beings, animals, birds, insects, plants, air, water. The masks these spirits inhabit may be rendered visible in wood by any animate form or by a composite of two or more animal or human referents. The mask proper – the face or head, may be one kind of creature; the superstructure may be another. The salient characteristics of the animal spirit he is impersonating are expressed by the dancer.</p>
       <p className="fs-6">In this case we find several popular mask types popular among the Nuna and Nunuma, sub-groups of the Gurunsi. From left to right: the snail (my opinion), warthog, chameleon – identified by his curly tail, crocodile – with menacing teeth and bedroom eyes, and the hyena or lion.
</p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default AnimalSpiritsVIIIcontent;

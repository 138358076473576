import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCaseIXContent = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
      


   <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports – Case IX</h4>
        
    

     </Card.Body>
   </Col>
   
 </Row>
      <Row className="g-0 align-items-center">
      <Col xs="12" md="4" className="ps-md-3 ps-0">
      <Card.Body>
          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case9-11.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
          </Card.Body>

</Col>
   <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="mb-4 fs-6 lead fw-bold">Dan Complex</h4>
       <p className="fs-5">The ‘tam-tam drum of the Dan/Guere is in this exhibition because of the very well-carved passport masks on the handle. It was collected in the field by Paolo Morigi, a pioneer Italian connoisseur of African art, who discovered it in the Tapita village, Liberia.
      </p>
    

     </Card.Body>
   </Col>
   
 </Row>

      <Row className="align-items-center">
         

    
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case9-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
             
                   <p className="fs-5">The forehead of the leather-covered mask on the right is embellished with three cowry shells. These are not found in the waters of the Atlantic; they were imported from the Indian Ocean, on the other side of the African continent. Cowries are not only decorative, they are a symbol of fertility because their shape suggests a womb. For Africans, fertility means wealth, so they also symbolize personal prestige. In some parts of Africa cowries were used as currency until the colonial period.
</p>
      
                   </Card.Body>
                 </Col>
              </Row>

              <Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9-4.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCaseIXContent;


import React, { useEffect, useState } from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import HtmlHead from 'components/html-head/HtmlHead';
import useCustomLayout from 'hooks/useCustomLayout';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import CarouselAutoplayMuseum from './CarouselAutoplayMuseum';
import MuseumExhibitions from './MuseumExhibitions';
import MuseumAcquisitions from './MuseumAcquisitions';
import MuseumHome from './MuseumHome';
import MuseumResourcesBooks from './MuseumResourcesBooks'
import MuseumSchoolsProgram from './MuseumSchoolsProgram';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Clamp from 'components/clamp';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import { useParams,useLocation } from 'react-router-dom';
import MuseumNav from './MuseumNav';



const MoreItemToggle = React.forwardRef(({ onClick, parentClassname }, ref) => (
  <a
    ref={ref}
    className={classNames('btn btn-icon btn-icon-only', {
      'btn-foreground mt-2': parentClassname.indexOf('nav-tabs-title') === -1,
      'btn-background pt-0 bg-transparent pe-0': parentClassname.indexOf('nav-tabs-title') > -1,
    })}
    href="#/"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <CsLineIcons icon="more-horizontal" size="20" />
  </a>
));

MoreItemToggle.displayName = 'MoreItemToggle';
// eslint-disable-next-line no-unused-vars
const ResponsiveNav = React.forwardRef(({ className, children }, ref) => {
  const innerRef = React.createRef();
  const [collapseIndex, setCollapseIndex] = useState(children.length);
  const [childSteps, setChildSteps] = useState([]);
  const { width } = useWindowSize();



  const setSteps = () => {
    const steps = [];
    const currentChildren = innerRef.current.children;
    console.log("log currentChildren ",currentChildren);
    let totalWidth = 0;
    for (let i = 0; i < currentChildren.length; i += 1) {
      totalWidth += currentChildren[i].clientWidth;
      steps.push(totalWidth);
    }
    setChildSteps(steps);
  };
  const checkCollapseIndex = () => {
    const navWidth = innerRef.current.clientWidth;
    let checkedCollapseIndex = childSteps.filter((x) => x < navWidth).length;
    if (checkedCollapseIndex < children.length) {
      checkedCollapseIndex = childSteps.filter((x) => x < navWidth - 50).length;
    }
    if (checkedCollapseIndex !== collapseIndex) {
      setCollapseIndex(checkedCollapseIndex);
    }


  };
  useEffect(() => {
    setSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width && childSteps.length > 0) {
      checkCollapseIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);



  return (
    <div ref={innerRef} className={className}>
      {children.slice(0, collapseIndex)}
       {collapseIndex !== children.length && (
        <Dropdown className={classNames('nav-item ms-auto pe-0')} alignRight>
          <Dropdown.Toggle as={MoreItemToggle} parentClassname={className} />
          <Dropdown.Menu>{children.slice(collapseIndex, children.length)}</Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
});


const MuseumPage = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'Under Construction ';
  const breadcrumbs = [{ to: '', text: 'Museum' }];
  const [activeTab, setActiveTab] = useState("Home");
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    const activeTabParam = params.get('activeTab');
    if (activeTabParam) {
      setActiveTab(activeTabParam);
      console.log("log activeTabParam ",activeTabParam);
    }
  

  }, [location.search]); 

  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>

             {/* Carousel Start */}
             <section className="scroll-section" id="carousel">
          <Row >
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplayMuseum />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* main tab content start */}

          <Tab.Container activeKey={activeTab} defaultActiveKey="Home">
          <Nav variant="tabs" className="nav-tabs-title nav-tabs-line-title" activeKey={activeTab} >
          <MuseumNav/>
          </Nav>
    
            <Tab.Content>
            
            <MuseumHome/>

              <MuseumExhibitions/>

              <MuseumAcquisitions/>
             
              <MuseumResourcesBooks/>
                <MuseumSchoolsProgram/>

            </Tab.Content>
          </Tab.Container>
          {/* main tab content end */}
        </Col>
      </Row>
    </>
  );
};

export default MuseumPage;

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import useCustomLayout from 'hooks/useCustomLayout';
import CarouselAutoplay from './CarouselAutoplay';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import Whoweare from './Whoweare';

const AboutPage2 = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'Who We Are';
  const breadcrumbs = [{ to: '', text: 'Who We Are' }];


  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });
return (
    <>

      <Row>
        <Col>
          {/* Carousel Start */}
          <section className="scroll-section" id="carousel">
          <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}


     

          {/* Product Start */}
          <section className="scroll-section" id="whoweare">
            <h2 className="small-title">Who We Are</h2>
            <div>
              <Whoweare />
            </div>
          </section>
          {/* Product End */}
        </Col>

      </Row>
    </>
  );

};

export default AboutPage2;

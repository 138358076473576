import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Liberiacontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
   
 
          <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-3">Liberia: A Land Old and New</h4>

              <p className="fs-5">
              The new exhibition will open on Sunday, October 9, 3-5 P.M in the SMA African Art Museum. Liberia: A Land Old and New celebrates a hundred years of the Society of African Missions in Liberia with masks, figure sculptures, textiles and costumes from the land founded by freed American slaves in 1847. Admission is free; please join us. Refreshments will be served in the Cloister Garden from 4:30 to to 5 P.M.
              </p>
           

            </Card.Body>
          </Col>
        </Row>
              

              

        

      </Card>
    </>
  );
};

export default Liberiacontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase8Content = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
        
          

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Passports – Case VIII</h4>
              


            </Card.Body>
          </Col>
        </Row>

        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
        <Card.Body>
            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Passports_Case8-11.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
            </Card.Body>
          </Col>
          

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
            <h4 className="mb-4 fs-6 lead fw-bold">Toma</h4>

              <p className="fs-5">This mask looks like terra cotta but it is actually stone. The simplicity of the form allows the material to express itself. The mask has a monolithic presence that is the essence of the Toma spirit.
              </p>


            </Card.Body>
          </Col>
        </Row>

        <Row className="align-items-center">
          

        </Row>
        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case8-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>

              <p className="fs-5">This powerful and mysterious family mask is one of the oldest in the exhibition. Its forehead is covered with fetish material, including a remnant of red cloth. Another fetish bundle is on the verso.
              </p>

            </Card.Body>
          </Col>


        </Row>

        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case8-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>

              <p className="fs-5">The strong form of this nyangbai mask, the feminine counterpart of the enormous lana mask of the Toma people, has a special place in my heart. The typical nyangbai has a high domed projecting face, with a strong nose and no mouth. The passport and personal masks in this case are a virtual survey of variations on the Toma mask form.
              </p>


            </Card.Body>
          </Col>


        </Row>
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCase8Content;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const AfricanSculpturecontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">African Sculpture from the Collection of the Society of African Missions

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      
   
      <Row className="g-3 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
    <Card.Body>
      <Col className="d-flex justify-content-center">
        <div className="me-1 mb-1 d-inline-block">
        <a href="/documents/AfricanSculptureFromTheCollection_SMA.pdf" download="SMA_AfricanArt_vol1.pdf">
          <img src="/img/museum/AfricanSculptureFromTheCollection_SMA-main.jpg" className="img-fluid roundeAfricanSculptured-xl" alt="card image" />
          </a>
        </div>
      </Col>

    
      <Col className="d-flex justify-content-center mt-2">
        <a href="/documents/AfricanSculptureFromTheCollection_SMA.pdf" download="SMA_AfricanArt_vol1.pdf" className="btn btn-primary">
          Download PDF
        </a>
      </Col>
    </Card.Body>
  </Col>
</Row>


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Foreword by Rev. Thomas E. Hayden, SMA American Province Society of African Missions</p>
            <p className="fs-5">At first thought Museums and Missionaries seem an unlikely combination. The latter’s other activities and divergent interests would seem to leave them little time either to appreciate or collect the art objects of other cultures.</p>
            <p className="fs-5">It must be admitted that until the end of the 19th century and even into this century many Christian missionaries regarded the peoples and cultures among whom they worked as inferior to those of the West. The artifacts of these peoples were often judged ugly and those having any connection with so-called pagan religious practices were often collected and burnt.</p>
            <p className="fs-5">It is to the credit of the Society of African Missions (founded at Lyons, France, in 1856) that it has remained true to the openness and vision of its Founder, Bishop Melchior de Marion Brésillac efforts were made early on in the history of the Society to collect and preserve artifacts from West Africa. Thanks to the untiring efforts of some of those early missionaries, many fine pieces in wood, ivory, brass, bronze and other metals were preserved and displayed at the SMA center at Lyons. Later on another fine collection was assembled by SMA Fathers in Holland.</p>
            <p className="fs-5">Bishop de Brésillac was very sensitive to the unique beauty and character of each culture and attempted to imbue his priests with an appreciation of cultures other than their own. He told his men that they could “remain French only in so far as it does not prevent us from being perfect missionaries.” He urged them to study the local languages and to make every effort to appreciate the customs of the people wherever these were not in clear contradiction to the Gospel of Jesus Christ. He warned against European pride and a superiority complex.</p>
            <p className="fs-5">Among the spiritual sons of Bishop de Brésillac, who heeded his admonitions concerning adaptation to and appreciation of the local African cultures, was Father Francis Aupiais (1877-1945). Throughout his life he exhibited a lively interest in and study of two forms of African popular culture: literature and the plastic arts. In fact, his biographer characterized the French missioner as “the man of Black Art.” He appreciated African art not only for itself but also as a mirror of the souls of the men who conceived it and gave it life. Father Aupiais was personally responsible for expositions of Benin art in France during the 1920’s. He condemned both extremes: European contempt and exaggerated and uncritical praise for the so-called “primitive” art of Africa. He was quick to point out the high level of artistry so often evident in the metal and wooden artifacts produced by the Africans with whom he worked.</p>
            <p className="fs-5">In Nigeria the Church became an active patron of the arts through the interest and efforts of such men as SMA Fathers Patrick Kelly, Kevin Carroll and Sean O’Mahony. Father Carroll is justly renowned for his long-term (35 years) abiding interest, encouragement and stimulation offered to artisans among the Yoruba people of Western Nigeria. He has published a book and numerous articles on African art. In addition, all these priests have interested African artists in producing Christian art making use of local materials and traditional art forms.</p>
            <p className="fs-5">From what has been said, then, it is clear that the Society of African Missions in the United States is following a long-standing SMA tradition in its efforts to acquaint Americans with the beauty and richness of African art and culture. To appreciate the art of a people is to have a deeper understanding of that people.</p>
            <p className="fs-5">With its origins in Europe and North America and its focus in Africa, SMA seeks to be a bridge between cultures and peoples, to build international understanding and appreciation. It is our hope that in some small way our Museum may prove a means of strengthening American understanding of Africa and help to forge bonds of world familyhood and peace.</p>

            </Card.Body>
          </Col>
        </Row>
       
        



      </Card>
    </>
  );
};

export default AfricanSculpturecontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const IbejiTwinChildrencontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Ibeji Twin Children
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">The Yoruba people of Nigeria look upon the birth of twins as a special blessing; they also have one of the highest rates of twin births in the world. Due to the high mortality rate among twins, Yoruba mothers are frequently faced with the death of a cherished child. In view of this reality, mothers often commission a carver to create a small wooden figure -ere ibeji – to serve as a repository for the soul of the deceased. The figures are cared for by the mother as her living children. She believes that twins have the power to bring good fortune, wealth, and blessings; hence, twin surrogate statues are treated with respect and great attention. Statues are ritually oiled and washed in special herbal baths. They are symbolically rubbed with food and occupy a special place in the household. At times of festivals, they are held by the mother and danced.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">twins: two bodies one soul</h4>

            <p className="fs-5">Such care may seem very strange to us from the viewpoint of another culture so further explanation is necessary. Twins are believed to share one soul. Since a surviving twin cannot live with half a soul, the statue may contain the other half soul. Moreover, the line between life and death is not absolute for the Yoruba. There is a close interplay between the world of the spirits and the material world. Spiritual forces are normally called upon to intercede for the living. The care of a twin makes great sense to the Yoruba for a twin can produce blessings; similarly, the neglect of the deceased twin may cause the living to endure its wrath. Hence, an angry deceased twin may cause illness, sterility, still-births, and various misfortunes.
            
            </p>
            <p className="fs-5"></p>
            <h4 className="mb-4 fs-6 lead fw-bold">Washing, feeding, rubbing
            
            </h4>
            <p className="fs-5">The frequent washing, feeding, and rubbing of the statues account for the worn and patined surfaces. In fact, the surface is a sign of its authenticity. Among the favorite embellishments are indigo blue, often rubbed into the coiffure. Blue is a spiritual color (consider the skies) and the head is the source of spiritual transformation. Reddish paste may be rubbed on the body both as a protection from insects and as a way to charge the figure, red being a hot color reflective of power and energy. Beads and beaded cloth may adorn an ibeji as a sign of prestige. Metal rings and cowries are symbols of wealth.</p>

            <h4 className="mb-4 fs-6 lead fw-bold">Death is not a barrier</h4>

            <p className="fs-5">Nevertheless, each ibeji is personal and shows the love and attention of the caregiver, who may be a brother compassion and suffering. Imagine the mother whose child has been lost to death now once more able to hold her infant in the form of the ibeji, able to wash and feed it, dress it and sing to it. Death is not a barrier to the Yoruba mother; her family is together because of the presence of the ibeji child.</p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default IbejiTwinChildrencontent;

import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import GlideGallery from 'components/carousel/GlideGallery';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const HomeCarousel = () => {
  const galleyItems = [
    {
      large: '/img/product/large/church-1.jpeg',
      thumb: '/img/product/small/church-1.jpeg',
    },
    {
      large: '/img/product/large/church-2.jpeg',
      thumb: '/img/product/small/church-2.jpeg',
    },
    {
      large: '/img/product/large/church-5.jpeg',
      thumb: '/img/product/small/church-5.jpeg',
    },
    {
      large: '/img/product/large/church-4.jpeg',
      thumb: '/img/product/small/church-4.jpeg',
    },
    {
      large: '/img/product/large/church-3.jpeg',
      thumb: '/img/product/small/church-3.jpeg',
    },
  ];

  return (
    <>
      <Card>
        <GlideGallery>
          <GlideGallery.LargeItems>
            {galleyItems.map((item, index) => (
              <GlideGallery.Item key={`half.boxed.large.${index}`}>
                <img alt="detail" src={item.large} className="responsive border-0 rounded-top-start rounded-top-end img-fluid mb-3 w-100 sh-50" />
              </GlideGallery.Item>
            ))}
          </GlideGallery.LargeItems>
          <GlideGallery.ThumbItems className="mb-3">
            {galleyItems.map((item, index) => (
              <GlideGallery.Item key={`half.boxed.thumb.${index}`}>
                <img alt="thumb" src={item.thumb} className="responsive rounded-md img-fluid" />
              </GlideGallery.Item>
            ))}
          </GlideGallery.ThumbItems>
        </GlideGallery>

        <Card.Body>
          <h4 className="mb-3">Welcome to S.M.A.</h4>
          <div>
            <p>
             SMA is a worldwide organization of Catholic missionaries. We are priests, brothers, and lay men and women. Our more than 1,000 missionaries hail from five continents and joyfully witness to the Gospel of Jesus Christ
               throughout Africa, and to people of African descent wherever they are.
               The American Province of SMA was established in 1941 and is headquartered in Tenafly, New Jersey. In the United States, SMA has a mission presence in the Archdioceses of Newark (NJ), Boston, and Washington (D.C.). In Africa, SMA missionaries serve in seventeen countries.
               The initials “SMA” stand for the Society’s name in Latin: Societas Missionum ad Afros.
                 </p>
          </div>
        </Card.Body>

      </Card>
    </>
  );
};

export default HomeCarousel;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BuraSudanandNigerXIIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XIII: Bura, Sudan and Niger Delta</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVIII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The archeological site of Bura-Asinda-Sika is a necropolis located northwest of Niamey, the capital of Niger. Bura relics have also come to light on the other side of the Niger River, in Burkina Faso. This is the territory of the ancient Songhai Empire, founded in 846 AD.</p>
       <p className="fs-6">The excavations of the Bura site date to 1975, when a man accidentally found two Bura heads and gave them to his children to play with (Schaedler, 1997). A large number of terra cotta works were found in grave sites and assigned to a period dating from the third to the tenth and eleventh centuries AD. These include conical receptacles for grave goods, half figures, equestrian figures and heads Typically, Bura heads are flat, with a longitudinal bulge in the center of the face, as in our charming half figure.</p>
       <h4 className="font-weight-bold">Kingdoms of the Sudan</h4>
       <p className="fs-6">‘In the territories which constitute today’s states of Senegambia, Mauretania, Mali and Burkina Faso, there developed an early African civilization which produced a West Sudanic culture, with distinctive art styles. Although some ethnic groups belonging to this culture migrated from their original habitats, stylistic features which developed in antiquity can be recognized in art works produced into the twentieth century.
       The Dogon have been linked with the Voltaic people for linguistic reasons, as have the Senufo, who may have migrated to their present area from the Inland Niger Delta. The snake, turtle and crocodile are common denominators in the art of Djenne, the Dogon and Senufo.</p>
       <p className="fs-6">Ghana was a powerful kingdom when the north of Africa was Islamicized in the eighth century AD. The great mosque of Jenne (Djenne), shown in the photograph to your left, dates back to the fourteenth century. Built of mud and wood, it is renewed annually.</p>
       <p className="fs-6">The Kingdom of Ghana was succeeded by the Empire of Mali, which was regarded as an equal by the North African states, the Kingdom of Portugal and mediterranean countries. Mali began its decline in the mid-fifteenth century under pressure from the Tuareg and the Songhai under their great leader Sunni Ali (1464). Toward the end of the seventeenth century, the Bamana, ended their subservience to the Empire of Songhai and founded their own states: Segou and Kaarta. They in turn were overcome by the Fulani in the nineteenth century.</p>
       <h4 className="font-weight-bold">The Inland Niger Delta</h4>
       <p className="fs-6">Karl Ferdinand Schaedler’s Earth and Ore is one of the best reference works on African pottery and metalwork. The Inland Niger Delta, located in the present day Republic of Mali, is critically important in the history of the western Sudan because it was important in the development of the early West African kingdoms: Ghana, Mali, Songhai and Bambara. Schaedler points out that its climate and topography are similar to those of the Nile Valley in Egypt. ‘Every year from June to December, large areas of this territory are inundated by the rivers Niger and Bani… On the hills of the Delta Toguere, to which the population withdraws during the period of inundation, sculptures and vessels have been found for over fifty years…These have come overseas only during the last two decades (from 1977-1997). Of the different styles named after their respective archeological sites, Djenne, Bankoni, Segou (Bamana), Tenenkun, Djenne is the most important.</p>
      

     </Card.Body>
   </Col>
   </Row>
     );
};

export default BuraSudanandNigerXIIIcontent;

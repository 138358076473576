import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCaseXVIContent = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
        

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Passports – Case XVI</h4>
              

            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case1-12.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>

          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">The Dan Complex</h4>
              <p className="fs-5">Passport masks are made by every culture in West Africa, but the Dan complex produces more than any other people. The beaked mask could well be the first passport mask I collected. Just behind that, with a rose-tinted patina, is my most recent acquisition.</p>


            </Card.Body>
          </Col>
        </Row>

       
        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case1-21.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">‘Passport’ Masks</h4>

              <p className="fs-5">
                West African miniature masks, too small to be worn, replicate the forms of masks worn in performance. Like their larger counterparts they are powerful charms, filled with spirit, with potential for good or evil. But while the full-sized masks are made for display in public ceremonies, the passport masks are not performed and are usually hidden from prying eyes. They may be kept in the compound or on a personal shrine. They can be worn on the arm or around the neck as an amulet to protect the owner when he is hunting or making war. They are carried about the person when traveling; hence the term ‘passport’ mask. Another source for the term ‘passport’ may be their use as ‘stepping stones’ at Dan secret society initiations (Liberia/Ivory Coast). Small masks are placed as obstacles on the path leading to the meeting place; aspiring members must pay to have them removed.</p>

            </Card.Body>
          </Col>
        </Row>

        <Row className="g-0 align-items-center">

          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case1-31.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">Dan Complex</h4>

              <p className="fs-5">
                The Dan Complex includes the Dan, Mano, Kpelle, Grebo, and Loma peoples of Liberia as well as the We (Gwere), Toma, Tura and Mau of Ivory Coast. The Dan also inhabit Ivory Coast around the border with Liberia. The Bassa are located mainly in Sierra Leone. These groups have rich masking cultures, each with dozens of types exhibiting a bewildering variety of forms. Among the more familiar Dan masks are the female deangle, with smooth features in an oval face; the kaogle, with projecting triangular cheekbones; the bugle, with wide open round eyes, which can function either as the ‘runner’ or the ‘fire warden’. A Dan mask may have a life of its own. A bugle, after years of performance as a runner or fire warden, may advance to the rank of ‘judge’ or ‘senior’ mask, without changing its form or iconography. Masks may be traded between groups. Or, a Mano dancer may commission a mask from a Dan carver whose work he admires.  </p>

            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCaseXVIContent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Cattle = () => {
  return (
    <>
      <Card>
        <Row className="g-0 align-items-top">
          <Col xs="12" md="4">

                  <img src="/img/banner/cattle.png" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-0 ps-0">
            <Card.Body>
                <p><h4><b>Cattle Farming Rehabilitation in the Congo.</b></h4></p>
              <p>
              <h4>
              How a Cattle Farm, brought attention on the need of parish agriculture.</h4>

At one point the Cattle project set in the Democratic Republic of
Congo produced enough funds to build the current Headquarters. The
beginning of this story goes back to 1952, when the Society of African
Missions Father (SMA) came to the Democratic republic of Congo to develop
cattle farming alongside their missionary work.</p>
<p>
The land was meant to be an extra source of income and assist with
financially sustaining local missions and create employment for locals.
However, after personnel decline and a pandemic that affected 34 Cows.
The Kinsele rehabilitation needs some assistance from the US (SMA)
province.
</p>
<p>
In the early the General council decided to leave the Diocese of Kikwit
and created a new mission experience in Kinshasa. Not wanting to get ride
of the cattle the fathers decided to transfer the cows to a new farm about
200 km from Kinshasa which is about 125 miles away called Kinsele.


With management, financials, and land ownership prosing as huge
problems the DRC District Council has proposed a 5- year plan to move the
mission work and cattle farming forward. The first solution they found was
training suitable candidates who could run the farm with studies in
agriculture and veterinary knowledge, the next solution was to purchase
more cows. The DRC District Council plans includes purchasing eighty cows
and after just four years they believe they can grow to a total of four
hundred.</p>


</Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" className="ps-md-3 ps-0">
                   <Card.Body>
                   <p>
                    After revamping the cattle faming project and proposing to purchase
more cows the DRC District Council believes it will allow the farm, the
missionary, and the land to become self-reliant. This plan of action will allow
profits for the missionary and the ability to resale meat to the locals at a

2
more affordable rate as the nearest farm is a great distance away. Once that
is accomplished the DRC District Council will begin to apply money back into
the missionary, and retirement funds. As the Priest coming from Congolese
are now in mission but may consider retirement by the end of the 5-year
plan.

The plan to assist the new priest in revamping the farm and
purchasing the land they currently rent, will allow them to establish stability
and maximize their returns. The requested for assistance brings another
mission closer to home for the SMA family. We can’t wait to see the
progress that comes along with the missionary work in Kinsele, the
Democratic Republic of Congo and  we offer them all of our support. (are
we supporting this for sure) Click Here to Donate? 
</p>
                   </Card.Body>
                 </Col>
                
               </Row>

      </Card>
    </>
  );
};

export default Cattle;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PaxRwandacontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
   
 
          <Col xl="21" md="25" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-3">Pax Rwanda: Embroideries by women of Savane Rutongo</h4>

              <p className="fs-5">The exhibition will open on Sunday, October 9, 3-5 P.M in the SMA African Art Museum. Pax Rwanda: Embroideries by the Women of Savane Rutongo is comprised of twenty exquisite embroideries by a cooperative of Hutu and Tutsi women who work together to survive in the aftermath of the horrific genocide of 1994. A seminar on contemporary life in Rwanda will be presented in the African Art Museum from 3:30 to 4:30. Admission is free; please join us. Refreshments will be served in the Cloister Garden from 4:30 to to 5 P.M.
              </p>
           

            </Card.Body>
          </Col>
        </Row>
              

              


        

      </Card>
    </>
  );
};

export default PaxRwandacontent;

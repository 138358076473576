import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const INRIcontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
   
 
          <Col xl="10" md="25" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-3">INRI: Crosses and Crucifixes</h4>

              <p className="fs-5">Through April 15, The American Province of SMA will exhibit crosses from its permanent collections in the Chapel Wing of the African Art Museum. Although these crosses and crucifixes are Christian in iconography they are African in style and reflect the missionary zeal of the SMA international to help the neediest people in the most challenging places in the world. The exhibition includes a number of works from Ethiopia, a Christian nation since the fifth century AD. Featured are processional crosses, blessing crosses and neck crosses, all lovingly made for public as well as private devotions. From Nigeria there is a mmo mask of the Igbo people with a superstructure bearing images of the crucified Christ, the Virgin Mary, Saint Barbara and Mamy Wata, a seductive water spirit originating in India, who now enjoys a huge cult following in West Africa and the diaspora.
              </p>
           

            </Card.Body>
          </Col>
        </Row>
              

              <Row className="g-0 align-items-center">

<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p  className="fs-5">Bruce P.O. Onobrakpeya, an Urhobo from Nigeria, is one of Africa’s most important contemporary artists. We are showing three prints from a set of Fourteen Stations of the Cross, made in 1969, when Nigeria was fighting for its independence from Great Britain. From Fr. Kevin Carroll’s SMA workshop in Ekiti, Nigeria we show a Crucifixion and a Virgin Mary by George Bandele of Osi Ilorin, son of Areogun, one of the greatest traditional carvers in Yorubaland. Three very small but precious works of art are crucifixes from the Democratic Republic of Congo Kingdom of Kongo, possibly dating from the 16th century A.D. The rulers of the Kingdom of Kongo wore pectoral cosses during the brief period when Christianity was the official religion of the empire. Afterwards they wore them as power symbols.
  </p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p className="fs-5" >The SMA African Art Museum is open seven days a week, 10 A.M. to 5 P.M. The museum will be closed on Good Friday.
  </p>

  </Card.Body>
</Col>
</Row>

        

      </Card>
    </>
  );
};

export default INRIcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MaliTodaycontent = () => {
  return (
    <Card>
      <Row className="g-0 align-items-center">
        <Col xs="12" md="12" className="ps-md-3 ps-0">
          <Card.Body>
            <h4 className="mb-4">Photographs by Richard P. Wedeen, M.D.</h4>
            <p className="fs-5">574 South Forest Drive, Teaneck, NJ 07666</p>
            <p className="fs-5">dwedeen@attglobal.net</p>
            <p className="fs-5">(201) 692-0836</p>
          </Card.Body>
        </Col>
      </Row>

      <Card>
        <Row className="g-0 align-items-center">
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <p className="fs-5">
                These photographs of Mali were taken in November and December 2011 by Richard P. Wedeen, M.D. They were intended to capture the vitality of the contemporary indigenous culture. Handmade textiles were collected along with the photographs of everyday life in Mali. Although these textiles are often treated as souvenirs, they represent the finest artistic traditions in continuous use in Africa. Unlike modern art that is dominated by individualism, traditional textiles hold tenaciously to communal designs modified by subtle changes introduced by individual artisans within socially defined limits of color and style. They reflect community traditions more than individual invention. Dr. Wedeen has done similar photography and textile collecting in India, Laos, Cambodia, Guatemala, Mexico, Peru, and Bolivia.
              </p>
              <p className="fs-5">
                Dr. Wedeen is a Professor of Medicine, and Professor of Preventive Medicine and Community Health at the University of Medicine and Dentistry of New Jersey – The New Jersey Medical School, Newark, NJ. As a physician-scientist, he has used photomicroscopy professionally for five decades. His photographs of indigenous people have won three First Place Awards and a Best in Show Award in New Jersey.
              </p>
            </Card.Body>
          </Col>
        </Row>

        
      </Card>
    </Card>
  );
};

export default MaliTodaycontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const FormandFunctionXXIIXXIIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XXII-XXIII: Form and Function</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XXII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">Around the world and through time, pottery has been made in countless variations on a few basic forms: the slab, the plate, bowl, globe, vase with curved or straight sides…. In sub-Saharan Africa terra cotta wares are used to store liquids and solids. In many areas jars with covers are used to brew alcoholic beverages. Pottery in various forms is used for individual or communal eating and drinking for purposes of sustenance and social, political or religious ceremony. In some cultures it is the repository of the remains of the dead or his possessions.</p>
       <p className="fs-6">To a degree, the use of a pot dictates its form and structure. The course clay body is porous when fired. It allows water to very slowly evaporate through it. This keeps stored liquids cool. A globular body and constricted neck keep precious water, beer or wine from spilling. The mouth may be wide to facilitate dipping or finished with a narrow neck and spout to permit pouring.</p>
       <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XXIII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
      <p className="fs-6">A roughened surface where the pot is held prevents it from slipping out of the hands. A rounded bottom pressed into a depression in the earth will keep a large bowl or jar upright. The pointed burls on Lobi storage jars multiply the surface area to increase evaporation and cooling. Jars with ‘pumpkin lids’ keep the contents safe from all kinds of damage. The walls of a pot may vary in thickness, depending on its size, shape and use. They are thicker, up to an inch or thinner, down to 1/8 inch or even 1/16 inch depending on the demands of manufacture and the skill of the potter.</p>

     </Card.Body>
   </Col>
   </Row>
  );
};

export default FormandFunctionXXIIXXIIIcontent;

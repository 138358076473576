import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MuseumPassportsMasquettesContent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
          <Col xs="12" md="4">

</Col>

          <Col  className="align-center">

                  <img src="/img/museum/poster2.jpg"   alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
   
 
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Diminutive Spirits. Passports and Masquettes from the collection of Albert Stanziano.</h4>

              <p>
              Why would an African make a mask that’s too small to wear? So that he can carry it around with him wherever he goes, especially into the bush where the wild spirits are. One hundred passport, miniature and family masks, ranging from tiny – less than 1” to mid-sized – 6”, are on view through July 30th, 2013. At the same time, the museum is exhibiting full-sized masks from its permanent collections so the visitor can compare a full-sized Toma nyangbai mask three feet high with its little brothers, only two inches high. One may be a lot bigger and heavier than the other but they are equally awesome in appearance. And both in the wing gallery adjacent to the beautiful St. Joseph’s Chapel the museum is showing crosses and crucifixes from its extensive Ethiopian collections. These symbols of eternal salvation were- and are worn as protective amulets, just like the animistic passport masks. Summer is group visit time at the SMA African Art Museum. The adjacent cloister garden is in full bloom and a great place to have a brown bag lunch. Director Bob Koenig gives all the tours and welcomes pre-schoolers to senior citizens, 10 A.M. to 4 P.M., in groups of five to fifty. Tours can be custom designed for you and can be 30 to 90 minutes in length. Individual admissions is free; we suggest a contribution of $1.000 per person for a talk/tour. For further information or to book a tour call Mr. Koenig at (201) 894-8611 or email him at museum@smafathers.org
              </p>
              <p>
              Robert J. Koenig, Director
              </p>

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="12" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">Explore the exhibition case by case</h4>

               
                     <div className="mb-3">
                     <NavLink to="/passportsCaseIV" >
         <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase6" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase5" >     
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase7" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase8" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseIX" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseXVI" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase17" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                       <NavLink to="/passportsCase18" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                        <NavLink to="/passportsCase19" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                         <NavLink to="/passportsCase20" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>

                         <NavLink to="/passportsCase21" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
      </div>
           

                   </Card.Body>
                 </Col>
                  
               </Row>

               <Row className="g-0 align-items-center">
   
 
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Introduction</h4>

       <p>
       In the western tradition, a mask is a covering for all or part of the face that is worn to conceal a person ’s identity. But for the indigenous peoples of Africa, a mask is the objective correlation of a spiritual force or power. It is not a representation of the spirit; it is a presentation of the iconic attributes of the spirit as imagined by the collective intellectual and emotional needs of a group of people sharing a physical, economic and political locus. But most African masks are made to be worn — to be danced in a performance that may be described as cosmic theater. They are large enough to conceal the face, head, neck and shoulders or even the whole body of the wearer. Some have integral gloves and slippers to cover the hands and feet. The masquerader is no longer himself, and he must not be recognized as a human being. He is the spirit, perceived as such as long as he wears the mask.
       </p>
       <p>
Masks that are too small to be worn fall into three categories: personal, miniature and family masks.
Personal masks do not replace the larger masks owned and worn by their owners. Rather, they are highly stylized portraits of the individual human being, or, to be more precise, of his unique psychological entity, imbued with the forces of a larger communal psyche.
Miniature masks may serve as repositories of the spiritual powers of larger masks that have been damaged or destroyed through use and over time. Once ‘materialized’ the power of a mask does not die; therefore, it must be dealt with by living human beings in one way or another. The miniature serves as a place of abode for that power.
Family masks are kept on the shrine in the compound. They protect all the members of the owner ’s extended family or clan.
</p>
       <p>
Both personal and miniature masks may serve as guardians, protecting their owners from black magic and the danger of ‘nyama’, the huge collective force of the spirit world. The Bassa have both types and sometimes it is difficult to distinguish between the two.
Among the Dan and Mano a ‘ma’ or passport mask validates a man ’s right to commission, own and wear his own full-sized dance mask. In addition, because it is small enough to be worn or carried on his person, it guards him whenever and wherever he needs protection, from his initiation into the men ’s secret society until his death, through the whole of the physical, intellectual and emotional space of his adult life.
Many of the masks in this exhibition have a shiny surface that testifies to the touch of hands over long periods of time. Others carry accretions of sacrificial material on crusted surfaces or in power packs fastened to the surface by a variety of means — tied, glued, nailed.
</p>
<p>
It is with great pleasure — and I must admit, pride, that I share my treasures with the SMA African Art Museum and with you, the visitor to this exhibition. Most of the collection shown here was formed years ago, but I must admit I still rise to the bait of an extraordinary piece. I say ‘extraordinary’ for several reasons: I already have a good survey of mask types and styles in every possible medium; Good to great examples are becoming increasingly difficult to come by; Increased demand for passport, miniature and family masks has driven prices to unreasonable heights.
These wonderful objects are cherished and cared for by their African owners from generation to generation. Some are kept in sheepskin packets when not in use. At meetings of the Poro, a powerful men ’s secret society, they are placed on the ground to validate the right of the zoe to participate in meetings and rituals. For that reason they are popularly known as ‘passport masks’.
</p>
<p>
I extend warmest thanks to the American Province of the Society of African Missions for hosting ‘Diminutive Spirits’. To my friend and colleague Leonard Kahan, my mentor in the field of African art and consulting curator for this exhibition, I owe the pleasure of a shared enthusiasm for African art which has enriched my intellectual life for many years. I am grateful to him for bringing Robert J. Koenig, Director of the SMA AfricanArt Museum, to my home. When Bob saw my passport, miniature and family masks, he enthusiastically ojfered me an exhibition at the SMA African Art Museum.
Peter Cade has contributed his expertise to the installation of the exhibition and to the fine publications associated with it. Norman Mai, GBH Electric, and his assistant Dave, installed the state-of-the art lighting that allowed us to create the dramatic installation. Vincent Pruzinsky, Paragon Decorators painted the galleries. Bernard McKinley helped in every way.
</p>
       <p>
       Albert Stanziano
       </p>

     </Card.Body>
   </Col>
 </Row>


      </Card>
    </>
  );
};

export default MuseumPassportsMasquettesContent;

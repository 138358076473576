import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheBlackStoolSymbolofPowercontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The Black Stool Symbol of Power
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Among the Akan there is a hierarchy of power. At the summit of this state power structure, the king rules by divine right. As animism is here set up as the state religion and symbolized by a stool, each king is enthroned on taking possession of it. The king, priest of the kingdom, is the only one authorized to move the stools. In the house of worship, there are as many black stools as there are enthroned princes in the kingdoms. The ruling monarch keeps the mother seat, and is morally and materially responsible for it.</p>
            <h4 className="mb-4 fs-6 lead fw-bold"></h4>

            <p className="fs-5">Royal stools are made of different materials: wood darkened by the blood of animals sacrificed during commemorative ceremonies, wood with a gold or silver leaf overlay, precious stone or solid gold. The legs and seat don’t change, but the base can vary with the region and type. The stools are round or rectangular. The round stool is feminine; it is that of the queen mother, and is more powerful and important than of the king, which is rectangular.
            Usually, each kingdom posses several principal stools: the mother stool, stools of the founders of the kingdom, the stool of the reigning sovereign, and the state stool. The major one symbolizes the Nation, ancestor worship, and the spiritual and temporal power of the king.</p>
            <p className="fs-5"></p>
            <h4 className="mb-4 fs-6 lead fw-bold"></h4>
            <p className="fs-5"></p>

            <h4 className="mb-4 fs-6 lead fw-bold"></h4>

            <p className="fs-5"></p>
            <p className="fs-5"></p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheBlackStoolSymbolofPowercontent;

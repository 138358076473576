import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MaskBeteIvoryCoastCAcontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
            

        <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
                   <h4 className="mb-4 fs-3">Mask, Bete, Ivory Coast, CA 1950 (2012)</h4>

             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/BeteMask_page.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
   
 
          
        </Row>
              

              
              <Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
             

            
              <p className="fs-5">MASK, BETE, IVORY COAST, CA 1950
              </p>
              <p className="fs-5">Wood, pigment, animal skin and hair
              </p>
              <p className="fs-5">Gift of Mr. Edward C. Gillette, 2012
              </p>

           

            </Card.Body>
          </Col>

</Row>
<Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
             

            
              <p className="fs-5">The mask is authenticated by one of the great experts on the art of the Guinea Coast, William C. Siegmann, recently deceased Curator Emeritus of the Arts of Africa and Asia, The Brooklyn Museum. Mr. Siegmann, the first curator of Collections of the SMA African Art Museum, published the mask in African Masks of the West Guinea Coast, Purdue University Press, 2003, p. 54. The mask was used in ceremonies invoking spirits of the bush. Its drama is heightened by the skillful combination of several potent materials: highly polished wood, animal skin and hair, vegetable fiber cord and white clay pigment. The eyes, elongated ovals with pointed ends, are highlighted by a band of white lines, The open mouth, as if speaking or singing, is set with fourteen very carefully rendered teeth. The mask dates from the mid twentieth century, it is 9 ¾” high, 21″ with the beard.
              </p>
             

           

            </Card.Body>
          </Col>

</Row>

        

      </Card>
    </>
  );
};

export default MaskBeteIvoryCoastCAcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BushcowMasksXVIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XVI: Bushcow Masks</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVI1.jpg.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">Bushcow Masks</h4>
       <p className="fs-6">The African bush cow bears a superficial resemblance to the peaceful animal we know from American dairy farms. It is respected for its strength, and feared for its unpredictability. Its horns are feared but also admired for their graceful curves, which are beautifully interpreted on these three masks.
       Lines radiating from the eyes are a Nuna device, as are open fields of white pigment. The petaloid pattern on the left is unique to the regions around Poura or Silli. The Nounama mask in the center is composite. It has the beak of a bird, but the horns of a bushcow. The handling of the patterns on this mask is masterful.</p>

     </Card.Body>
   </Col>
   </Row>
  );
};

export default BushcowMasksXVIcontent;

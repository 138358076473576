import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase7Content = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports - Case VII</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      <Row className="align-items-center">
      <Col xs="12" md="4" className="ps-md-3 ps-0">
     <Card.Body>
       <Col >
          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7-11.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
</Col>
    

     </Card.Body>
   </Col>
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
      
     <h4 className="mb-4 fs-6 lead fw-bold">BeNue River, Nigeria</h4>

       <p className="fs-5">The Benue River area of Nigeria is replete with tribes borrowing mask forms from each other. Here, Igbo, Igala and Ogoni family masks relate to each other in many ways: size, material, surface, style, iconography and spiritual function. The Ogoni masquette on the left, representing a human being, is called ‘Elu’. Like many Ogoni masks, it has a distinctive hairstyle.


       </p>
       
    

     </Card.Body>
   </Col>
   </Row>
       
      

     
      <Row className="align-items-center">
          <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>
          <div className="me-1 mb-1 d-inline-block">
                  <img src="/img/museum/Passports_Case7-21.jpg" className="img-fluid rounded-xl"  alt="card image" />
                  </div>
                  </Card.Body>
          </Col>
          <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-3 d-inline-block">
          <img src="/img/museum/Passports_Case7-31.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
    
        </Row>
              

        <Row className="g-0 align-items-center">

                 <Col xs="12" md="12" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">Explore the exhibition case by case</h4>

               
                     <div className="mb-3">
                     <NavLink to="/passportsCaseIV" >
         <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase6" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase5" >     
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase7" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase8" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseIX" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseXVI" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase17" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                       <NavLink to="/passportsCase18" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                        <NavLink to="/passportsCase19" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                         <NavLink to="/passportsCase20" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>

                         <NavLink to="/passportsCase21" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
      </div>
           

                   </Card.Body>
                 </Col>
                  
               </Row>


      </Card>
    </>
  );
};

export default PassportsCase7Content;

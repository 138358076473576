import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const GuidetoCollectionscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Guide to Collections – Volume I

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
   
      <Row className="g-3 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
    <Card.Body>
      <Col className="d-flex justify-content-center">
        <div className="me-1 mb-1 d-inline-block">
        <a href="/documents/SMA_AfricanArt_vol1.pdf" download="SMA_AfricanArt_vol1.pdf">
          <img src="/img/museum/SMA_AfricanArt_vol1-cover1.jpg" className="img-fluid roundeAfricanSculptured-xl" alt="card image" />
          </a>
        </div>
      </Col>

    
      <Col className="d-flex justify-content-center mt-2">
        <a href="/documents/SMA_AfricanArt_vol1.pdf" download="SMA_AfricanArt_vol1.pdf" className="btn btn-primary">
          Download PDF
        </a>
      </Col>
    </Card.Body>
  </Col>
</Row>


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Foreword by Fr. Michael Moran, SMA, Provincial Superior, American Province President, Board of Trustees, SMA African Art Museum
            
            </p>
            <p className="fs-5">It is my great pleasure to welcome you to the African Art Museum’s first publication in cyberspace. Guide to the African Collections of the American Province of SMA will be published on www.smafathers.org in six volumes. The first in the series – I. The West Guinea Coast: Liberia, Sierra Leone, Ivory Coast, Guinea and Guinea Bissau, – is now on the web site and will be available in hard copy in July, 2012. II. Burkina Faso, will also be published in both modes in 2012, as will III.The Gold Coast: Ghana, Benin, Nigeria, Cameroon, in 2013; IV. Central Africa: Gabon, Chad, Democratic Republic of Congo, Central African Republic, in 2014; V. The Sahel: Mali, Niger, Chad, Sudan, in 2015; and finally, VI. East and South Africa: Ethiopia, Kenya, Tanzania, South Africa, in 2016.</p>
            <p className="fs-5">Carol Messer, Executive Director, and Gregory Lucente, Arts Outreach Coordinator, Bergen County Department of Parks, Division of Cultural and Historic Affairs, have been patient and supportive throughout the delays and frustrations inevitable in a project like this one. I express warm appreciation to our donors who have contributed so many fine works of art to ourWest African collections. To our museum staff and volunteers we extend blessings for all the great work they have done since the museum had its beginnings in 1965. For their labors on this publication I single out Robert J. Koenig, Director of the SMA African Art Museum, Peter H. Cade, Assistant to the Director, Prof. Charles Bordogna of Bergen Community College, Bill Siegmann, former Curator of Collections, SMA African Art Museum, and Martha Paladino, General Manager.We especially salute Audrey Koenig for the crucial supportive role she has played as a volunteer in the management of the African Art Museum collections for over fifteen years.</p>
            <p className="fs-5">The SMA and the African Art Museum have enjoyed a cordial business relationship with Galvanic Printing and Plate Co., Inc., for years, but the Guide to Collections has been our happiest collaboration to date. Bob gave John Moss, Jr., President of Galvanic, a design philosophy to work with, but the page by page layout as well as the pervasive clarity, balance and refinement of this book are due to the hard work, skill and vision of three Galvanic staff members: Paul Ragas, Graphic Development Manager, Peter Ruiz, Media Specialist and Jeff Herzog, Color Specialist.</p>
            <p className="fs-5">Funding for this publication has been made possible in part by the New Jersey State Council on the Arts/Department of State, through grant funds administered by the Bergen County Department of Parks, Division of Cultural and Historic Affairs.</p>
           

            </Card.Body>
          </Col>
        </Row>
       
        



      </Card>
    </>
  );
};

export default GuidetoCollectionscontent;

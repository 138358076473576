import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheGurunsiandtheAntelopeMasksXIXcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XIX: The Gurunsi and the Antelope Masks</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XIX.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">The Gurunsi</h4>
        <p className="fs-6">‘Gurunsi’ is a pejorative Mossi word meaning ‘those who eat the bark of trees’
        The Nuna, Nunuma, Lela, Sisala, Kasena, Nankana, Kusase and Winiama, who constitute the Gurunsi, do not use that name to identify themselves. Howver, ‘Gurunsi’ is a useful term for referring to a cultural area in Burkina Faso, and since it has been widely used in the literature on African art, we use it in our exhibition in a precise and limited way.
        The Gurunsi are the seminal people in the figurative art of Burkina Faso. The Bwa, the Mossi and the Dafing have all been greatly influenced by their forms and their iconography. The Bobo, the Lobi and the Senufo have developed their art forms independently of the Gurunsi.
        The Gurunsi occupy the central part of Burkina Faso, an area good for farming, but infested with tsetse flies, the vectors for trypanosomiasis, sleeping sickness.
        When their horses were decimated by the tsetse fly, the invading Mossi thought they were destroyed by the magic of the forest-dwelling natives, the nyonyose. To this day, the Gurunsi/Nyonyose are feared for their magical powers.
        The Gurunsi believe in a supreme God, Yi, who created the world and then left it to Su, the mask spirit. They make dozens of different types of masks – bush buffalo, antelope, pigs, hyenas, serpents, crocodiles, birds, insects and several kinds of human beings. Other masks are abstract or take the form of broad short planks with curving beaks, posts, hooks, crests and a multitude of other shapes. All of these may be thought of as aspects of Su. </p>
        <h4 className="font-weight-bold">Antelope Masks</h4>
       <p className="fs-6">All hollow-horned foragers, antelope are prey to a number of carnivores, including man, but they are so beautiful, especially in motion, that, according to African legend, t hey can ‘stop the heart of the
       hunter’ so he cannot bring himself to kill them.
       The Marka-Dafing mask ‘kou’, on the far left, has blocky horns and rectangular tab ears. A trapezoidal
       chin hand-hold with deeply incised vertical lines, and a one-to-one ratio of face to forehead are
       characteristic of Marka-Dafing masks. The two Wan-nyaka masks, in the center and on the far right,
       are Liuli, worn on top of the head, usually in the company of more important family masks. The mask
       with vertical horns is either a large Ouagadougou style mask or a small example of a kokologho-style
       mask from southwest of Ouagadougou.’ TGBW </p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default TheGurunsiandtheAntelopeMasksXIXcontent;

import React from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';


const MuseumHome = () => {
  return (

    <Tab.Pane eventKey="Home">
    {/* Text Content Start */}
      <section className="scroll-section" id="textContent">
       
        <Card className="cta-2 mb-5">
          <Card.Body className="d-flex flex-column scroll-out">
            <OverlayScrollbarsComponent options={{ overflowBehavior: { x: 'hidden', y: 'scroll' } }} className="sh-50">
            <h3 className="card-title mb-4">About SMA Museum</h3>
              <p>
              The African Art Museum of the SMA Fathers at Tenafly,
               New Jersey is one of five museums around the world founded and maintained by the Society of African Missions (SMA), 
               an international Roman Catholic missionary organization that serves the people of Africa.
              </p>
 
              <p>
              The museums continue the vision of SMA’s founder, 
              Bishop Melchior de Marion Brésillac (1813-1859). 
              The French-born clergyman urged his Society to respect and preserve the culture of the peoples they serve, the unique vision among missionaries of his time.
              </p>

              <p>
              Established in 1980, the Tenafly museum is one of only a rare few in the United States dedicated solely to the arts of Africa. 
              Its permanent collections, exhibited on a rotating basis, offer a unique advantage in the study and research of sub-Saharan sculpture and painting, 
              costumes, textiles and decorative arts, religion and folklore.
              </p>
   
              <p>
              Hours are 8AM-5PM seven days a week. Group tours for adults and children by appointment.
               Admission is free, though donations are always welcome.
The museum is located at 23 Bliss Ave., Tenafly, NJ.
              </p>
            </OverlayScrollbarsComponent>
          </Card.Body>
        </Card>
      </section>
      {/* Text Content End */}

          </Tab.Pane>        
    
  );
};

export default MuseumHome;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const HomeVertical = () => {
  return (
    <>
      <Card>
        <Row className="g-0 align-items-center">
          <Col xs="12" md="4">

                  <img src="/img/banner/about_1.webp" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">AMERICAN PROVINCE HISTORY</h4>

              <p>
                SMA missionaries first established a presence in the United States at the end of the nineteenth century when Fr. Ignatius Lissner SMA,
                a native of Alsace in France, came to North America to raise funds for the SMA’s missions in Africa. While there,
                he quickly saw America as a potential mission territory, particularly among the impoverished, and largely unchurched, black population of the South. On receiving permission from his superiors, Fr Lissner and his fellow missionaries (mostly other Alsatian-born SMAs) began their work in America of establishing schools and parishes for black Catholics – especially in Georgia.
              </p>
              <p>
           Adhering to the vision of the founder of the SMA, Bishop Melchior de Marion Brésillac, the SMAs recruited clergy and religious sisters from the black population to minister to their own people. As a result they endured bitter opposition spurred by the racial prejudice and anti-Catholic sentiments prevalent in the area at the time.
              </p>

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">A SAINTLY FRIEND AND A SEMINARY</h4>

                     <p>
                 The SMAs in America worked closely with Mother Katharine Drexel (1858-1955), the extraordinary woman who founded a religious order and who was
                 canonized in October 2000. Mother Drexel’s Sisters of the Blessed Sacrament also had a special commitment to serving blacks. They worked extensively in Georgia where they collaborated with the SMA on many projects.
                     </p>
                     <p>
                     Mother Drexel and Fr Lissner also maintained a lifelong friendship. It was she, the heiress to a family fortune,
                     who contributed funds to help the SMA acquire property in Tenafly, New Jersey, for its first American seminary. St Anthony’s Mission House,
                     established in 1921, was then the only seminary in the USA that accepted black candidates for the priesthood and which was also racially integrated. Although two black priests were ordained from there, the seminary had to close in 1926 – a casualty of the racial tension that permeated the country at that time.
                     </p>

                   </Card.Body>
                 </Col>
                      <Col xs="12" md="4">
                                          <img src="/img/banner/about-2.webp" className="card-img h-100 scale" alt="card image" />
                                  </Col>
               </Row>
   <Row className="g-0 align-items-center">
          <Col xs="12" md="4">

                  <img src="/img/banner/about-3.webp" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">SMA EXPANSION IN AMERICA / GROWTH OF CATHOLICISM</h4>

              <p>
             In the following years a wave of Irish SMA priests came to America, expanding the SMA presence to Arizona, Illinois and California. They founded new black parishes and schools and also agreed to take over existing ones. Their increased visibility attracted vocations among young Americans.
             Responding to this growth the SMA opened Queen of Apostles Major Seminary in 1939 in Silver Spring, Maryland. When a fire destroyed the building in 1943, seminary operations were relocated to Washington, D.C. </p>
              <p>
          As the Catholic Church in America grew in strength, so also the USA branch of the SMA began to flourish.
           In 1941 the American Province of the Society of African Missions was established, with Fr Lissner as its first provincial superior and Tenafly as the location of its headquarters. Five years later a minor seminary (also called Queen of Apostles) was opened in Dedham, Massachusetts.
          By 1946 the SMA American Province with its three administrative locations, was well established. </p>

            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default HomeVertical;

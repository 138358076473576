import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const IvoryCoastContent = () => {
  return (
    <>

      <Card>
        <Row className="g-0 align-items-center">
             <Row className="align-items-center">
          <Col >
            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/BeteMask_page.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
          </Col>

        </Row>

          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            
              <h4 className="mb-4"></h4>
              <h4></h4>

              <p className="fs-5"> MASK, BETE, IVORY COAST, CA 1950</p>               
              <p className="fs-5">Wood, pigment, animal skin and hair</p>
              <p className="fs-5">Gift of Mr. Edward C. Gillette, 2012</p>



            </Card.Body>
          </Col>
        </Row>

       
        <Row className="g-0 align-items-center">

          <Col xs="12" md="20" className="ps-md-3 ps-0">
            <Card.Body>
              

              <p className="fs-5">
                A new exhibition, A Passion for Life: will open on Saturday, September 22, 2012 in celebration of the event Culture of Ivory Coast.              
                </p>
              <p className="fs-5">
                It will feature Ivorian masterworks from the permanent collections of the SMA African Art Museum. These are documented by a brand new publication The SMA African Art Museum Guide to Collections: Volume I, which covers Ivory Coast, Liberia, Sierra Leone, Guinea and Guinea Bissau. Every guest at the Ivory Coast festival will receive a free copy of the catalogue, which retails for $29.95. The text, by director Robert J. Koenig with photographs by museum associate Peter H. Cade. is available on the museum website www.smafathers.org. A new acquisition, a Bete mask from Ivory Coast, will be featured ion the exhibition.

              </p>

            </Card.Body>
          </Col>
        </Row>

        <Row className="g-0 align-items-center">

         
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
             

              <p className="fs-5">
                It will feature Ivorian masterworks from the permanent collections of the SMA African Art Museum. These are documented by a brand new publication The SMA African Art Museum Guide to Collections: Volume I, which covers Ivory Coast, Liberia, Sierra Leone, Guinea and Guinea Bissau. Every guest at the Ivory Coast festival will receive a free copy of the catalogue, which retails for $29.95. The text, by director Robert J. Koenig with photographs by museum associate Peter H. Cade. is available on the museum website www.smafathers.org. A new acquisition, a Bete mask from Ivory Coast, will be featured ion the exhibition.
              </p>

            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">

         
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
   

    <p className="fs-5">
  The mask is authenticated by one of the great experts on the art of the Guinea Coast, William C. Siegmann, recently deceased Curator Emeritus of the Arts of Africa and Asia, The Brooklyn Museum. Mr. Siegmann, the first curator of Collections of the SMA African Art Museum, published the mask in African Masks of the West Guinea Coast, Purdue University Press, 2003, p. 54. The mask was used in ceremonies invoking spirits of the bush. Its drama is heightened by the skillful combination of several potent materials: highly polished wood, animal skin and hair, vegetable fiber cord and white clay pigment. The eyes, elongated ovals with pointed ends, are highlighted by a band of white lines, The open mouth, as if speaking or singing, is set with fourteen very carefully rendered teeth. The mask dates from the mid twentieth century, it is 9 ¾” high, 21″ with the beard.
    </p>

  </Card.Body>
</Col>
</Row>





      </Card>
    </>
  );
};

export default IvoryCoastContent;


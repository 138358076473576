import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Malicontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
            

        <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
                   <h4 className="mb-4 fs-3">Mali: Masks of the Dogon</h4>

             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/DogonMask.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
   
 
          
        </Row>
              

              <Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-4 lead fw-bold">Rabbit Mask Dyommo, Dogon, Mali. Wood, commercial enamel paints, iron nails.
              Gift of Leonard and Judy Kahan, 1996.</h4>

            
              <p className="fs-5">The Dogon people have created more than eighty mask types to represent characters in their cosmic myths. They are worn in large numbers in funeral dances. The Dogon use such dances to lead the homeless souls of the deceased to their final resting places in the world of spirit, where they become part of the ancestor realm. ‘Certain masks act out stories in addition to dancing. An example of this is the dyommo mask (rabbit) which performs with the dannana mask (hunter). The hunter pretends to hunt the several rabbit masks performing with him. He chases them; they flee in all directions, hiding among the spectators assembled in the village square.’
              </p>
              <p className="fs-5">Imperato, Dogon Cliff Dwellers, L. Kahan Gallery Inc., 1978.
              </p>

           

            </Card.Body>
          </Col>

</Row>

        

      </Card>
    </>
  );
};

export default Malicontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const ColorandPatternIVcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case IV: Color and Pattern</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-IV.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The masks of the Nuna, Nunuma, Winiama and Lela are covered with patterns painted in red, black and white. The two masks on the left have retained most of their pattern and color. The very old bird mask on the right has lost its polychrome through age, wear and use. Its muted brown surface appeals to us because it reveals the beauty of the carving (just as we enjoy the pristine whiteness of ancient Greek sculpture, which was originally painted in bright colors). If the old bird mask were to be danced again, it would be repainted. Typical patterns include concentric circles, checkerboards, triangles, rectangles, and zigzags. Concentric circles around eyes are ubiquitous in Burkina Faso, but only the Nuna use lines that radiate from the pupil like the spokes of a wheel.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default ColorandPatternIVcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCaseIVContent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
          <Col >
          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case1-11.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
</Col>

          <Col>
          <div className="me-1 mb-1 d-inline-block">
                  <img src="/img/museum/Passports_Case1-2.jpg" className="img-fluid rounded-xl"  alt="card image" />
                  </div>
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
   
 
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Passports – Case IV</h4>

              <p>
              This case gives us an opportunity to compare masks from a number of West African cultures. On these two pedestals, from top to bottom, are passports from the Makonde, Agni, Lwalwa (carved from a nut), Grebo, unidentified culture, Kpelle, and Bidjogo. The Makonde and Agni (the latter is actually a miniature plaquette) are very rare terra cotta passports.
              </p>
           

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case1-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
             
                   <p>
                      This is a sowei mask of the Bundu spirit, which protects women and girls. The full-sized Bundu mask is unique in the cultures of Africa because it is worn by females of the Mende, Vai, Bassa, Gola and Sherbro people.
</p>
      
                   </Card.Body>
                 </Col>
              </Row>

              <Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case1-4.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p>
  The miniature stone passport on the left is from the Toma. The extremely elongated mask with built up surface is a masterpiece (in my opinion).
  </p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case1-5.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p>
  This terra cotta Dan/Guere mask is unique – at least I have never seen another one.</p>

  </Card.Body>
</Col>
</Row>

<Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCaseIVContent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const RealismandAbstractionXVIIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XVIII: Realism and Abstraction</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVIII.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">Realism</h4>

       <p className="fs-6">While not really realistic, The Bird in Flight mask is a convincing impression of a swooping bird. The head, though too big for the body, feels like an organic whole with the outspread wings and the tail. The patterns are beautifully integrated with the carved forms to give us an impression of feathers in motion. The blacksmith who made this piece of sculpture was not only a good carver, he was an acute and sensitive observer of birds. And, in my opinion, a poet as well.</p>
       <p className="fs-6">Perhaps Nuna, this mask could be Samo or Nunuma. The tightly packed triangles may be associated with the Samo; the open fields on the tail and beak and the unusual form may reflect Nuna sensibilities. The raised crest between the eyes is often associated with the Nunuma.’ TGBW</p>
       <h4 className="font-weight-bold">Abstraction</h4>
       <p className="fs-6">The hook on the round form at the base of this mask appears on many Gurunsi and Bwa planks. It is an abstract reference to the beak of a bird. The paired black and white triangles are a typical Nuna design. The black and white checkerboard pattern on the large Bwa plank mask in the Rotunda means the separation of wisdom (black) from ignorance (white), necessary for the initiation of a boy into the wisdom of a man. We cannot assume that the Nuna pattern means the same thing, but it is tempting to speculate that the two visual ideas may have a common root. In any case, both the checkerboard and the triangles are sophisticated geometrical patterns with figure/ground ambiguity, a commonplace in twentieth century abstract painting.</p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default RealismandAbstractionXVIIIcontent;

import React from 'react';
import { Card } from 'react-bootstrap';
import Glide from 'components/carousel/Glide';

const CarouselAutoplayMuseum = () => {
  return (
    <Glide
      noControls
      options={{
        gap: 0,
        rewind: true,
        type: 'carousel',
        autoplay: 2000,
        perView: 1,

        breakpoints: {
                    400: { perView: 1 }
        },
      }}
    >
 
        <Glide.Item key="smallGutters.1">
            <Card className="sh-70">
               <img src="/img/banner/Station1_web.jpg" className="card-img h-100" alt="card image" />
             </Card>
          </Glide.Item>
    
             <Glide.Item key="smallGutters.2">
               <Card className="sh-70">
                  <img src="/img/banner/Station2_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.3">
               <Card className="sh-70">
                  <img src="/img/banner/Station3_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.4">
               <Card className="sh-70">
                  <img src="/img/banner/Station4_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.5">
               <Card className="sh-70">
                  <img src="/img/banner/Station5_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.6">
               <Card className="sh-70">
                  <img src="/img/banner/Station6_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.7">
               <Card className="sh-70">
                  <img src="/img/banner/Station7_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.8">
               <Card className="sh-70">
                  <img src="/img/banner/Station8_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.9">
               <Card className="sh-70">
                  <img src="/img/banner/Station9_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.10">
               <Card className="sh-70">
                  <img src="/img/banner/Station10_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
             <Glide.Item key="smallGutters.11">
               <Card className="sh-70">
                  <img src="/img/banner/Station11_web.jpg" className="card-img h-100 " alt="card image" />
                </Card>
             </Glide.Item>
    </Glide>
  );
};

export default CarouselAutoplayMuseum;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const WaluandKomoMasksXVcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XV: Walu, the Antelope Mask and Komo Society Masks</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XV.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">Walu, the Antelope Mask of the Dogon People of Mali</h4>
       <p className="fs-6">This mask depicts a mythical antelope known to the Dogon as Walu. At the time of creation, God (Amma) assigned Walu to protect the sun from Oro (Yurugu), Fox.
       The Walu mask always has a rectangular face, a long straight nose, rectangular or triangular eye holes set far behind the facial plane, and long horns and ears. This is an unusually large and impressive example, painted in red and silver commercial paint; the black is a natural pigment. These are not necessarily the original colors; the members of the Awa mask society, which owns the masks, repaint them for every dama (great mask festival), at five year intervals. Our Walu retains a fragment of its original costume.</p>
       <h4 className="font-weight-bold">Komo Society Mask of the Bamana People of Mali</h4>
       <p className="fs-6">Komo masks are worn and seen only by men who have been initiated into the powerful komo association. In their rites, komo practitioners use the spiritual power in the masks to fight black magic and evil. The mask is constructed in secret, in the bush, by komo members. It is an altar as well as a mask. The basic form is of wood, covered with mud, honey and cow dung mixed with human excrement. That ‘skin’ is augmented with other materials, such as animal and human blood, and chewed kola nuts. The power of the mask is further enhanced by the attachment of antelope horns, tusks and porcupine quills.</p>
       
     </Card.Body>
   </Col>
   </Row>
     );
};

export default WaluandKomoMasksXVcontent;

import React from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


const MuseumAcquisitions = () => {
  return (

                    
    <Tab.Pane eventKey="Acquisitions">


    {/* Images Large Start */}
    <section className="scroll-section" id="imagesLarge">
    <br></br>
    <h3 className="card-title mb-4">Acquisitions</h3>
  

  

    <Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/BeteMask_thumb.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/MaskBeteIvoryCoastCA" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Mask, Bete, Ivory Coast, CA 1950 (2012)
          </Button>
          <div className="text-muted text-truncate">TMASK, BETE, IVORY COAST, CA 1950
The mask was used in ceremonies invoking spirits of the bush. Its drama is heightened by the skillful combination of 
several potent materials: highly polished wood, animal skin and hair, ...
                    </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card> 



<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/NupeDoor_thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/DoorNupeNigeriaCA" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Door, Nupe, Nigeria, CA 1945 (2012)
          </Button>
          <div className="text-muted text-truncate">
          Constructed of four boards of blackened wood, covered with 
                   well-designed and beautifully executed images in low relief ....
                    </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/SoccerPlayer_thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/CommemorativePortraitofSalifKeita" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Commemorative Portrait of Salif Keita (2010)
          </Button>
          <div className="text-muted text-truncate">
          This heroic figure represents the great Malian soccer player Salif Keita, who played the game in the 1960s and 70s.
                    It is carved from one piece of wood, except for the ball ...               </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>



    
        </section>
        </Tab.Pane> 
  );
};

export default MuseumAcquisitions;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase20Content = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
   
 
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports – Case XX</h4>

    

     </Card.Body>
   </Col>
 </Row>
      <Row className="g-0 align-items-center">
      <Col  xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>
          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case5-1.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
          </Card.Body>
          </Col>

   <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
<h4 className="mb-4 fs-6 lead fw-bold">Eastern Pende</h4>
       <p className="fs-5">
       The geographic division between the eastern Pende who live along the Loango River and the Western Pende, along the Kasai River, produced two very different aesthetic traditions as demonstrated in this showcase. The masks of the eastern Pende, carved of a lighter wood , are geometric. Those of the western Pende, while abstract, are more organic in feeling.
The masks of the Pende are marked by two different styles, the very abstract, even geometric mingaji masks of the Kasai Pende, and the more naturalistic mbuya masks of the Katundu Pende. Mbuya functions as a socializing agent by satirizing deviations from the norm as experienced by the people in the village. The carvers portray idiomatic forms which caricature personalities such as the chief, the diviner, the epileptic, the widow, the man in a trance, the madman, the lover and the executioner. The mbuya dancer must entertain the crowd by imitating the movements and gestures of the character in his mask. The Pende also make amulets called ikhoko. These may be made of ivory, wood, even hippopotamus tooth or the muhafu pit, a very hard material, difficult to carve. The masks shown here are all Katundu Pende mbuya miniatures.
 </p>
    

     </Card.Body>
   </Col>
 </Row>

      
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case5-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                    <h4 className="mb-4 fs-6 lead fw-bold">Western Pende</h4>
             
                   <p className="fs-5">
                   For me, the gem in this case is the very small western Pende passport mask with the crown-like coiffure. It is reminiscent of Pende ivory ‘ikoko’ pendants in its polished patina and downcast eyes. The glossy surface may be the result of polishing by an indigenous owner or western collector. Perhaps because of its great rarity, it is one of my favorites.

</p>
      
                   </Card.Body>
                 </Col>
              </Row>


              <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default PassportsCase20Content;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheDamaandtheKanagaMaskXIVcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XIV: The Dama and the Kanaga Mask</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVI.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The function of the Dama is to control the secret force that emanates from the dead. The collective power of the masks directs that dangerous spiritual force, the nyama, to a sacred space where it will be fixed for eternity. At the end of the dama, the deceased will belong to the ranks of the ancestors of the Dogon people. Individually and collectively, the ancestors transmit the word of Amma, the creator god, to man. The word of Amma, like the word of God in the Old Testament, is a vital force, with the power to fertilize the fields and make fecund all living creatures. There is no mask for Amma; all creation may be thought of as the mask of God. The masks making visible the characters in the dama are manifestations of various aspects of the vital force of the universe.</p>
       <h4 className="font-weight-bold">The Kanaga Mask</h4>
       <p className="fs-6">The Dogon people use seventy eight different masks in their great cosmic dance/drama, the Dama, performed at intervals of five years. It is an abstract symbol of a bird , the bustard, an important character in the Dogon creation myth. The kanaga mask always takes the basic shape of a quadruped seen from above, but the four ‘legs’ are the wings of the bird. The head of the bird, resembling a warrior wearing a helmet, gives the mask its forbidding aspect. In contrast to most African masks which are monoxylic (carved of one piece of wood) the kanaga is an assemblage of five distinct parts. It is sometimes embellished with two figures, the mythical primordial couple.</p>
       <h4 className="font-weight-bold">The Meaning of Kanaga</h4>
       <p className="fs-6">To the uninitiated, the kanaga mask evokes a bird, but for those who have attained knowledge through initiation into the secret society, it is the symbol of man, axis of the world, pointing to earth and sky. It is the water insect at the beginning of time, who planted the first seed from which all other seeds sprang.
       The kanaga masquerader communicates the excitement of this cosmic event through his dance. With a rapid movement of the upper part of his body, he sweeps the mask close to the ground, reenacting the internal vibration that animated the matter created by Amma.
       The flat board representing the body of the kanaga refers to the body of Oro the fox, the first transgressor, crushed when he fell from heaven.</p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default TheDamaandtheKanagaMaskXIVcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const SharedIconographyandtheGroundHornbillXVIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XVII: Shared Iconography and the Ground Hornbill</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVIId.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">Shared Iconography</h4>

       <p className="fs-6">The African bush cow bears a superficial resemblance to the peaceful animal we know from American dairy farms. It is respected for its strength, and feared for its unpredictability. Its horns are feared but also admired for their graceful curves, which are beautifully interpreted on these three masks.
       
       </p>
       
       <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XVII.jpg" className="img-fluid rounded-xl" alt="card image" />
      
      </div>
      <h4 className="font-weight-bold">The Ground Hornbill</h4>
<p className="fs-6">Lines radiating from the eyes are a Nuna device, as are open fields of white pigment. The petaloid pattern on the left is unique to the regions around Poura or Silli. The Nounama mask in the center is composite. It has the beak of a bird, but the horns of a bushcow. The handling of the patterns on this mask is masterful.
        
       </p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default SharedIconographyandtheGroundHornbillXVIIcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PlankMasksVcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case V: Plank Masks</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-V.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <p className="fs-6">Masks with tall superstructures having a board or ‘plank’as the intrinsic form are called ‘plank masks’. In Africa, they occur only in the Sudan: in Mali, among the Dogon, and in Burkina Faso, among the Mossi, the Bwa, and the group of peoples called Gurunsi (Nuna, Nunuma. et al). A spectacular piece of theater, the plank mask towers above all the other masks in a ceremony. Worn by a young, strong, well-trained dancer, it soars into the air when he jumps, carves great circles in space when he spins, and describes a great arc when he bends backwards and then forwards, touching the earth in the four cardinal points with the tip. Compare this Nuna plank mask with the Bwa nwantantay and the Dogon sirige in the Rotunda.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default PlankMasksVcontent;

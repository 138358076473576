import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Scrollspy from 'components/scrollspy/Scrollspy';
import TheInlandNigerDeltaIcontent from 'views/museumlib/TheInlandNigerDeltaIcontent';
import JewelryIIcontent from 'views/museumlib/JewelryIIcontent';
import AnimalSpiritsVIIIcontent from 'views/museumlib/AnimalSpiritsVIIIcontent';
import BatebaMorphologyVIIcontent from 'views/museumlib/BatebaMorphologyVIIcontent';
import PlankMasksVcontent from 'views/museumlib/PlankMasksVcontent';
import TheLobiVIcontent from 'views/museumlib/TheLobiVIcontent';
import ColorandPatternIVcontent from 'views/museumlib/ColorandPatternIVcontent';
import WaluandKomoMasksXVcontent from 'views/museumlib/WaluandKomoMasksXVcontent';
import TheDamaandtheKanagaMaskXIVcontent from 'views/museumlib/TheDamaandtheKanagaMaskXIVcontent';
import TheGurunsiandtheAntelopeMasksXIXcontent from 'views/museumlib/TheGurunsiandtheAntelopeMasksXIXcontent';
import RealismandAbstractionXVIIIcontent from 'views/museumlib/RealismandAbstractionXVIIIcontent';
import SharedIconographyandtheGroundHornbillXVIIcontent from 'views/museumlib/SharedIconographyandtheGroundHornbillXVIIcontent';
import ChairsandStoolsIIIcontent from 'views/museumlib/ChairsandStoolsIIIcontent';
import MasksWithCrestsXXcontent from 'views/museumlib/MasksWithCrestsXXcontent';
import CarvingandWearingaMaskXXIcontent from 'views/museumlib/CarvingandWearingaMaskXXIcontent';
import FormandFunctionXXIIXXIIIcontent from 'views/museumlib/FormandFunctionXXIIXXIIIcontent';
import DecorationXXIVcontent from 'views/museumlib/DecorationXXIVcontent';
import BushcowMasksXVIcontent from 'views/museumlib/BushcowMasksXVIcontent';
import ChairsandStoolsIXcontent from 'views/museumlib/ChairsandStoolsIXcontent';
import MossiFigureSculptureXcontent from 'views/museumlib/MossiFigureSculptureXcontent';
import NakomseandNyonyoseXIcontent from 'views/museumlib/NakomseandNyonyoseXIcontent';
import NyonyosiStoneSculptureXIIcontent from 'views/museumlib/NyonyosiStoneSculptureXIIcontent';
import BuraSudanandNigerXIIIcontent from 'views/museumlib/BuraSudanandNigerXIIIcontent';

const carosuls = () => {
  const BurkinaFaso = '';

  const scrollspyItems = [
    { id: 'BurkinaFaso', text: 'Burkina Faso' },
    { id: 'theinlandnigerdeltaIcontent', text: 'The Inland Niger Delta (I)' },
    { id: 'jewelryIIcontent', text: 'Jewelry (II)' },
    { id: 'chairsAndStoolsIIIcontent', text: 'Chairs and Stools (III)' },
    { id: 'colorAndPatternIVcontent', text: 'Color and Pattern (IV)' },
    { id: 'plankMasksVcontent', text: 'Plank Masks (V)' },
    { id: 'theLobiVIcontent', text: 'The Lobi (VI)' },
    { id: 'batebaMorphologyVIIcontent', text: 'Bateba Morphology (VII)' },
    { id: 'animalSpiritsVIIIcontent', text: 'Animal Spirits (VIII)' },
    { id: 'chairsAndStoolsIXcontent', text: 'Chairs and Stools (IX)' },
    { id: 'mossiFigureSculptureXcontent', text: 'Mossi Figure Sculpture (X)' },
    { id: 'nakomseAndNyonyoseXIcontent', text: 'Nakomse and Nyonyose (XI)' },
    { id: 'nyonyosiStoneSculptureXIIcontent', text: 'Nyonyosi Stone Sculpture (XII)' },
    { id: 'buraSudanAndNigerXIIIcontent', text: 'Bura, Sudan and Niger (XIII)' },
    { id: 'theDamaAndTheKanagaMaskXIVcontent', text: 'The Dama and the Kanaga Mask (XIV)' },
    { id: 'waluAndKomoMasksXVcontent', text: 'Walu and Komo Masks (XV)' },
    { id: 'bushcowMasksXVIcontent', text: 'Bushcow Masks (XVI)' },
    { id: 'sharedIconographyAndTheGroundHornbillXVIIcontent', text: 'Shared Iconography and the Ground Hornbill (XVII)' },
    { id: 'realismAndAbstractionXVIIIcontent', text: 'Realism and Abstraction (XVIII)' },
    { id: 'theGurunsiAndTheAntelopeMasksXIXcontent', text: 'The Gurunsi and the Antelope Masks (XIX)' },
    { id: 'masksWithCrestsXXcontent', text: 'Masks With Crests (XX)' },
    { id: 'carvingAndWearingAMaskXXIcontent', text: 'Carving and Wearing a Mask (XXI)' },
    { id: 'formAndFunctionXXIIXXIIIcontent', text: 'Form and Function (XXII-XXIII)' },
    { id: 'decorationXXIVcontent', text: 'Decoration (XXIV)' },
  ];

  return (
    <>
      <Row className='cta-2'>
        <Col>
          {/* Burkina Faso Section */}
          <section className="scroll-section" id="BurkinaFaso">
            <div className="page-Burkina Faso-container">
              <h1 className="mb-0 pb-0 display-4">{BurkinaFaso}</h1>
            </div>
            
            <Row className="g-0 align-items-center">
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4"></h4>
              <h2>Burkina Faso: the Thomas G.B. Wheelock Gift</h2>
            </Card.Body>
          </Col>

          <Col xs="12" md="20" className="ps-md-3 ps-0">
            <Card.Body className="fs-6 text-break">
              <p>Please join us at the opening reception for the exhibition “Burkina Faso: the Thomas G.B. Wheelock Gift”, On Sunday, April 29th, 2012.</p>
              <p>Refreshments will be served from 3 to 5 p.m. in the museum and the adjacent cloister garden. Opening remarks will be at 3:45.</p>
              <p>The exhibition celebrates the gift of one hundred and forty four works of art to the SMA African Art Museum from Mr. Wheelocks private collection.</p>
              <p>Mr. Wheelock will be there, as will Bill Wright, a great friend and supporter of the SMA museum. Bill and Tom worked together in Burkina Faso in the early nineteen seventies, and were the first students of African art to deal in the arts of Burkina Faso, and to bring it to the attention of scholars, collectors, museums, and the general public.</p>
              <p>The exhibition will be presented online as well as on the walls of the gallery. If you seek more information than we can present in the display cases, go to the display station on the table in the museum rotunda and click on Burkina Faso. Clicking on an icon will give you in-depth information about various aspects of an object – its religious, philosophical, historical, functional, decorative, aesthetic, and recreational functions, for instance. Books and periodicals dealing with the art of Burkina Faso are also available on this table.</p>
              <p>Additionally, all of this information is available at home. Just go to www.smafathers.org, and click on MUSEUM</p>
              <p>The mask is authenticated by one of the great experts on the art of the Guinea Coast, William C. Siegmann, recently deceased Curator Emeritus of the Arts of Africa and Asia, The Brooklyn Museum. Mr. Siegmann, the first curator of Collections of the SMA African Art Museum, published the mask in *African Masks of the West Guinea Coast*, Purdue University Press, 2003, p. 54. The mask was used in ceremonies invoking spirits of the bush. Its drama is heightened by the skillful combination of several potent materials: highly polished wood, animal skin and hair, vegetable fiber cord, and white clay pigment. The eyes, elongated ovals with pointed ends, are highlighted by a band of white lines. The open mouth, as if speaking or singing, is set with fourteen very carefully rendered teeth. The mask dates from the mid-twentieth century, it is 9 ¾” high, 21″ with the beard.</p>
              
        </Card.Body>
        <Card.Body className="fs-6 text-break">
        <p>FR. MICHAEL MORAN, PRESIDENT</p>
              <p>ROBERT J. KOENIG, DIRECTOR</p>
              <p>THE AFRICAN ART MUSEUM OF THE SMA FATHERS MARCH 15, 2012</p>
              
             
            </Card.Body>
            
          </Col>

         
         
          </Row>
          
          </section>

          {/* Section Rendering */}
          <section className="scroll-section" id="theinlandnigerdeltaIcontent">
            <TheInlandNigerDeltaIcontent />
          </section>
          <section className="scroll-section" id="jewelryIIcontent">
            <JewelryIIcontent />
          </section>
          <section className="scroll-section" id="chairsAndStoolsIIIcontent">
            <ChairsandStoolsIIIcontent />
          </section>
          <section className="scroll-section" id="colorAndPatternIVcontent">
            <ColorandPatternIVcontent />
          </section>
          <section className="scroll-section" id="plankMasksVcontent">
            <PlankMasksVcontent />
          </section>
          <section className="scroll-section" id="theLobiVIcontent">
            <TheLobiVIcontent />
          </section>
          <section className="scroll-section" id="batebaMorphologyVIIcontent">
            <BatebaMorphologyVIIcontent />
          </section>
          <section className="scroll-section" id="animalSpiritsVIIIcontent">
            <AnimalSpiritsVIIIcontent />
          </section>
          <section className="scroll-section" id="chairsAndStoolsIXcontent">
            <ChairsandStoolsIXcontent />
          </section>
          <section className="scroll-section" id="mossiFigureSculptureXcontent">
            <MossiFigureSculptureXcontent />
          </section>
          <section className="scroll-section" id="nakomseAndNyonyoseXIcontent">
            <NakomseandNyonyoseXIcontent />
          </section>
          <section className="scroll-section" id="nyonyosiStoneSculptureXIIcontent">
            <NyonyosiStoneSculptureXIIcontent />
          </section>
          <section className="scroll-section" id="buraSudanAndNigerXIIIcontent">
            <BuraSudanandNigerXIIIcontent />
          </section>
          <section className="scroll-section" id="theDamaAndTheKanagaMaskXIVcontent">
            <TheDamaandtheKanagaMaskXIVcontent />
          </section>
          <section className="scroll-section" id="waluAndKomoMasksXVcontent">
            <WaluandKomoMasksXVcontent />
          </section>
          <section className="scroll-section" id="bushcowMasksXVIcontent">
            <BushcowMasksXVIcontent />
          </section>
          <section className="scroll-section" id="sharedIconographyAndTheGroundHornbillXVIIcontent">
            <SharedIconographyandtheGroundHornbillXVIIcontent />
          </section>
          <section className="scroll-section" id="realismAndAbstractionXVIIIcontent">
            <RealismandAbstractionXVIIIcontent />
          </section>
          <section className="scroll-section" id="theGurunsiAndTheAntelopeMasksXIXcontent">
            <TheGurunsiandtheAntelopeMasksXIXcontent />
          </section>
          <section className="scroll-section" id="masksWithCrestsXXcontent">
            <MasksWithCrestsXXcontent />
          </section>
          <section className="scroll-section" id="carvingAndWearingAMaskXXIcontent">
            <CarvingandWearingaMaskXXIcontent />
          </section>
          <section className="scroll-section" id="formAndFunctionXXIIXXIIIcontent">
            <FormandFunctionXXIIXXIIIcontent />
          </section>
          <section className="scroll-section" id="decorationXXIVcontent">
            <DecorationXXIVcontent />
          </section>
        </Col>

        {/* Scrolspy */}
        <Col xs="2" className="xl-5">
        <h2 className="scrollspy-heading lg-10">Burkina Faso’s Case by Case</h2> 
          <Scrollspy items={scrollspyItems} />
        </Col>
      </Row>n    </>
  );
};

export default carosuls;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const SymbolismInGhanacontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
   
 
          <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-3">Symbolism In Ghana</h4>

              <p className="fs-5">The Symbolism In Ghana exhibition begins a six-month run at the African Art Museum of the SMA (Society of African Missions) Fathers in Tenafly, NJ. Spanning five cases, the exhibition explores five different aspects of Ghanaian culture: Adinkra, Military/Government/Commerce, Royalty, Birth and Death, and Assorted Symbolism. A sixth case tackles the murky subject of Authentic vs. “Tourist” Art.
              </p>
              <p className="fs-5">From the colors used in various rituals to the designs used in everyday life, Ghana is a country teeming with symbolism: the representation of a concept in visual form. Although Adinkra (also called Adinkara) symbols have existed for funeral hundreds of years, their origins remain hazy. In his book Religion and Art in Ashante, R.S. Rattray suggests these symbols may have been borrowed from the Saharan Moslem world to the north as a byproduct of trade. This theory makes sense in that Islam has a rich tradition of symbolism, pictorial images being taboo. In their book The Arts Of Ghana, Herbert M. Cole and Doran H. Ross state that Kuduo and Forowa boxes, used by Ghanaians for purposes as varied as makeup and burial offerings, were either imported ready-made or in hammered, pre-embossed sheets from the Moslem regions to the north, a theory reinforced by the notion that neither copper nor bronze—the metals they were made of—are found in Ghana.
              </p>
              <p className="fs-5">The most likely scenario is that Ghana did import Moslem elements, modifying them for its own purposes, just as it does with Western influences today. Plenty of historical precedents exist for this, as in the Greek influence on perhaps the greatest cultural assimilators of them all, the ancient Romans. Ultimately, art isn’t so much a “creation” as an “alteration” of an existing thing.
              </p>
              <p className="fs-5">Ghanaian art differs from much of African art in that it lacks a strong mask-making tradition, which seems odd because this is strong in neighboring countries. Its monumental art, though tending toward the modern, is both prominent and unique, from Posuban Shrines (many of which contain life-sized figures) to Decorative Coffins (also containing life-sized figures–!) to Talking Drums (which can be monstrous in size). Mostly, though, Ghanaian art is a portable, intimate art. From Kente Cloth to Akuaba Dolls to Forowa Boxes, Stools to Adinkra symbols to Gold Weights, it is an art made to be worn, handled, used. As much as any culture I’ve seen, Ghanaians incorporate art into their daily lives in a host of practical ways.
              </p>
              <p className="fs-5">As evidenced by their influence on Picasso and others, Africans are the world’s first and foremost “abstract” artists. Yet their traditional art has always been more about functionality than “creativity,” the “onion coffin” in this exhibition being a notable exception. This exhibit seeks to absorb the viewer into five separate but interlocking “worlds”: ADINKRA, MILITARY/ POLITICAL/COMMERCE, ASSORTED SYMBOLISM, ROYALTY and BIRTH & DEATH. The opening and sixth case explores TOURIST VS. “AUTHENTIC” ART.
              </p>
              <p className="fs-5">As an SMA Lay Missionary having served four years there, I am struck by the haunting, addictive quality of Ghana, a country I have come to love. I can’t remember a day when I didn’t smile or laugh at something, when I didn’t feel at least a twinge of sadness. Ghana charms you even as it breaks your heart. There is a vitality there, a rich and endless swirl of human endeavor that speaks of Africa. I need only look at the African shirts hanging in my closet, shirts that make most Western shirts look sleepy by comparison, to remember just where I was. They are a reflection of the highly visual place Ghana is.
              </p>
              <p className="fs-5">Ghana is a country in transition, awash in contradiction, hungering for All Things Western while struggling to keep many rich traditions alive. Here there is a growing tendency to supplant the old with the new—because it is new, Western. Fifty-plus years after its independence, Ghana struggles to find a national identity, an irony given the richness of its tribal past. Walking down the street of a major city is an adventure in contrast: women in wide-brimmed straw hats selling pork skins—while wearing Western shirts and talking on cell phones.
              </p>
              <p className="fs-5">Like the malaria I contracted five times, Ghana is a place that gets into your blood. Once you get away from the big cities and into the country, you see what a truly beautiful place it is, a beauty made all the more poignant by the relentless felling of its big trees. With this exhibition, I hope to convey some of the richness I experienced in this small gem of a country: hopefully enough to make you want more. Maybe enough to make you go there and see for yourself some day!
              </p>

           

            </Card.Body>
          </Col>
        </Row>
              

              <Row className="g-0 align-items-center">

<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p className="fs-5">“Kwasi”* Sean Hogan, SMA
  </p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>

  <p className="fs-5" >*Twi for “Sunday-born boy”
  </p>

  </Card.Body>
</Col>
</Row>

        

      </Card>
    </>
  );
};

export default SymbolismInGhanacontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase5content = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports - Case V</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
        <Row className="g-0 align-items-center">
           <Col xs="12" md="4" className="ps-md-3 ps-0">
              <Card.Body>
              <Col >
            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Passports_Case7-1.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
          </Col>

            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">Songye and Luba</h4>

              <p className="fs-5">
                The Songye plaquette on the case back was worn on the costume of a dancer or suspended on the wall of a personal shrine. It is an eloquent interpretation of the kifwebe icon, ubiquitous in the Congo region as a full-sized, functional mask.
                The Songye, Luba and other people in the Congo all use kifwebe masks in their initiation rites. All have striations of one kind or another; the Songye kifwebe is angular, the Luba is round or oval. These miniatures display the essential iconography of the kifwebe with admirable economy.
              </p>
              </Card.Body>
              </Col>
        </Row>

        
        <Row className="g-0 align-items-center">

          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case7-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">Kuba</h4>

              <p className="fs-5">The fine surface of this Kuba passport mask looks like ivory but is actually bone, probably the femur or upper cranium of an animal. The mask is rare, not only because of its material, but because of the design incised on the surface. These are motifs we find on Kuba textiles – the famous ‘Kasai velvets’, which we know in the form of cut pile mats and dance skirts.
              </p>

            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

    <div className="me-1 mb-1 d-inline-block">
      <img src="/img/museum/Passports_Case7-4.jpg" className="img-fluid rounded-xl" alt="thumb" />
    </div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4 fs-6 lead fw-bold">Binji</h4>

    <p className="fs-5">
    This Binji mask shares characteristics with other mask styles in what was once the Kuba Kngdom. You can see the perforations around the eyes in the full-size Bena Lulua masksin Case XIV. This form is sculptural in the sense that it engages the space around it from every angle.</p>

  </Card.Body>
</Col>
</Row>
        <Row className="g-0 align-items-center">

          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>

              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case7-3b.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>

            </Card.Body>
          </Col>
        </Row>
        
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>



      </Card>
    </>
  );
};

export default PassportsCase5content;

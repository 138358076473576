import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const ChairsandStoolsIXcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case IX: Chairs and Stools</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XI.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">Throughout the western Sudan, the chairs of men have three legs and those chairs or stools belonging to women have four legs. Lounging chairs are for men in the evening, when the sun is low in the sky and the air is cool. The male elders in Nuna villages light their clay or brass pipes, and lean back in the large carved chairs or ‘dangalo’or the smaller backed stools called ‘daon’.The men exchange news of the day or play with their children. Stools for women are stools for working. Women sit on them while they prepare food, tend their children, and do everything except relax.’</p>
       <p className="fs-6">Christopher D. Roy and Thomas G.B. Wheelock. Land of the Flying Masks: Art and Culture in Burkina Faso. The Thomas B. Wheelock Collection. Munich (Prestel)</p>
       <h4 className="font-weight-bold">Tom Wheelock and Bill Wright</h4>
       <p className="fs-6">‘Late in the spring of 1972, with an intent to traverse the globe, I ventured across the Sahara Desert in a Land Rover. My route was from Algiers, through the Hoggar Mountains to Aggadir in Niger. This was the season of sandstorms. After an arduous journey, I finally crossed the border into Burkina Faso and headed toward Ouagadougou and Bobo Dioulaso. When I arrived in Ouagadougou, I headed straight for the American Embassy. The staff allowed me to camp beside the embassy swimming pool. There I met a peripatetic American art dealer, William Wright, in his road-weary, bright yellow, VW bus. Wright had been buying bronzes for a pittance in Bobo Dioulasso and selling them for unconscionable sums 5,000 miles farther west to retailers like Bloomingdales.
Bill Wright was an affable man with a most engaging enthusiasm. I soon was witness to all manner of wonders, produced from old grain sacks packed away in the depths of his bus. I only recollect an iron staff surmounted by a brass figure, but it was enough to have fatally piqued an acquisitive interest. Wright, not the least daunted by my expressed interest in pursuing an enterprise similar to his, gave me the names of local men who could help me procure traditional objects in the field.
Neither of us could have imagined that the forthrightness of that afternoon would lead to many years of the most pleasurably conducted business as could only befit a lasting friendship such as developed between us.’</p>
       <p className="fs-6">From Tom Wheelock’s introduction to Land of the Flying Masks: Art and Culture in Burkina Faso, The Thomas B. Wheelock Collection, by Christopher D. Roy and Thomas G.B. Wheelock, Prestel: Munich, Berlin, London, New York.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default ChairsandStoolsIXcontent;

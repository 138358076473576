import React, { useEffect, useState } from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { useParams,useLocation } from 'react-router-dom';


const MuseumNav = () => {

  return (
    <Nav className='cta-2'>
   <Nav.Item>
    <Nav.Link eventKey="Home" href='/museum?activeTab=Home'>Home</Nav.Link>

    </Nav.Item>

    <Nav.Item>
    <Nav.Link eventKey="Exhibitions" href='/museum?activeTab=Exhibitions'>Exhibitions</Nav.Link>

    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="Acquisitions" href='/museum?activeTab=Acquisitions'>Acquisitions</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="ResourceseBooks" href='/museum?activeTab=ResourceseBooks'>Resources & eBooks</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="SchoolsProgram" href='/museum?activeTab=SchoolsProgram'>Schools Program</Nav.Link>
    </Nav.Item>
    </Nav>
  );
};

export default MuseumNav;

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import useCustomLayout from 'hooks/useCustomLayout';
import CarouselAutoplay from './CarouselAutoplay';

import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import Service from './Service';


const ButtomBar = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'Service';
  const breadcrumbs = [{ to: '', text: '50 Years of Service' }];
  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });
return (
    <>
      <Row>
        <Col>
          {/* Carousel Start */}
          <section className="scroll-section" id="carousel">
          <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* Vertical Content Start */}
          <section className="scroll-section" id="Service">
          <div className="mb-5">
            <h2 className="cta-2 small-title">50 Years of Service</h2>
            </div>
            <div className="cta-2 mb-5">
              <Service />
            </div>
          </section>
          {/* Vertical Content End */}
        </Col>

      </Row>
    </>
  );

};

export default ButtomBar;

import React from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


const MuseumExhibitions = () => {
  return (


    <Tab.Pane eventKey="Exhibitions" >



    {/* Images Large Start */}
    <section className="scroll-section" id="Exhibitions">
   
    <div className="mb-5">  
 <br></br>
    <h3 className="card-title mb-4">Exhibitions</h3>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/m_citizen_art.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/museumChristianArt?activeTab=Exhibitions" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Africanizing Christian Art
          </Button>
          <div className="text-muted text-truncate">
          The New exhibition will be open on September 29th, 2013.
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>
<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/passport_masks_exhibit.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/museumPassportsMasquettes?activeTab=Exhibitions" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Passports and Masquettes
          </Button>
          <div className="text-muted text-truncate">
          <b> Diminutive Spirits. Passports and Masquettes from the collection of Albert Stanziano.</b>
‘ I would like you to share with me a passion I have had for over twenty five years, ....
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>

<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/Stations_thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/nigeriaStationsOfCross?activeTab=Exhibitions" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Nigeria: The Fourteen Stations of the Cross
          </Button>
          <div className="text-muted text-truncate">
          This rare set of linocuts was printed in 1969 in several editions of about fifty by Bruce Onobrakpeya,...
                 
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>

<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/BeteMask_thumb.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/IvoryCoast" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Ivory Coast: A Passion for Life
          </Button>
          <div className="text-muted text-truncate">
          A new exhibition, A Passion for Life: will open on Saturday, September 22, 2012 in celebration of the event Culture of Ivory Coast. 
                   It will feature Ivorian masterworks ...       
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>

<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/BurkinaFaso_thumb.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/BurkinaFaso" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Burkina Faso: the Thomas G.B. Wheelock Gift
          </Button>
          <div className="text-muted text-truncate">
          Burkina Faso
On display are plank masks, face masks and head crests, figure sculptures, men’s and women’s chairs and stools, ...
                       
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/Mali-Weeden-thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/MaliToday" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Mali Today: Photographs by Richard P. Wedeen, M.D. (online)
          </Button>
          <div className="text-muted text-truncate">
          These photographs of Mali were taken in November and December 2011 by Richard P. Wedeen, M.D.  ...
                                  
          </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/inri_thumb.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">

      <NavLink to="/INRI" > 
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          INRI: Crosses and Crucifixes (2011)
          </Button>
          <div className="text-muted text-truncate">
          The American Province of SMA will exhibit crosses from its permanent collections in the Chapel Wing of the African Art Museum. 
                   Although these crosses and crucifixes are Christian in iconography they are 
                   African in style and reflect the missionary ... </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>

<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/PaxRwanda_thumb.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/PaxRwanda" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Pax Rwanda: Embroideries by the Women of Savane Rutongo (2011)
          </Button>
          <div className="text-muted text-truncate">
          Pax Rwanda: Embroideries by the Women of Savane Rutongo is comprised of twenty exquisite embroideries by a cooperative of Hutu and Tutsi women who work 
       ... </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/ai.jpg" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/Liberia" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Liberia: A Land Old and New
          </Button>
          <div className="text-muted text-truncate">
          Liberia: A Land Old and New celebrates a hundred years of the Society of African Missions in Liberia with masks, 
                   figure sculptures, textiles and costumes from the land founded by freed American slaves in 1847 ...
                    </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card> 



<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/GhanaDolls.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/SymbolismInGhana" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Symbolism In Ghana (2010)
          </Button>
          <div className="text-muted text-truncate">
          The Symbolism In Ghana exhibition begins a six-month run at the African Art Museum of the SMA (Society of African Missions) Fathers in Tenafly, NJ. 
                   Spanning five cases, ...
                    </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


<Card className="mb-2">
  <Row className="g-0 sh-20">
    <Col xs="auto" className="h-100">
      <img src="/img/banner/MaliRabbitMaskDyommo.webp" alt="product-1" className="card-img card-img-horizontal sw-20" />
    </Col>
    <Col>
      <Card.Body className="d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
      <NavLink to="/Mali" >
        <div className="d-flex flex-column">
          <Button variant="link" className="p-0 stretched-link text-start text-large">
          Mali: Masks of the Dogon (2010)
          </Button>
          <div className="text-muted text-truncate">
          The Dogon people have created more than eighty mask types to represent characters in their cosmic myths. They are worn in large numbers in funeral dances. The Dogon use such dances to lead the homeless souls of the deceased to their final resting places in the world of spirit, where they become part of the ancestor realm ...
                       </div>
        </div>
        </NavLink>     
      </Card.Body>
    </Col>
  </Row>
</Card>


</div>


    </section>
    {/* Images Large End */}


        </Tab.Pane>
        
  );
};

export default MuseumExhibitions;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheLegendofChiWaracontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The Legend of Chi Wara

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">The mythology of the Bamana people of Mali tells the story of an antelope named Chi Wara who first taught farming skills to humanity. The headpieces were worn by a Bamana society to represent the mythical agricultural animal during dances held at sowing and harvesting times. At the start of the rainy season, after the fields have been seeded, the Chi Wara crests are danced in the evening.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Danced in pairs
            
            </h4>

            <p className="fs-5">Chi Wara are danced in male and female pairs, a marvelous statement of the complementary nature of life. The dances are performed by vigorous young men in the fields while a chorus of women sing the praises of the ideal farmer.
            The women also contribute jewelry to the antelope sculpture. During the dance, each masked performer is accompanied by a woman who dances behind him. In this way the Chi Wara dance alludes to the fruitful union of male and female analogous to the union of the sun (considered a male power) with the earth (considered female), which leads to the fruitfulness of the fields.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">A roan antelope</h4>
            <p className="fs-5">The headpiece depicts a roan antelope. The body of the Chi Wara is said to also represent an aardvark, whose habit of burrowing in the earth is seen to mimic farming. The zigzag mane parallels the path of the sun between the two solstices. The horns stand for the millet stalk, a staple of Bamana life. The female Chi Wara carries her baby on her back just as do African women.
            
            </p>

            <h4 className="mb-4 fs-6 lead fw-bold">A raffia fringe</h4>

            <p className="fs-5">The headdress is worn with long owing raffia fringe which represents later, the third necessary element for the growth of plants.
            Thus is the fertility of the land evoked by the contributing elements of earth, sun, and water, as the fertility of humanity is saluted in the pairs of male and female dance headcrests.
            Chi Wara antelopes come in different forms based upon the regions where they were carved.</p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheLegendofChiWaracontent;

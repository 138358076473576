import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase6Content = () => {
  return (
    <>
      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
      <h4 className="mb-4 fs-6 lead fw-bold">Passports - Case VI</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
        <Row className="align-items-center">
          <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>
            <div className="me-1 mb-1 d-inline-block">
              <img src="\img\museum\Passports_CaseV-2.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
             <h4 className="mb-4 fs-6 lead fw-bold">Lega</h4>
              <p className="fs-5">
              The Lega are famous for their ivory statuettes; however, these masks are made of wood. Large masks belong to the earth clan and are painted with pembe each time they are brought out. They are used in dramatic performances involving song, dance and recitation of proverbs. Miniature masks like the ones shown here are worn on the arm or kept in a basket. Their form is marked by a heart-shaped, concave face with a delicate mouth, pointed chin and eyes shaped like cowrie shells.
              Sometimes the eyes take the form of a point in a circle. The Lega live in the eastern virgin forests of eastern Democratic Republic of Congo, between the great lakes and the Lualaba River.
              </p>
            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">
          
        </Row>
        <Row className="g-0 align-items-center">
          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>
              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_CaseV-1.jpg" className="img-fluid rounded-xl" alt="thumb" />
              </div>
            </Card.Body>
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <p className="fs-5">Over the years I have had numerous Lega passport masks come my way, only to go their way – the way of all fakes. The mask with the high concave forehead is for protection from snakes.


              </p>
            </Card.Body>
          </Col>
        </Row>
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>

      </Card>
    </>
  );
};

export default PassportsCase6Content;

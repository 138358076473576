import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase17Content = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
   
 
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports – Case XVII</h4>
       
    

     </Card.Body>
   </Col>
 </Row>
      <Row className="g-0 align-items-center">
      <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>

          <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case2-1.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
          </Card.Body>
</Col>

    
 
   <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-6 lead fw-bold">The Mambila</h4>

       <p className="fs-5">
       The Mambila live in the eastern part of Nigeria on the Sardauna plain in the province of the same name. They are farmers and cattlemen. Their masks represent animals – the crow, a taboo animal; the owl, who announces death; and the dog, necessary for hunting. Traditionally, these masks could be seen only by men. They were made by amateurs, not trained professionals, which accounts for their varying quality. The masks were used at semiannual celebrations of sowing and harvesting.
Mambila passports and miniatures are extremely rare. In fact, I have never seen a large, functional Mambila mask like the miniature on the left. The perfectly rendered ‘crow’ mask on the right is much more familiar to the student of African art.
</p>
    

     </Card.Body>
   </Col>
 </Row>

      <Row className="align-items-center">
          
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case2-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                    <h4 className="mb-4 fs-6 lead fw-bold"> The Salampasu</h4>
             
                   <p className="fs-5">
                   The Salampasu miniatures reproduce all the characteristics of their larger relatives, down to the smallest detail. By so doing, they effectively borrow the protective powers of the larger masks, repositories of spirits when they are danced.
Living east of the Kasai River in Democratic Republic of Congo (formerly Zaire), the Salampasu are a warrior people with no centralized form of government. Their masks and figure sculptures are made for use in initiation rites and ceremonies. The costume of the mask, comprised of animal skins, fiber and feathers, is sacralized, that is, the spirit is in the costume as well as the face covering. The entity of mask/costume is called akish; when it is worn – that is, possessed by the spirit, it is called mukish.</p>
      
                   </Card.Body>
                 </Col>
              </Row>


        
              <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>

      </Card>
    </>
  );
};

export default PassportsCase17Content;

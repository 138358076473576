import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const AkuaBaTheFertilityDollscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Akua Ba The Fertility Dolls
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">The best known and most numerous Asanti carvings are the akua’ ba. These so-called fertility dolls are carved for women who are having difficulty conceiving children or, according to some accounts, for women who are pregnant. A woman takes such a carving to a priest who consecrates it and invokes the influence of a deity to induce pregnancy (or in the case of a woman who is pregnant, to ensure the birth of a perfect child). The woman then carries the figure for a stated period of time tucked into the back of her waist cloth. The figures are fondled, dressed and adorned with earrings and waist beads just as a real child would be. The akua’ba embody the Asanti ideal of beauty with a disc-shaped head and rings of fat around the neck indicating a prosperous and healthy condition. Asanti mothers seek to induce the desired head shape in their infants by gently massaging the soft cranial bones of the newborns.</p>
           
           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default AkuaBaTheFertilityDollscontent;

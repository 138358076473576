import React from 'react';
import { Button, Card, Col, Row ,ListGroup,ButtonGroup} from 'react-bootstrap';
import GlideGallery from 'components/carousel/GlideGallery';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const DetailsCarousel = () => {
  const galleyItems = [
   {
      large: '/img/product/large/vaction_1_large.webp',
      thumb: '/img/product/large/vaction_1_small.webp',
    },
    {
      large: '/img/product/large/vaction_2_large.webp',
      thumb: '/img/product/large/vaction_2_small.webp',
    },
    {
     large: '/img/product/large/vaction_5_large.webp',
     thumb: '/img/product/large/vaction_5_small.webp',
    }

  ];
  const galleyItems2 = [
    {
      large: '/img/product/large/vaction_3_large.webp',
      thumb: '/img/product/large/vaction_3_small.webp',
     },
     {
      large: '/img/product/large/vaction_4_large.webp',
      thumb: '/img/product/large/vaction_4_small.webp',
     }
 
   ];

  return (
    <>
  <Card>
<Card.Body>
          <h4 className="mb-3">REQUIREMENTS FOR SMA CANDIDATES</h4>
          <div>
            <p>
            The Society of African Missions (S.M.A.) is looking for candidates who are responding to God’s call in their lives with a deep desire to live 
            the Gospel and grow in their relationship with Jesus. The Society of African Missions is committed mainly in Africa. S.M.A. has a call to 
            proclaim and live the Gospel in the African Missions and with our brothers and sisters of African descent. The special calling of S.M.A is
             a ministry to the most abandoned, wherever they are sent.
            </p>
          </div>
        </Card.Body>
        </Card>

      <Card>
      <h4 className="small-title">PRIESTHOOD</h4>
        <GlideGallery>
          <GlideGallery.LargeItems>
            {galleyItems.map((item, index) => (
              <GlideGallery.Item key={`half.boxed.large.${index}`}>
                <img alt="detail" src={item.large} className="responsive border-0 rounded-top-start rounded-top-end img-fluid mb-3 w-100 sh-60" />
              </GlideGallery.Item>
            ))}
          </GlideGallery.LargeItems>
          <GlideGallery.ThumbItems className="mb-3">
            {galleyItems.map((item, index) => (
              <GlideGallery.Item key={`half.boxed.thumb.${index}`}>
                <img alt="thumb" src={item.thumb} className="responsive rounded-md img-fluid" />
              </GlideGallery.Item>
            ))}
          </GlideGallery.ThumbItems>
        </GlideGallery>

        <Card.Body>
          {/* Flush Start */}
          <section className="scroll-section" id="flush">
            
            <Card body className="mb-5">
              <ButtonGroup>
                <ListGroup variant="flush">
                           <ListGroup.Item>Catholic: Have certificates for Baptism, Holy Communion and Confirmation and any other documents showing 
                    status in the Catholic Church.</ListGroup.Item>
              <ListGroup.Item>Single</ListGroup.Item>
              <ListGroup.Item>Between 20 – 45 years of age (negotiable)</ListGroup.Item>
              <ListGroup.Item>A person of good moral and physical character</ListGroup.Item>
              <ListGroup.Item>Education: All candidates must have a high school diploma or its equivalent. It is desirable that a candidate has 
                completed an undergraduate degree in college. Suitable candidates, who lack a degree, however, may still be considered. 
                The degree is more important for older candidates who desire priesthood, since it requires four to five years of graduate studies. 
                For those who have been in the workplace, a stable work record is important.</ListGroup.Item>
              <ListGroup.Item>Debts: Ideally, candidates should be debt-free or have a plan for dealing with their debts.</ListGroup.Item>
              <ListGroup.Item>Legal residents in the US can be considered.</ListGroup.Item>

                </ListGroup>
              </ButtonGroup>
            </Card>
          </section>
          </Card.Body>

          

      </Card>
<br></br>
      <Card>
      <h4 className="small-title">LAY ASSOCIATE</h4>
  <GlideGallery>
    <GlideGallery.LargeItems>
      {galleyItems2.map((item, index) => (
        <GlideGallery.Item key={`half.boxed.large.${index}`}>
          <img alt="detail" src={item.large} className="responsive border-0 rounded-top-start rounded-top-end img-fluid mb-3  w-100 sh-60" />
        </GlideGallery.Item>
      ))}
    </GlideGallery.LargeItems>
    <GlideGallery.ThumbItems className="mb-3">
      {galleyItems2.map((item, index) => (
        <GlideGallery.Item key={`half.boxed.thumb.${index}`}>
          <img alt="thumb" src={item.thumb} className="responsive rounded-md img-fluid" />
        </GlideGallery.Item>
      ))}
    </GlideGallery.ThumbItems>
  </GlideGallery>

  <Card.Body>
                   {/* Flush Start */}
                   <section className="scroll-section" id="flush">        
            <Card body className="mb-5">
              <ButtonGroup>
                <ListGroup variant="flush">
                <ListGroup.Item>Catholic: Have certificates for Baptism, Holy Communion and Confirmation and if applicable, marital status</ListGroup.Item>
              <ListGroup.Item>Single or Married (without dependents)</ListGroup.Item>
              <ListGroup.Item>Completed college or have qualifications in their area of expertise</ListGroup.Item>
              <ListGroup.Item>In good health</ListGroup.Item>
              <ListGroup.Item>Open to longer term commitment of 2 years (renewable)
There is the possibility of making a permanent commitment with SMA after several years (3 to 4 tours of mission service)</ListGroup.Item>
              <ListGroup.Item>Candidates can be considered if they have resided for two years in the US,
                 hold legal immigration status and meet SMA requirements.</ListGroup.Item>

                </ListGroup>
              </ButtonGroup>
            </Card>
          </section>
          {/* Flush End */}
    </Card.Body>

    

</Card>
    </>
  );
};

export default DetailsCarousel;

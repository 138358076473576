import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheIvoirianBeadscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The Ivoirian Beads
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Among the Baule, and possibly elsewhere, great numbers of beads were cast in gold. They also exists in brass, and a few are of silver. These beads probably represent old Akan forms which were adopted by the Anyi and Baule in the seventeenth or eighteenth century. Their forms are basically simple and abstract: discs, rectangles, tubes and bicone forms predominate. A few others represent tusks, horns, claws and leopard’s teeth, but these are uncommon. There is no equivalent to the rich variety of nineteenth-century Akan bead types in the form of seeds, shells, drums stars, human teeth stools, etc.
            The Ivorian beads were most often worn by women and children, but they were also used in other contexts.</p>
            <p className="fs-5">It was not unknown for them be used to adorn items of wood sculpture, such as “spirit spouse” figures and the statuettes used by spirit mediums. Large disc-shaped and rectangular beads were sometimes made for a chief; on public occasions they were hung before him suspended from strings. Whether this was done simply to emphasize his prestige, or for some ulterior motive (such as to keep away evil influences) is not known.</p>
            <p className="fs-5">The Baule bestowed many fanciful names on their different bead types. Most of these are unknown to the present gene-ration, but they are still remembered by old ladies and by a few goldsmiths. Those of larger size were known as senze «the setting sun», or even kwa jamma, «the slave’s toe». A Goldsmith explained the last name by saying that «the slave valks a lot so his toes becomes bigger».</p>

            <h4 className="mb-4 fs-6 lead fw-bold">Thimothy F.Garrand, </h4>            <p className="fs-5">Gold of Africa, Prestel 1989.</p>


            <p className="fs-5"></p>
            <h4 className="mb-4 fs-6 lead fw-bold"></h4>
            <p className="fs-5"></p>

            <h4 className="mb-4 fs-6 lead fw-bold"></h4>

            <p className="fs-5"></p>
            <p className="fs-5"></p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheIvoirianBeadscontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Holiday = () => {
  return (
    <>
      <Card>

      <Row className="g-0 align-items-center">

<Col xs="12" className="ps-md-3 ps-0">
  <Card.Body>

              <p>
              <h4>
              How Two lay Missionaries return home just in time for the Holiday season after 2 years of Lay
mission work in various parts of Tanzania.</h4></p>
<p>
Right before the world as we knew it changed into pandemic mode, with most of humanity placed
amongst worldwide mandates and quarantines. Two Missionaries let God lead them on their first lay
mission journey to Tanzania
I sat down with Mikki Wente and Anastasia (Ana) Jeong Nam, the two Lay Missionaries who traveled to
Tanzania for a 2-year experience that changed many lives. Here are their stories.</p>
<p>
My interview with both lay missionaries started off with extreme gratitude. Both Ana and Mikki
immediately went into how grateful they were for the opportunity, how welcoming the people of
Tanzania are, and how they both could not say Goodbye to students, parishioners, farmers, and friends
they made over the course of two years in Tanzania. Although Mikki and Ana both set on a SMA
Missionary journey in December of 2019 together their experiences are so different.
</p>
  </Card.Body>
</Col>

</Row>
<Row className="g-0 align-items-top">
          <Col xs="12" md="6">
          <Card className="sh-90  hover-img-scale-up">
                  <img src="/img/banner/holiday1.png"  alt="card image" />
                  </Card>
          </Col>
          <Col xs="12" md="6" className="ps-md-0 ps-0" >
          <Card.Body >
          <p><h4><b>Mikki’s Story: Village Life!</b></h4></p>
  
          <p>   I sat down one on one with Mikki Wente first. She originally calls Montana home, but she walked me
through two years of what it was like to live in Bugisi a small village of about 50,000 people where she
stayed for her entire mission trip in Tanzania. Mikki begin with the background information, the basics
rather, to give me a better understanding of what was going through her mind and how Tananniza
unfolded for her. In December 2019 Mikki only knew she would be going to East Africa and the location
was going to be either Kenya or Tanzania. After spending the first 3 months of training learning Swahili
she found out she would be helping in a small village in Tanzania called Bugisi. Collaborating with Our
Lady of Apostle also known as OLA, Mikki’s day started with a 7am mass daily, breakfast at 8:30 then
administrative work assisting the sisters at mass or during prayer services. Shortly after working within
her routine Mikki found more ways to become more involved with the people. Mikki often though to
herself, that her true mission was to connect with God’s people.</p> 
                  </Card.Body>
          </Col>
          </Row>
        <Row className="g-0 align-items-top">
   
          <Col xs="12" md="12" className="ps-md-0 ps-0">
            <Card.Body>
<p>
During this time Coronavirus had insignificant impact on Mikki journey as the numbers were almost
nonexistent. Within the village it was very calm. They encouraged small children and mothers with
infants to be cautious and stay home from Mass in additional to the boarders being closed. In Bugisi
they decided not to panic the people regarding covid. As that would have enticed panic looting, into
homes which are made of mud. So, the authorities believed it was best to avoid more chaos.
</p>
<p>
With no electricity or bathrooms outside of the parish, Mikki made the most of her time. Mikki would
take time learning to farm and garden like a local, make jam, learn to make baskets used at the market,
making toy cars for the children, and becoming handy fixing and restitching shoes just to name a few
things. Harvesting many herbs as medicines, nutrients or even Tea consumption Mikki quickly adapted
and embraced her new surrounds. This part was not difficult for Mikki as she has 20 years of Military

background and traveled for most of her life. This time it was special to her, as she was there doing
God’s work focusing on the positives, the good in all the circumstances.
</p>
<p>
“Our lives are meant to be shared with other people. Time with locals, and just being in the
moment. Not getting so caught up in missionary work, that was my job.” Mikki explained it was best for
her to understand why she was there. And repeated to herself “Don’t forget the people!”. Mikki’s focus
during this mission was to share herself. Meaning people like stories, be in the experience, talk to them,
make an impression on them with the 2 years you are here. Believing that their thought was “There was
1 foreigner in my life that made a difference.” So Mikki says “So, what is that difference going to be, it is
up to you.”</p>


</Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" className="ps-md-3 ps-0">
                   <Card.Body><p>The Main challenge Bugisi faces is the weather. They have a wet season and a dry season. The Dry
season normally last for 6 months and the people of Bugisi rely solely on rainwater. So, the framers
tend to their corps, and garden during the Rain months to make sure they have enough food to last
during the dry season and hopefully have enough food where they can sell, to make money for the next
season. Unfortunately, this year Bugisi is currently in their 8th month of dry season and will have just
enough to feed themselves and not much of abundance to sell at all this year. However, the people are
still optimistic living in the Now, and taking every day one day at a time no sure how much longer the
dry season will last.

Living under certain circumstances, the nearest city being a one-hour car ride away and the main means
of transportation in Bugisi being a bike. Mikki learned a plethora of games and skills that she was able to
then pass on to the older children such as stitching shoes and making baskets, whom than started
teaching the younger children by the time Mikki’s 2-year Lay mission journey was coming to an end.
Emersed in the culture just spending time with the people Mikki became well known especially on
Market Day which was on Mondays. Just by speaking and learning from people and various vendors, or
some nights eating dinner with one family who invited her frequently to their home. Mikki took her
responsible to be presence with the people very seriously.
Mikki learned and admired that despite their challenges living in Bugisi , the people were always full of
life and optimism. “The people have an infatuating spirit about them, no matter their circumstances.
Whether its lack of Food, Education, Medicine, or Assistance on building a new church.” Mikki apricated
how they were always in great spirts, and ready to laugh. Click here to Donate?</p>
<p>
By simply showing up for the people with a kind heart Mikki quickly became a part of the village, willing
to Help, talk to, Teach, and Play with everyone around. As a surprise Mikki made two young girls who
would look for her in Mass every Sunday a Rosary of their own, for Rosary Prayer month this pass
October before she left. Mikki’s goal was to always stay connected to the people, she said to me
“Outside of your parents, who looks for you.” And that is what fueled her to look out for the people in
Tanzania.

Mikki is back state side and excited to be home but explained how she really could not say Goodbye. She
told the kids she had a meeting to go to for work but could not really tell them Goodbye, more like a see
you later. Excited about the toy cars and other little gifts the kids made for her before she left, Mikki
says she’d like to see in the Museum. However, as for Mikki her next steps she says, “It’s to God.”
</p>
                   </Card.Body>
                 </Col>
                
               </Row>
               <Row className="g-0 align-items-top">
          <Col xs="12" md="6">
          <Card.Body>
                  <img src="/img/banner/holiday2.png" className="card-img h-100 scale" alt="card image" />
                  </Card.Body>
          </Col>
          <Col xs="12" md="6">
          <Card.Body>
          <p><h4><b>Ana’s Story: City Living in Tanzania.</b></h4></p>
        
        <p><h4>† Tumsifu Yesu Kristo, Milele Amina.
(Praise to Jesus Christ. Forever Amen)</h4>
Is how Anastasia “Ana” Jeong Nam wanted to start her article. It is Swahili for Praise to Jesus Christ
Forever Amen. Ana’s experience is quite different from Mikki, and they are both too incredible to
compare. Although both of their Lay missionary journeys begin together with 3 months of one-on-one
language tutoring, the next steps are where their experiences offer different living perspectives.</p>
<p>
Ana was stationed in a city called Mwanza where the population is up 5% to 1,182,000 people as of
2021, and it is now the Second Largest city in Tanzania. The locals also refer to Mwanza as The Rock City
because of its gigantic rocks sitting in Lake Victoria and streams in Tanzania. Ana describes Mwanza as
being kindred to her home of Seoul, Korea. Ana expressed how much she enjoys the people, their
energy, and their zest for life as being one of her favorite parts of Tanzania. The energy of the people
was what enticed Ana to join a lay mission back in 2017.
</p>
                  </Card.Body>
          </Col>
          </Row>
               <Row className="g-0 align-items-top">
  
          <Col xs="12" md="12" className="ps-md-0 ps-0">
            <Card.Body>

<p>
Back in 2017 Ana had taken a trip to Tanzania, with a Priest from her home parish. From that visit she
knew she wanted to return to Tanzania to do God’s work. Nervous about going and unsure about being
prepared for her Lay mission Ana kept repeating to herself “You are an example of God’s love,” and
wanted to arrive with “Open Minds, and Open Eyes.” So, when she was told in December of 2019, she
would be on a Lay mission in Tanzania, she was elated.

After her three months in training and language course completion, Ana was assigned as a Catechist.
Where she would be teaching catechism to the teenage Catholic students. Upon her arrival the
Coronavirus Pandemic begin, and Secondary (Junior High) Schools were shut down for students for a
complete semester. A little saddened Ana used that time to help the parish sisters with elementary
school students, Mass, and outdoor activities.</p>

<p>
By the time September 2020 came around Ana begun her first class with two hundred students at the
all-boys school called Bwiru Secondary School. It took a little getting used to as Ana would create an 8-
10-page lesson plan in Korean, then translate it into English, then translate it to Swahili weekly. This
would take many hours each week, but it helped Ana practice her English and Swahili.

By the end of her first semester teaching Ana moved on the Kitangiri Secondary school where she
taught three hundred Students girls and boys, then Mnarani Secondary School to teach four hundred
more. Ana found immense joy in teaching Catechism and grew closer to all her students, some students
even wanted to learn Korean, but Ana wanted to do more.
Ana begun to get more involved with media for the parish, she had experience from her home parish
and wanted to provide media assistance with photos during classes, Mass, and various visits to
difference villages. During the weekends Ana begun to travel to all 8 Parishes in Tanzania attending
Mass with the sisters and Priest of the Parish. Ana would take photos, video, and just learn from the
many different tribes she would interact with. Collectively throughout her 2 years on her lay mission Ana
was learning to meet people from each of the two hundred different tribes in Tanzania and begun
greeting them in their own dialects.

Instructing students became easier as she got used to the language and the students would assist her
while she learned Swahili. Christmas and Easter are widely celebrated holidays in Tanzania and Ana
wanted to give back to her students. She expressed how difficult it was on a Lay mission salary. So, Ana
tapped into her resources back home and reached out to non-Catholic friends, high school friends and
acquaintances. Who were able to donate Prayers books, notebooks, pens, pencils, and cookies to all of
her students. She said it was excited as well as emotional for her to witness as some of the student had
never received gifts for Christmas or Easter in their life. That was a sign for Ana, and she knew than that
“God had prepared her and sent her there to help.” Although Ana, wanted to go to Tanzania for her Lay
Mission, Ana had never imagined teaching in Tanzania. She often thought she was not equipped to
manage the task God had given her, but she later believed all the years she taught Sunday School in
Korea was God’s preparation for her and she was where she was meant to be.</p>
</Card.Body>
          </Col>
          </Row>
      
<Row className="g-0 align-items-center">

<Col xs="12" md="6" className="ps-md-3 ps-0">
  <Card.Body>
            
<p>


Although Ana’s assigned task was teaching Ana wanted to bring more life into the parish, into the
students, and into the classrooms she taught in. So, Ana took notice on the difficulty it was for some of
her disabled students to attend classes. As her class size grew larger Ana had to move some of her
classes outside because the classrooms were getting too small. This sparked an idea Ana had and she
quickly went to work on it. She began working with the parish and schools on larger spaces and
classrooms accessibility, and once again reach out to an acquaintance she was introduced to that was
connected to the welfare center for the disable in Korea. That was just the person Ana needed to meet
as they were able to donate funds and supplies for wheelchair ramps to classrooms for all students with
disabilities, not just hers.

One thing Ana started the interview by saying was she began her journey with and open mind and was
not very sure what to expect. However, after 2 years Ana has many favorite parts of her Lay mission in
Tanzania such as the food, the various projects she worked on, students she was able to help with
prayer books, supplies, and access to classrooms, teaching, and traveling. But most of all Ana says the
people still bring her happiness and joy with their lively energy, she still enjoys their outlook on life.

As far as next steps for Ana, she said it was hard saying goodbye to her students after each semester,
but she is excited to return to her church Archdiocese in Seoul. Ana says she is open to wherever God
needs her. Right now, she is preparing a donation platform to assist the Parish in Mwanza where she
spent most of her weekends worshiping. The donations would be raised for a new roof. Currently, the
rain season last for 6 months and makes it difficult for parishioners to attend mass, because they are
unable to hear over the noise of the tin roof, and the speaker equipment is not in the best condition
either. Click here to donate?
• Ana’s main advice to anyone interested in a Lay Mission is “Make sure you are happy, so you can
project that happiness on to everyone around you. Because you are an Example of God’s Love.”</p>
  
  </Card.Body>
</Col>
     <Col xs="12" md="6">
                         <img src="/img/banner/holiday3.png" className="card-img h-100 scale" alt="card image" />
                 </Col>
</Row>

      </Card>
    </>
  );
};

export default Holiday;

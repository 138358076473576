import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BeadedArtistrycontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Beaded Artistry

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Elaborate beadwork is found in many traditions in Africa, too numerous to fully explore. While some beads came through early trade, others were created by Africans. Beadwork is often found only on prestige items due to its costly labor intensive demands.
            The Artistry of Traditional African Sculpture includes an ade beaded crown and a beaded ifa priest’s bag which illustrate some of the extraordinary complexity of design and imagery found on specific ritual items.</p>
            <h4 className="mb-4 fs-6 lead fw-bold"></h4>

            <p className="fs-5">A beaded face on the crown refers to the “inner head” (orinun) or to the spiritual power of the devotee. Yoruba beaded crowns are worn by a chief. Thin strands of beads hang down covering the chiefs face, which is said to glow from divine spiritual power.
            Royal beadwork for Yoruba courts included slippers, pillows, flywhisks, gowns, caps, and crowns. Birds on a kingly crown symbolize a mastery over witchcraft and evil power. They allude to the ability of the king to communicate with spirits of departed kings: (whose faces appear on the crowns).</p>
            <p className="fs-5">The Museum of the SMA Fathers also owns a beaded mask from Cameroon, generally attributed to the Bamileke culture although also found among the Batut, Bali, Bamum, and Bangwa. This mask came from an exclusive society known as kuosi and kemdde, a privileged group who were under the direct control of the paramount ruler. Worn during public assemblies of the society, the large disc ears and trunk-like panel allude to the elephant, a symbol of power, and the spots refer to the leopard, a symbol of force. The lavish use of expensive beads displays the power and wealth of Cameroon royalty.</p>
            <h4 className="mb-4 fs-6 lead fw-bold"></h4>
            <p className="fs-5">Beadwork can be found across Africa. Beaded aprons from the Ndebele culture of Southern Africa shows the high level of the craft as it once was practiced by Ndebele women. An elaborate apron would be a gift, possibly given at the time of marriage, to be worn by wives upon special occasions. The extensive beading of our example dates it to the early part of this century. In recent decades, less beadwork has been appearing on aprons, and recent examples even have plastic glued upon them. The beadwork of Africa is in itself a rich study of a passing art form. African beadwork is a monument to the creative energy, imagination, and skills of past generations.</p>

            <h4 className="mb-4 fs-6 lead fw-bold"></h4>

            <p className="fs-5"></p>
            <p className="fs-5"></p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default BeadedArtistrycontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const JewelryIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case II: Jewelry</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-II_Sel.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The arts of personal adornment in Africa include body and face painting and scarification, coiffure, clothing, and jewelry. Enormous amounts of time and energy are devoted to the acquisition of raw materials, preparation, manufacture and connoisseurship of the objects produced in this vast industry. These arts are practiced by amateurs as well as professionals. They enhance the appearance of men, women and children, rich and poor, but they do more than that. The style of the hair, the marks on the face and body, the shape and color of apparel and the jewelry worn on head, face, neck, arms, torso and legs gives a great deal of information about the age, sex, marital status, immediate family and clan, social and political rank, and religion of the wearer. Jewelry may also have a magical, protective function. Bwa and Gurunsi women wear anklets with images of spirits that protect them. Mossi men once wore cast brass rings with images of the masks that guard their well-being.</p>
       <h4 className="font-weight-bold">Materials and Techniques</h4>
       <p className="fs-6">African jewelry is made of anything that comes to the hand and fancy of the maker. But it is the metalwork that astonishes us by its imaginative form and extraordinary workmanship. Iron, bronze, brass, copper, silver, gold, aluminum, and tin may be used in pure form or combined to make alloys. Many pieces are made from recycled materials. The bracelet with the leaf mask in the center of this case is made from an aluminum alloy used in the engine blocks of the Peugeot mopeds that have been the most important means of transportation in Burkina Faso since the 1950s. (Roy, p. 75)
These metals may be forged, hot or cold cut, twisted, soldered together and/or cast. Casting in West Africa is by the lost wax process (Fr. moule a cire perdue – to mold on lost wax). In cire perdue, a wax image in the exact form of the desired metal object is formed around a clay core. An outer layer of clay is then applied to the surface of the wax. Sprues (tiny tubes) lead to the waxen layer.
When molten metal is poured into the sprues leading to the wax, it melts – is lost, perdue. The clay mold is then broken revealing the metal object within. The baked clay core may be removed, but frequently all or part of it is left inside the work of art. All of the objects in this case were made in this way.
The cire perdue method of metal casting is used all over the world. We find it in masterpieces from ancient China, Greece and Rome, renaissance Italy, and West Africa.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default JewelryIIcontent ;

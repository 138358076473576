import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const DecorationXXIVcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XXIV: Decoration</h4>
      <div className="text-center me-1 mb-1">
        <img src="\img\museum\BurkinaFaso-Case-XXIV.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">We refer to the parts of a pot as follows: the foot, the waist, the shoulders, the neck the lip, the handle(s), the spout. These are not only important to the structural integrity of a vessel, they are natural focal points for decorative emphasis and enhancement.
‘Very often women decorate their pottery with raised lines that represent scars or graphic patterns or with incised lines that belong to the same body of graphic patterns carved on masks or woven into textiles. In addition they apply figurative shapes such as faces, breasts umbilici and more. I have often asked about the decoration and the artists reply that it is to make them (the pots) pretty. Women begin to talk about magical protection provided by the designs if I am more persistent. Some older women wear marks on their bellies that are identical to those on the pots.
Some of these are applied after the birth of each of their first children. It is quite apparent that the jars are metaphors for the protective, nurturing character of the body of a woman.</p>
       <p className="fs-6">’Roy, ‘The Art of Burkina Faso in a Cultural Context’ in Land of the Flying Masks: Art and Culture in Burkina Faso. Prestel, 2010.
       The lack of a kiln, with its capacity for high firing, precludes the use of glazes on African pottery. But surfaces may be treated with many kinds of substances to add color. They may, in addition, be burnished with a pebble, bone, or shell. The contrast between rough and smooth areas is part of the aesthetic of African pottery.
       </p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default DecorationXXIVcontent;

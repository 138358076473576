import { DEFAULT_PATHS } from 'config.js';
import { lazy } from 'react';
import HomePage from 'views/Home';
import AboutPage from 'views/About';
import AboutPage2 from 'views/Aboutwwa';
import VocationPage from 'views/Vocation';
import ButtomBar from 'views/ButtomBar';
import FooterBarHoliday from 'views/FooterBarHoliday';
import FooterBarCattle from 'views/FooterBarCattle';
import FooterBarMaccalli from 'views/FooterBarMaccalli';
import MuseumChristianArt from 'views/MuseumChristianArt';
import MuseumPassportsMasquettes from 'views/museumlib/MuseumPassportsMasquettes';
import IvoryCoast from 'views/museumlib/IvoryCoast';
import BurkinaFaso from 'views/museumlib/BurkinaFaso';
import TheInlandNigerDeltaI from 'views/museumlib/TheInlandNigerDeltaI';
import JewelryII  from 'views/museumlib/JewelryII';                                         
import AnimalSpiritsVIII   from 'views/museumlib/AnimalSpiritsVIII'; 
import  BatebaMorphologyVII   from 'views/museumlib/BatebaMorphologyVII';
import  PlankMasksV   from 'views/museumlib/PlankMasksV';
import  TheLobiVI from 'views/museumlib/TheLobiVI';
import   ColorandPatternIV from 'views/museumlib/ColorandPatternIV';
import  WaluandKomoMasksXV from 'views/museumlib/WaluandKomoMasksXV';
import  TheDamaandtheKanagaMaskXIV from 'views/museumlib/TheDamaandtheKanagaMaskXIV';
import  TheGurunsiandtheAntelopeMasksXIX from 'views/museumlib/TheGurunsiandtheAntelopeMasksXIX';
import RealismandAbstractionXVIII  from 'views/museumlib/RealismandAbstractionXVIII';
import SharedIconographyandtheGroundHornbillXVII  from 'views/museumlib/SharedIconographyandtheGroundHornbillXVII';
import  ChairsandStoolsIII from 'views/museumlib/ChairsandStoolsIII';
import MasksWithCrestsXX from 'views/museumlib/MasksWithCrestsXX';
import CarvingandWearingaMaskXXI from 'views/museumlib/CarvingandWearingaMaskXXI';                   
import FormandFunctionXXIIXXIII from 'views/museumlib/FormandFunctionXXIIXXIII';                 
import DecorationXXIV from 'views/museumlib/DecorationXXIV';
import BushcowMasksXVI  from 'views/museumlib/BushcowMasksXVI';
import ChairsandStoolsIX from 'views/museumlib/ChairsandStoolsIX';
import MossiFigureSculptureX  from 'views/museumlib/MossiFigureSculptureX';
import NakomseandNyonyoseXI  from 'views/museumlib/NakomseandNyonyoseXI';
import NyonyosiStoneSculptureXII from 'views/museumlib/NyonyosiStoneSculptureXII';              
import BuraSudanandNigerXIII from 'views/museumlib/BuraSudanandNigerXIII';  
import MaliToday from 'views/museumlib/MaliToday';
import INRI from 'views/museumlib/INRI';
import PaxRwanda from 'views/museumlib/PaxRwanda';
import Liberia from 'views/museumlib/Liberia';
import SymbolismInGhana from 'views/museumlib/SymbolismInGhana';
import Mali from 'views/museumlib/Mali';
import MaskBeteIvoryCoastCA from 'views/museumlib/MaskBeteIvoryCoastCA';
import DoorNupeNigeriaCA from 'views/museumlib/DoorNupeNigeriaCA';
import CommemorativePortraitofSalifKeita from 'views/museumlib/CommemorativePortraitofSalifKeita';
import MeaningofanAfricanMask from 'views/museumlib/MeaningofanAfricanMask';
import TheTallMask from 'views/museumlib/TheTallMask';
import GuidetoCollections from 'views/museumlib/GuidetoCollections';
import  AfricanSculpture from 'views/museumlib/AfricanSculpture';
import AfricanArtandEuropeanArtists  from 'views/museumlib/AfricanArtandEuropeanArtists';
import  ACulturalHeritagethroughArts from 'views/museumlib/ACulturalHeritagethroughArts';
import BeautyandtheBeasts from 'views/museumlib/BeautyandtheBeasts';
import IbejiTwinChildren from 'views/museumlib/IbejiTwinChildren';
import  GeledeMasks from 'views/museumlib/GeledeMasks';
import  TheLegendofChiWara from 'views/museumlib/TheLegendofChiWara';
import AkuaBaTheFertilityDolls from 'views/museumlib/AkuaBaTheFertilityDolls';
import BonduMask from 'views/museumlib/BonduMask';
import BeadedArtistry from 'views/museumlib/BeadedArtistry';
import TheIvoirianBeads from 'views/museumlib/TheIvoirianBeads';
import TheBlackStoolSymbolofPower from 'views/museumlib/TheBlackStoolSymbolofPower';
import TheArtistryofTraditionalAfricanSculpture from 'views/museumlib/TheArtistryofTraditionalAfricanSculpture';
import TheLegacyofAfricanArts from 'views/museumlib/TheLegacyofAfricanArts';
import TheAfricanArt from 'views/museumlib/TheAfricanArt';
import TheSMAFathersandtheMuseumofAfricanArt  from 'views/museumlib/TheSMAFathersandtheMuseumofAfricanArt';
import PassportsCaseIV from 'views/museumlib/PassportsCaseIV';
import PassportsCase6 from 'views/museumlib/PassportsCase6';
import PassportsCase5 from 'views/museumlib/PassportsCase5';
import PassportsCase7 from 'views/museumlib/PassportsCase7';
import PassportsCase8 from 'views/museumlib/PassportsCase8';
import PassportsCaseIX from 'views/museumlib/PassportsCaseIX';
import PassportsCaseXVI from 'views/museumlib/PassportsCaseXVI';
import PassportsCase17 from 'views/museumlib/PassportsCase17';
import PassportsCase18 from 'views/museumlib/PassportsCase18';
import PassportsCase19 from 'views/museumlib/PassportsCase19';
import PassportsCase20 from 'views/museumlib/PassportsCase20';
import PassportsCase21 from 'views/museumlib/PassportsCase21';
import NigeriaStationsOfCross from 'views/museumlib/NigeriaStationsOfCross';
import NigeriaStationsOfCrossOne from 'views/museumlib/NigeriaStationsOfCrossOne';
import Stations2ofCross from 'views/museumlib/Stations2ofCross';
import Stations3ofCross from 'views/museumlib/Stations3ofCross';
import Stations4ofCross from 'views/museumlib/Stations4ofCross';
import Stations5ofCross from 'views/museumlib/Stations5ofCross';
import Stations6ofCross from 'views/museumlib/Stations6ofCross';
import Stations7ofCross from 'views/museumlib/Stations7ofCross';
import Stations8ofCross from 'views/museumlib/Stations8ofCross';
import Stations9ofCross from 'views/museumlib/Stations9ofCross';
import Stations10ofCross from 'views/museumlib/Stations10ofCross';
import Stations11ofCross from 'views/museumlib/Stations11ofCross';
import Stations12ofCross from 'views/museumlib/Stations12ofCross';
import Stations13ofCross from 'views/museumlib/Stations13ofCross';
import Stations14ofCross from 'views/museumlib/Stations14ofCross';







import MuseumPage from 'views/Museum';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';




const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;



const donations = {
  
  home: lazy(() => import('views/donations/home/Home')),
  success: lazy(() => import('views/donations/success/Success')),
  cancel: lazy(() => import('views/donations/cancel/Cancel')),
  massCardDetail: lazy(() => import('views/donations/detail/MassCardDetail')),
  SMAGreatestNeed: lazy(() => import('views/donations/smagreatestneed/SMAGreatestNeed')),
  massCardDetailSP: lazy(() => import('views/donations/masscarddetailsp/MassCardDetailSP')),
  HealingCard: lazy(() => import('views/donations/healingcard/HealingCard')),
  Development: lazy(() => import('views/donations/development/Development')),
  Training: lazy(() => import('views/donations/training/Training')),
  Special: lazy(() => import('views/donations/special/Special')),
  SpecialCvv: lazy(() => import('views/donations/specialcvv/SpecialCvv')),
  cart: lazy(() => import('views/donations/cart/Cart')),
  checkout: lazy(() => import('views/donations/checkout/Checkout')),
  
};

const barService = {
  
  service: lazy(() => import('views/ButtomBar')),
    


  
};

const routesAndMenuItems = {
  
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`,
    },
    {
      path: `${appRoot}/home`,
      component: HomePage,
      label: 'menu.home',
      icon: 'church',
    },
    {
      path: `${appRoot}/about`,
      label: 'menu.about',
      icon: 'about',
      component: AboutPage,
    },
    {
      path: `${appRoot}/whoweare`,
 component: AboutPage2,
    },
    {
          path: `${appRoot}/vocation`,
          label: 'menu.vocation',
          icon: 'vocation',
          component: VocationPage,
        },
        {
          path: `${appRoot}/donations`,
          exact: true,
          redirect: true,
          to: `${appRoot}/donations/home`,
          label: 'menu.donations',
          icon: 'donate',
         component: donations.home,
          subs: [
            { path: '/home', component: donations.home },
            { path: '/success',  component: donations.success },
            { path: '/cancel',  component: donations.cancel },
            { path: '/detail',  component: donations.massCardDetail },
            { path: '/masscarddetailsp',  component: donations.massCardDetailSP },
            { path: '/healingcard',  component: donations.HealingCard },
            { path: '/smagreatestneed',  component: donations.SMAGreatestNeed },
            { path: '/development',  component: donations.Development },
            { path: '/training',  component: donations.Training },
            { path: '/special',  component: donations.Special },
            { path: '/specialcvv',  component: donations.SpecialCvv },
            { path: '/cart',  component: donations.cart }
         ],
        },
      
     {
           path: `${appRoot}/museum`,
           label: 'menu.museum',
           icon: 'museum',
           component: MuseumPage,
         },
         {
          path: `${appRoot}/museumChristianArt`,   
          component: MuseumChristianArt,
        },
        {
          path: `${appRoot}/museumPassportsMasquettes`,   
          component: MuseumPassportsMasquettes,
        },
        {
          path: `${appRoot}/passportsCaseIV`,   
          component: PassportsCaseIV,
        },
        {
          path: `${appRoot}/passportsCase6`,   
          component: PassportsCase6,
        },
        {
          path: `${appRoot}/passportsCase5`,   
          component: PassportsCase5,
        },
        {
          path: `${appRoot}/passportsCase7`,   
          component: PassportsCase7,
        },
        {
          path: `${appRoot}/passportsCase8`,   
          component: PassportsCase8,
        },
        {
          path: `${appRoot}/passportsCaseIX`,   
          component: PassportsCaseIX,
        },
        {
          path: `${appRoot}/passportsCaseXVI`,   
          component: PassportsCaseXVI,
        },
        {
          path: `${appRoot}/passportsCase17`,   
          component: PassportsCase17,
        },
        {
          path: `${appRoot}/passportsCase18`,   
          component: PassportsCase18,
        },
        {
          path: `${appRoot}/passportsCase19`,   
          component: PassportsCase19,
        },
        {
          path: `${appRoot}/passportsCase20`,   
          component: PassportsCase20,
        },
        {
          path: `${appRoot}/passportsCase21`,   
          component: PassportsCase21,
        },
        {
          path: `${appRoot}/nigeriaStationsOfCross`,   
          component: NigeriaStationsOfCross,
        },
        {
          path: `${appRoot}/nigeriaStationsOfCrossOne`,   
          component: NigeriaStationsOfCrossOne,
        },
        {
          path: `${appRoot}/Stations2ofCross`,   
          component: Stations2ofCross,
        },
        {
          path: `${appRoot}/Stations3ofCross`,   
          component: Stations3ofCross,
        },
        {
          path: `${appRoot}/Stations4ofCross`,   
          component: Stations4ofCross,
        },
        {
          path: `${appRoot}/Stations5ofCross`,   
          component: Stations5ofCross,
        },
        {
          path: `${appRoot}/Stations6ofCross`,   
          component: Stations6ofCross,
        },
        {
          path: `${appRoot}/Stations7ofCross`,   
          component: Stations7ofCross,
        },
        {
          path: `${appRoot}/Stations8ofCross`,   
          component: Stations8ofCross,
        },
        {
          path: `${appRoot}/Stations9ofCross`,   
          component: Stations9ofCross,
        },
        {
          path: `${appRoot}/Stations10ofCross`,   
          component: Stations10ofCross,
        },
        {
          path: `${appRoot}/Stations11ofCross`,   
          component: Stations11ofCross,
        },
        {
          path: `${appRoot}/Stations12ofCross`,   
          component: Stations12ofCross,
        },
        {
          path: `${appRoot}/Stations13ofCross`,   
          component: Stations13ofCross,
        },
        {
          path: `${appRoot}/Stations14ofCross`, 
          component: Stations14ofCross,
        },
        {
          path: `${appRoot}/IvoryCoast`,    
          component: IvoryCoast,
        },
        {
          path: `${appRoot}/BurkinaFaso`, 
          component: BurkinaFaso,
        },
        {
          path: `${appRoot}/TheInlandNigerDeltaI`,       
          component: TheInlandNigerDeltaI,
        },
        {
          path: `${appRoot}/JewelryII`,                           
          component: JewelryII,               
        },
        {
          path: `${appRoot}/AnimalSpiritsVIII`,                     
          component: AnimalSpiritsVIII,
        },
        {
          path: `${appRoot}/BatebaMorphologyVII`,                        
          component: BatebaMorphologyVII,
        },
        {
          path: `${appRoot}/PlankMasksV`,                           
          component: PlankMasksV,
        },
        {
          path: `${appRoot}/TheLobiVI`,                           
          component:  TheLobiVI,
        },
        {
          path: `${appRoot}/ColorandPatternIV`,                           
          component:  ColorandPatternIV,
        },
        {
          path: `${appRoot}/WaluandKomoMasksXV`,                           
          component: WaluandKomoMasksXV,
        },
        {
          path: `${appRoot}/TheDamaandtheKanagaMaskXIV`,                           
          component: TheDamaandtheKanagaMaskXIV,
        },
        {
          path: `${appRoot}/TheGurunsiandtheAntelopeMasksXIX`,     
          component: TheGurunsiandtheAntelopeMasksXIX,
        },
        {
          path: `${appRoot}/RealismandAbstractionXVIII`,                           
          component: RealismandAbstractionXVIII,
        },
        {
          path: `${appRoot}/SharedIconographyandtheGroundHornbillXVII`,                           
          component: SharedIconographyandtheGroundHornbillXVII,
        },
        {
          path: `${appRoot}/ChairsandStoolsIII`,                           
          component: ChairsandStoolsIII,
        },
        {
          path: `${appRoot}/MasksWithCrestsXX`,                           
          component: MasksWithCrestsXX,
        },
        {
          path: `${appRoot}/CarvingandWearingaMaskXXI`,                           
          component: CarvingandWearingaMaskXXI,
        },
        {
          path: `${appRoot}/FormandFunctionXXIIXXIII`,                           
          component: FormandFunctionXXIIXXIII,
        },
        {
          path: `${appRoot}/DecorationXXIV`,                           
          component: DecorationXXIV,
        },
        {
          path: `${appRoot}/BushcowMasksXVI`,                           
          component: BushcowMasksXVI,
        },
        {
          path: `${appRoot}/ChairsandStoolsIX `,                           
          component: ChairsandStoolsIX,
        },
        {
          path: `${appRoot}/MossiFigureSculptureX`,                           
          component: MossiFigureSculptureX,
        },
        {
          path: `${appRoot}/NakomseandNyonyoseXI`,                           
          component: NakomseandNyonyoseXI,
        },
        {
          path: `${appRoot}/NyonyosiStoneSculptureXII`,                           
          component: NyonyosiStoneSculptureXII,
        },
        {
          path: `${appRoot}/BuraSudanandNigerXIII`,                          
          component: BuraSudanandNigerXIII,
        },
        {
          path: `${appRoot}/MaliToday`,                          
          component: MaliToday,
        },
        
        {
          path: `${appRoot}/INRI`,                    
          component: INRI,
        },
        {
          path: `${appRoot}/PaxRwanda`,                         
          component:  PaxRwanda,
        },
        {
          path: `${appRoot}/Liberia`,                         
          component:  Liberia,
        },
        {
          path: `${appRoot}/SymbolismInGhana`,                         
          component:  SymbolismInGhana,
        },
        {
          path: `${appRoot}/Mali`,                         
          component: Mali,
        },
        {
          path: `${appRoot}/MaskBeteIvoryCoastCA`,                        
          component: MaskBeteIvoryCoastCA,
        },
        {
          path: `${appRoot}/DoorNupeNigeriaCA`,                         
          component: DoorNupeNigeriaCA,
        },
        {
          path: `${appRoot}/CommemorativePortraitofSalifKeita`,                         
          component: CommemorativePortraitofSalifKeita,
        },
        {
          path: `${appRoot}/MeaningofanAfricanMask`,                         
          component: MeaningofanAfricanMask,
        },
        {
          path: `${appRoot}/TheTallMask`,                         
          component: TheTallMask,
        },
        {
          path: `${appRoot}/GuidetoCollections`,                         
          component: GuidetoCollections,
        },
        {
          path: `${appRoot}/AfricanSculpture`,                         
          component: AfricanSculpture,
        },
        {
          path: `${appRoot}/AfricanArtandEuropeanArtists`,                         
          component: AfricanArtandEuropeanArtists,
        },
        {
          path: `${appRoot}/ACulturalHeritagethroughArts`,                         
          component: ACulturalHeritagethroughArts,
        },
        {
          path: `${appRoot}/BeautyandtheBeasts`,                         
          component: BeautyandtheBeasts,
        },
                {
          path: `${appRoot}/IbejiTwinChildren`,                         
          component: IbejiTwinChildren,
        },
        {
          path: `${appRoot}/GeledeMasks`,                         
          component: GeledeMasks,
        },
        {
          path: `${appRoot}/TheLegendofChiWara`,                         
          component: TheLegendofChiWara,
        },
        {
          path: `${appRoot}/AkuaBaTheFertilityDolls`,                         
          component: AkuaBaTheFertilityDolls,
        },
        {
          path: `${appRoot}/BonduMask`,                         
          component: BonduMask,
        },
        {
          path: `${appRoot}/BeadedArtistry`,                         
          component: BeadedArtistry,
        },
        {
          path: `${appRoot}/TheIvoirianBeads`,                         
          component: TheIvoirianBeads,
        },
        {
          path: `${appRoot}/TheBlackStoolSymbolofPower`,                         
          component: TheBlackStoolSymbolofPower,
        },

         {
          path: `${appRoot}/TheArtistryofTraditionalAfricanSculpture`,                         
          component: TheArtistryofTraditionalAfricanSculpture,
        },
         {
          path: `${appRoot}/TheLegacyofAfricanArts`,                         
          component: TheLegacyofAfricanArts,
        },
         {
          path: `${appRoot}/TheAfricanArt`,                         
          component: TheAfricanArt,
        },
         {
          path: `${appRoot}/TheSMAFathersandtheMuseumofAfricanArt`,                         
          component: TheSMAFathersandtheMuseumofAfricanArt,
        },
        
         {
           path: `${appRoot}/buttombar`,   
           component: ButtomBar,
         },
         {
          path: `${appRoot}/footerbarholiday`,   
          component: FooterBarHoliday,
        },
        {
          path: `${appRoot}/footerbarcattle`,   
          component: FooterBarCattle,
        },
        {
          path: `${appRoot}/footerbarmaccalli`,   
          component: FooterBarMaccalli,
        },

  ],
  sidebarItems: [],
};
export default routesAndMenuItems;

import React from 'react';
import { Row, Col, Card, Button, Tab } from 'react-bootstrap';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const MuseumSchoolsProgram = () => {
  return (
    <Tab.Pane eventKey="SchoolsProgram">
      {/* Images Large Start */}
      <section className="scroll-section" id="imagesLarge">
        <h2 className="cta-2">Schools Program</h2>
        {/* Card for Masks and Figure Sculptures */}
        <Card className="mb-4">
          <Row className="g-0 align-items-center">
            <Col xs="12" md="4">
              <img src="/img/banner/Lindgren_School.jpg" className="card-img h-100 scale" alt="Lindgren School" />
            </Col>
            <Col xs="12" md="8" className="ps-md-3 ps-0">
              <Card.Body>
              <h3 className="mb-4"><b>To Make an Appointment</b></h3>
                <p className="fs-5">
                  The SMA African Art Museum (African Art Museum of the SMA Fathers) www.smafathers.org is located at    </p><p className="fs-5"> 23 Bliss Avenue, Tenafly, New Jersey 07670.</p> 
                  <p className="fs-5">  Phone: (201) 567-0450, Email: museum@smafathers.org. </p>
                  <p className="fs-5">It is open seven days a week, from 10 A.M. to 5 P.M.,
                  and is easily accessible from the Garden State Parkway, Route 80, and Route 4.
                </p>
                <p className="fs-5">
                  A visit to the SMA African Art Museum includes a tour of the current exhibition as well as a hands-on examination of African masks, figure sculptures, costumes, jewelry, musical instruments, weapons, currency, and much more from our permanent collections.
                  The experience can be custom-designed for you and your students. It can take from 1½ to 2½ hours, depending on age level, and may include preparation and follow-up.
                </p>
                <p className="fs-5">
                Call the office at 201-567-0450 to make an appointment. The museum does not charge a fee, Donations are welcome!

             </p>

        
              </Card.Body>
            </Col>
            <Card className="mb-2">
              <Card.Body className="d-flex justify-content-center">
                <a href="/documents/SMA_SchoolsBrochure.pdf" download>
                  <Button variant="primary" className="btn-icon btn-icon-end mb-1 text-large">
                    <span>Download Schools Program Brochure</span> <CsLineIcons icon="download" />
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Row>
        </Card>
        {/* Brochure Download Button */}

        {/* Card for SHRINE-FIGURE-ESU Image */}
        <Card className="cta-2 mb-4">
          <Row className="g-0 align-items-center">


            <Col xs="12" md="4">
              <img src="/img/banner/SHRINE-FIGURE-ESU.jpg" className="card-img h-100 scale" alt="SHRINE FIGURE ESU" />
            </Col>
            <Col xs="12" md="8" className="ps-md-3 ps-0">
              <Card.Body>
              <h3 className="mb-4">Masks and Figure Sculptures</h3>
                <p className="fs-5">
                  SHRINE FIGURE ESU, AREOGUN, YORUBA, NIGERIA, 1952
                  Collection SMA African Art Museum, Gift of Fr. Sean O’Mahoney, 1999
                  (Photo-Peter Cade)
                </p>
                <p className="fs-5">
                  A visit to the SMA African Art Museum includes a tour of the current exhibition as well as a hands-on examination of African masks, figure sculptures, costumes, jewelry, musical instruments, weapons, currency and a lot more from our permanent collections. The experience can be custom-designed for you and your students. It can take from 1 ½ to 2 ½ hours, depending on age level and may include preparation and follow up.
                  All school tours are with Robert J. Koenig, Director of the SMA African Art Museum. Mr. Koenig has taught for over forty years in public schools, universities, and museums. He has lectured widely on African art. The museum welcomes groups of all ages: pre-school, elementary, secondary, university, undergraduate and graduate, adult education and senior citizen. Children and adults with physical, emotional and intellectual disabilities are welcome.
                  Call Bob at (201) 894-8611 to make an appointment. The museum does not charge a fee; contributions are welcome.


                </p>
              </Card.Body>
            </Col>
          </Row>
        </Card>



        {/* Card for KENTE CLOTH ADANUDO */}
        <Card className="mb-4">
          <Row className="g-0 align-items-center">
            <Col xs="12" md="8" className="ps-md-3 ps-0">
              <Card.Body>
                <h3 className="mb-4">KENTE CLOTH ADANUDO</h3>
                <p className="fs-5">
                  KENTE CLOTH ADANUDO, EWE. GHANA
                  Collection SMA African Art Museum, Purchase from Eric D. Robertson African Arts, 2002
                  (Photo-Peter Cade)
                </p>
                <p className="fs-5">
                  The Asanti of Ghana are famous for their colorful and elaborate kente cloth. Their neighbors, the Ewe, produce even more intricate cloths. Kente cloth is woven in strips a few inches wide. The strips are then sewn together to make a wrapper for a man or a woman. Kente cloths are very expensive and are worn by their owners only on special occasions as symbols of rank and prestige. This adanudo is over one hundred years old. It’s original owner, probably a chief, did not purchase it in the marketplace; he commissioned it from a master weaver. The designs in the center of the panels are inserted in the body of the weaving by hand, using supplementary weft threads. There are over two hundred such designs on this breathtaking old wrapper. It is one of the treasures in the Tenafly collections.
                </p>
              </Card.Body>
            </Col>
            <Col xs="12" md="4">
              <img src="/img/banner/KENTE-CLOTH-ADANUDO.jpg" className="card-img h-100 scale" alt="Kente Cloth Adanudo" />
            </Col>
          </Row>
          </Card>
          {/* Card for More about KENTE CLOTH ADANUDO */}

          <Card className="cta-2 mb-4">
            <Row className="g-0 align-items-center">


              <Col xs="12" md="4">
                <img src="/img/banner/KENTE-CLOTH-ADANUDO2.jpg" className="card-img h-100 scale" alt="SHRINE FIGURE ESU" />
              </Col>
              <Col xs="12" md="8" className="ps-md-3 ps-0">
                <Card.Body>
                  <h3 className="mb-4">More about KENTE CLOTH ADANUDO</h3>
                  <p className="fs-5">                  This adanudo is over one hundred years old. The original owner, likely a chief, commissioned it from a master weaver. There are over two hundred unique designs in this piece.

                  </p>
                  <p className="fs-5">                  The SMA African Art Museum is open seven days a week, located at 23 Bliss Avenue, Tenafly, NJ, and welcomes visitors.

                  </p>

                </Card.Body>
              </Col>
            </Row>
          </Card>
       
      </section>
    </Tab.Pane>
  );
};

export default MuseumSchoolsProgram;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheTallMaskcontent = () => {
  return (
    <>

      <Card>
      
   
        <Row className="g-3 align-items-center">
           <Col xs="12" md="12" className="ps-md-3 ps-0">
              <Card.Body>
              <Col className="d-flex justify-content-center"> 
            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Af_Fest-2012-the-mask-with-stilts.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
          </Col>

            </Card.Body>
          </Col>
          
        </Row>

        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>

              <p className="fs-5">This picture was taken at the SMA African Festival on Saturday, September 23, 2012. A member of the African Ivorian dance company of Yahayaha Koumate was performing the mask kwuya gblen-gbe ‘the tall mask’, the mask with stilts. He obviously was not dancing in his village in Ivory Coast for his fellow initiates in the men’s secret kono society, and although there were quite a few Ivorians in the audience, there were also a lot of non-Africans. The dance, therefore, was not a profoundly spiritual religious ritual but was a performance for tourists. It was awesome and exciting, nonetheless, as close as you could come to a masked ritual without going to Africa.</p>
              <p className="fs-5">Let me try to describe the experience, on behalf of my fellow onlookers.</p>
              <p className="fs-5">A small man in a green cotton suit emerged from behind the shrubbery and ran as if pursued by something. Then a figure over ten feet high emerged, its face covered by a mask of black netting. On its head was a tall conical hat embellished with cowry shells and a plume of white ram’s hair. The giant wore a voluminous blue and white shirt, enormous earrings and a necklace of cowry shells. The figure had no hands! The ends of the sleeves were sewn shut but from the stumps the figure brandished tufts of white ram’s hair. The apparition wore a white skirt and garters, but most astonishingly, the lower legs, covered in blue and white cloth, had no feet, only stumps that tapered almost to points. It balanced on these stumps, then stamped the ends on the ground, and standing on one ‘foot’, whirled, pirouetted, suddenly kicked its right ‘leg’ above its head and fell to its knees! How it would ever get up again? To regain its balance on the stilts it bent backwards, supporting itself on its right hand, then arched its back and with a mighty effort, lurched to an upright position. Its companion, the man in the green suit, seemed in a state of agitation throughout this performance. He fled, running as fast as he could, then he jumped up and down in front of the mask, yelling at it, imploring it, scolding it, begging, demanding, and yet dreading its attention, then as in the photograph, smiling joyfully.</p>
              <p className="fs-5">The two dancers were a unity with each other and also with the drummers, the other dancers and the audience. The mask, we understand, was more than the covering of the face. It was the costume, the dancer, the drums, the audience, the village. The effect of the masquerade is an exchange of intense spiritual energy among all the participants.</p>
              <p className="fs-5">The Dance, Art and Ritual of Africa was published in Paris in 1978, with photographs by Michel Huet and text by Jean Laude and Jean Louis Paudrat. The book contains a number of color photographs of a Guinean mask with stilts. Huet’s fifty year old description of the mask in his photographs applies exactly to the mask that danced at the SMA Mission House at the SMA African Festival on September 23, 2012. Huet tells us that ‘the stilts are cut from the banana tree because it is light, flexible and strong. They are sometimes as much as two meters high. They are usually covered by long trousers. The feet of the dancer rest on two lateral supports and the uprights are tied to the shins to give greater freedom to the knees. The dancer’s face is hidden under a net of palm tree fiber, while long braids hang at mouth level on either side of the face. The upper half of the costume is made of a blue and white striped cloth shirt with the cuffs sewn together. Bells and a raffia skirt are attached to the belt. The conical hat is embellished with cowries, grains, leather and cloth rags. surmounted by a tuft of white ram’s hair. The sounds uttered by the mask are high-pitched and grating and it is sometimes accompanied by a chorus of young men.’ (Paudrat, op cit.)</p>
              <p className="fs-5">Our Tenafly mask was accompanied by a single ‘antagonist’, not a chorus. The man ran, jumped, fell down, rolled on the ground, waved his arms, shouted, screamed, sang, laughed and whispered to the mask. This man was not masked, and for this non-African suggested Everyman relating to the otherworldly, the world of spirit. His reactions to the mask helped reveal its disposition to the onlookers.</p>
              <p className="fs-5">‘The designation of the mask on stilts, kwela gblen-gbe, places it in the category of symbolic representations, nyomu-gle-gue. It originated in kono country, in the Triple Border Region in Guinea, but its use spread to the Toma in the west and the Ivorian and Liberian Dan in the east. It is said that the making and preparing of the mask can only take place on the outskirts of the village and that any display of it can only take place with the approval of the local religious authorities. In certain traditions it is said that the mask is present at boys’ initiation ceremonies.’ The gravity defying acrobatic feats of the dancer refer to the ancient wisdom of the group, which enables it to maintain a balance in political and social structure. It is the means by which the kono society proves to itself that it can escape from the gravitational forces of the earth. This search for equilibrium outside normal conditions, this breaking through the barriers, show that man has the power to thwart the traps that are constantly being set for him by ‘the witches’. (Paiudrat, op.cit., pp 26, 27, ill. 47-54.)</p>
              <p className="fs-5">A spirit is an entity that lacks a body. The term ‘spirit’ refers to a whole group of non corporeal entities, such as spirits of the dead, both good and bad, ancestral spirits, bush spirits, genii, voodoos and orisha. Spirits can penetrate inanimate objects, especially masks and figure sculptures, as well as other objects, called fetishes. Bamandier, Georges nd Jacques Mackuet. Dictionary of Black Civilization. New york, 1974.</p>
              <p className="fs-5">Animism – the belief that all life is produced by a spiritual force separate from matter – is the ancient religion of Africa. Further, it is the doctrine of the existence of soul as independent of matter, and still further, it is the belief that forms of life other than human beings, as well as natural phenomena and objects, such as rocks, trees, water, wind, are alive and also have souls. Animism is today a powerful belief system in many parts of Africa, side by side with Christianity, Islam, Judaism and other great religions.</p>
              <p className="fs-5">The Smithsonian Institution, Washington DC has in its Eliot Elisofon Photographic Archives a slide showing a Gble Gblin stilt-dancer mask, Man Region, Ivory Coast (Eliot Elisofon Photographs, 1942-1972. A summary of the description of the mask emphasizes its importance. ‘Among the non-carved masks, it is important to mention the great enlivener of village celebrations who is much loved in the Dan lands: the stilt-dancer mask or long mask called Ge Gblin (photograph by Eliot Elisofon, 1971. Image No. EEPA EECL 3739).</p>
              <p className="fs-5">rjk: 9/27/12</p>


            </Card.Body>
          </Col>
        </Row>
       
        



      </Card>
    </>
  );
};

export default TheTallMaskcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Stations4ofCrossContent = () => {
  return (
    <>

      <Card>
      <Row className="align-center">

          <Col md="12" className="align-center">
                 <img src="/img/museum/Station4_web1.jpg" className="img-fluid rounded-xl"  alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              
               <h4 className="mb-4">
             JOHN 19
               </h4>

              <p className="fs-5">
              Near the cross of Jesus stood His mother and His mother’s sister, Mary the wife of Clopas, and Mary of Magdala. Seeing His mother and the disciple He loved standing near her, Jesus said to His mother “Woman, this is your son”. Then to the disciple He said, “This is your mother”. And from that moment, the disciple made a place for her in his home.
</p>
    

            </Card.Body>
          </Col>
        </Row>
      

        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the Stations:</h4>


    <div className="mb-3">
    <NavLink to="/nigeriaStationsOfCrossOne" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station1_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations2ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station2_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations3ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station3_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations4ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station4_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations5ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station5_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations6ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station6_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations7ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station7_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations8ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station8_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations9ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station9_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations10ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station10_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations11ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station11_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations12ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station12_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations13ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station13_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations14ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station14_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>

</div>


  </Card.Body>
</Col>
 
</Row>

      </Card>
    </>
  );
};

export default Stations4ofCrossContent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheLegacyofAfricanArtscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The Legacy of African Arts
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5"></p>
            <h4 className="mb-4 fs-6 lead fw-bold">A Legacy of Beauty</h4>

            <p className="fs-5">The appreciation of beauty and self-adornment is a quality of African-Americans which has clear links to the African heritage. Today, the beauty of bright and colorful patterns, so evident in the traditional Kente cloths worn by the royalty of the Asante people in Ghana, are witnessed in the traditional worn by African-Americans. Clothing styles inspired by African styles are found throughout the United States and the Caribbean. In addition to dress, jewelry-earrings, bracelets and rings- and leather goods, such as shoes, handbags, and wallets, exemplify the legacy of beauty. Finally, the infinite variety of African coiffures has survived the passage from Africa to America. Beauty and adornment is a rich legacy from which African-Americans continue to draw to enhance their lives.</p>
            <p className="fs-5"></p>
            <h4 className="mb-4 fs-6 lead fw-bold">A Legacy of Creativity</h4>
            <p className="fs-5">A legacy of creativity throughout the African Diaspora is preserved through the blending of various rhythms into the euphonious sounds of music. The polyrhythms of thousands of years of African traditions can be experienced in listening to the West African griot reciting a praise song to his people over the various beats of the taking drum and other accompanying instruments. Some of the tradition of the polyrhythms of the drums can be experienced in the United States as the rapper recites a rhythm to young African-Americans and as the dub poet recites to the youth of Jamaica and the Caribbean. These musical forms-praise songs, rap, and dub poetry-with the musician speaking in measured beats superimposed over drums, maintain but one of the many creative traditions which have survived the middle passage from Africa to America.
            
            </p>

            <h4 className="mb-4 fs-6 lead fw-bold">A Legacy of Education</h4>

            <p className="fs-5">Informal learning involves passing knowledge down from the elder person to the younger person. Such a tradition of learning has taken an oral form in the African Diaspora.
            The oral tradition, an educational tool, can be witnessed in a variety of form in Africa. One such form is that which occurs in the village or local meeting where the elders address the group in the language of the people. Often, the current states of affairs are discussed as they affect the people. At such meetings the young learn how to solve certain kinds of problems. The local village meeting has been maintained in the African-American community in the religious institutions and other local social and communal institutions, such as fraternal organizations. In the meetings of such institutions, the oral tradition of addressing the group in the language of the people, often vernacular Black-English, is alive and well. The leaders and elders often discuss the current problems of African-American people as well as explore potential solutions to those problems. This is often a tremendous learning experience for the youth. This African tradition has also survived the tumult and stress of the passage to America.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">A Legacy of Faith</h4>

            <p className="fs-5">“Faith is the substance of things hoped for” is a lyric song by many African-Americans in religious institutions. Faith in the Creator and the creation is a tradition that the first Africans, who were forced to leave their native land in the fourteenth century, brought with them to the various places throughout the world were they were taken. This faith, nurtured by each succeeding generation in the African Diaspora, has inspired all the people of the Diaspora to struggle for their human rights from the first days of slavery until today. This faith directed many ex-slaves in the United States back to Al Islam, which many Africans practiced in West Africa prior to slavery. This faith is manifest in other African-Americans who practice in a variety of religious expressions. This legacy of faith is one of the strongest elements in the cultural heritage of Africa.</p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheLegacyofAfricanArtscontent;

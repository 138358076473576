
import React, { useEffect, useState } from 'react';
import { Row, Col, Card,Button, Nav, Tab ,Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import HtmlHead from 'components/html-head/HtmlHead';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import CarouselAutoplayMuseum from '../CarouselAutoplayMuseum';
import { useParams,useLocation } from 'react-router-dom';
import Stations7ofCrossContent from './Stations7ofCrossContent';
import MuseumNav from '../MuseumNav';

const  Stations7ofCross = () => {         
  const title = 'Welcome to S.M.A.';                      
  const description = 'Under Construction ';

  const breadcrumbs = [{ to: '', text: 'Museum' }];
  const [activeTab, setActiveTab] = useState("Exhibitions");
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    const activeTabParam = params.get('activeTab');
    if (activeTabParam) {
      setActiveTab(activeTabParam);
      console.log("log activeTabParam ",activeTabParam);
    }
  

  }, [location.search]); 
  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });

  return (
    <>
      <HtmlHead title={title} description={description} />

      <Row>
        <Col>
 
             {/* Carousel Start */}
             <section className="scroll-section" id="carousel">
          <Row >
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplayMuseum />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* main tab content start */}
          <Tab.Container activeKey={activeTab} defaultActiveKey="Exhibitions"> 
          <Nav variant="tabs" className="nav-tabs-title nav-tabs-line-title" activeKey={activeTab} >
          <MuseumNav/>
          </Nav>
   

{/* Images Large Start */}
<Tab.Content>
<Tab.Pane eventKey="Exhibitions">
<section className="scroll-section" id="imagesLarge">
<br></br>
    <h2 className="card-title mb-4">Exhibitions {' -> '} Station VII: Jesus falls the second time </h2>

<div className="mb-n2">

<Stations7ofCrossContent />
            </div> 
            </section>
            </Tab.Pane>
            </Tab.Content>
         </Tab.Container>
              {/* Images Large End */}



          {/* main tab content end */}
        </Col>
      </Row>

    </>
  );
};

export default Stations7ofCross;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase18Content = () => {
  return (
    <>

      <Card>
        <Row className="g-0 align-items-center">


          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Passports – Case XVIII</h4>



            </Card.Body>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>
              <div className="me-1 mb-1 d-inline-block">
                <img src="/img/museum/Passports_Case18_1.jpg" className="img-fluid rounded-xl" alt="card image" />
              </div>
            </Card.Body>
          </Col>
          
          <Col xs="12" md="8" className="ps-md-3 ps-0">
          <Card.Body>
          <h4 className="mb-4 fs-6 lead fw-bold">Lavalle</h4>
            <p className="fs-5">This is a superb family mask of the Lavalle people, a little-known tribe of Angola. Its compelling flatness, in addition to its size, might separate it from the category of wearable masks. The ears, which would be on the sides of the head, are brought forward so that all the features are drawn to the frontal plane.
            </p>
          </Card.Body>
           </Col>
          </Row>
      <Row className="g-0 align-items-center">

        <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>

            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Passports_Case3-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
            </div>

          </Card.Body>
        </Col>
        <Col xs="12" md="8" className="ps-md-3 ps-0">
          <Card.Body>
            <h4 className="mb-4 fs-6 lead fw-bold">Chokwe</h4>

            <p className="fs-5">The Chokwe are famous for two types of mask: the chihongo, a male spirit symbolizing power and wealth, and the mwana pwo, a female spirit or ‘young woman’ symbolizing fertility. The male dancers who wore the chihongo went from village to village collecting rewards for their performances, which were believed to bring blessings. The carving of a mwana pwo is a symbolic marriage. The dancer who orders the mask gives the blacksmith a copper ring as a bride price. After his death, the mask is buried with him. the cross-shaped scarification on the forehead, called cingelyengele, reflects the influence of iron crosses disseminated in the Congo by Portuguse traders and merchants.Chokwe masks are distinguished by their narrowed ‘coffee bean’ eyes and deep, round eye sockets. They usually have a cross-shaped scarification mark in the middle of the forehead (circumspectly). The rare hanging mask would have been worn on the forehead, supported by the fiber cap.</p>

          </Card.Body>
        </Col>
      </Row>
      <Row className="g-0 align-items-center">

        <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>

            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Passports_Case3-4.jpg" className="img-fluid rounded-xl" alt="thumb" />
            </div>

          </Card.Body>
        </Col>
        <Col xs="12" md="8" className="ps-md-3 ps-0">
          <Card.Body>
            <h4 className="mb-4 fs-6 lead fw-bold">D’Jimini</h4>
            <p className="fs-5">
              This striking mask is one of my favorites partly because of the color. It is among the most abstract forms in my collection. The rectangles above the facial features can perhaps be read as a second pair of eyes.
            </p>

          </Card.Body>
        </Col>
      </Row>




      <Row className="g-0 align-items-center">

        <Col xs="12" md="4" className="ps-md-3 ps-0">
          <Card.Body>

            <div className="me-1 mb-1 d-inline-block">
              <img src="/img/museum/Passports_Case3-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
            </div>

          </Card.Body>
        </Col>
      </Row>
      
      <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the exhibition case by case</h4>


    <div className="mb-3">
    <NavLink to="/passportsCaseIV" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase6" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase5" >     
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase7" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase8" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseIX" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCaseXVI" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
     <NavLink to="/passportsCase17" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
      <NavLink to="/passportsCase18" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
       <NavLink to="/passportsCase19" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
        <NavLink to="/passportsCase20" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>

        <NavLink to="/passportsCase21" > 
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>
</NavLink>
</div>


  </Card.Body>
</Col>
 
</Row>

    </Card >
    </>
  );
};

export default PassportsCase18Content;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const CommemorativePortraitofSalifKeitacontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
            

        <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
                   <h4 className="mb-4 fs-3">Commemorative Portrait of Salif Keita</h4>

             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/IMG_1069.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
   
 
          
        </Row>

              
              <Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
             
            <h4 className="mb-4 fs-6 lead fw-bold">Lent by Dr Eugene and Mrs Harriet Becker
            Wood, Pigment. Height 10’ 10”</h4>

            
              <p className="fs-5">This heroic figure represents the great Malian soccer player Salif Keita, who played the game in the 1960s and 70s. It is carved from one piece of wood, except for the ball. The sculpture was collected by William Wright in New York City about ten years ago. The figure may have been carved in Mali, but the style suggests that it is Baule, from the Ivory Coast. The sculpture is remarkable – not only for its size but for the artist’s characterization of the athlete. The axis of the hips is twisted against that of the shoulders, one foot is forward, the left hand is raised with the forefinger pointing upward. The figure is all potential, poised for quick and effective movement. The face is all concentration, focused on the game. This man is a winner! I submit that this work is great folk art. It is probable that the sculptor had no formal training. Despite the extraordinary action of the figure, he is working out of West African tradition. It is possible that he had some exposure to images of monumental European sculpture and/or Egyptian.
              </p>
             

           

            </Card.Body>
          </Col>

</Row>

        

      </Card>
    </>
  );
};

export default CommemorativePortraitofSalifKeitacontent;

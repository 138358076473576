import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import useCustomLayout from 'hooks/useCustomLayout';
import CarouselAutoplay from './CarouselAutoplay';
import DetailsCarousel from './DetailsCarousel';
import Scrollspy from 'components/scrollspy/Scrollspy';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import HomeVertical from './HomeVertical';
import Whoweare from './Whoweare';

const AboutPage = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'About Us';
  const breadcrumbs = [{ to: '', text: 'About Us' }];

 

  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });
return (
    <>

      <Row>
        <Col>
          {/* Carousel Start */}
          <section className="scroll-section" id="carousel">
          <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* Vertical Content Start */}
          <section className="scroll-section" id="verticalContent">
            <h2 className="cta-2 small-title">About Us</h2>
            <div className="cta-2 mb-5 ps-3">
              <HomeVertical />
            </div>
          </section>
          {/* Vertical Content End */}

     
        </Col>
      </Row>
    </>
  );

};

export default AboutPage;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BatebaMorphologyVIIcontent = () => {
  return (
    <Row className="align-items-center">
          <Col>
          <h4 className="mb-4">Case VII: Bateba Morphology</h4>
            <div className="me-1 mb-1 d-inline-block">
              <img src="\img\museum\BurkinaFaso-Case-VII.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
          </Col>
        
         <Col xs="12" md="12" className="ps-md-3 ps-0">
           <Card.Body>
             <p className="fs-6">Bateba range in size from a few inches to a few feet. Larger Bateba are placed upon village altars. Smaller figures, for personal use, are placed upon the household shrine, carried about in the clothing or even worn around the neck. Life is regulated by the Thila, embodied – made visual, by Bateba. The spiritual power of the figures protects their owners from sorcery and evil spells, and helps them gain strength, happiness and wealth.
             The positions of the Bateba are specific and meaningful t o the Lobi. ‘Bateba phuwe’ stand with their arms hanging down at their sides. ‘Dangerous bateba’ extend their arms or raise one arm as if in defense or supplication. Sad bateba hold their hands under their chins.</p>
           </Card.Body>
         </Col>
         </Row>
  );
};

export default BatebaMorphologyVIIcontent;

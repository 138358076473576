import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Stations13ofCrossContent = () => {
  return (
    <>

      <Card>
      <Row className="align-center">

          <Col md="12" className="align-center">
                 <img src="/img/museum/Station13_web1.jpg" className="img-fluid rounded-xl"  alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">
              MARK 22
               </h4>
               

              <p className="fs-5">
              It was now evening, and since it was Preparation Day (that is, the vigil of the sabbath), there came Joseph of Arimethea, a prominent member of the Council, who himself lived in the hope of seeing the kingdom of God, and he boldly went to Pilate and asked for the body of Jesus.
              Pilate, astonished that Jesus should have died so soon, summoned the centurion and asked if He was already dead. Having been assured of this by the centurion, he granted the corpse to Joseph, who bought a shroud, took Jesus down from the cross, wrapped Him in the shroud and laid Him in a tomb which had been hewn in the rock. He then rolled a stone against the entrance to the tomb. Mary of Magdala and Mary, the mother of Joset were watching and took note of where He was laid. </p>
    

            </Card.Body>
          </Col>
        </Row>
      
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the Stations:</h4>


    <div className="mb-3">
    <NavLink to="/nigeriaStationsOfCrossOne" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station1_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations2ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station2_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations3ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station3_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations4ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station4_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations5ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station5_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations6ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station6_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations7ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station7_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations8ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station8_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations9ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station9_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations10ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station10_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations11ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station11_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations12ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station12_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations13ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station13_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations14ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station14_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>

</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default Stations13ofCrossContent;

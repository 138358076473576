import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const CarvingandWearingaMaskXXIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XXI: Carving and Wearing a Mask</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XXI.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
     <h4 className="font-weight-bold">Carving a Mask</h4>
       <p className="fs-6">All Burkina Faso masks are carved from the wood of the silk-cotton tree, Ceiba pintandra. It is light and easy to carve. Most masks are monoxyllic – carved from one piece of wood. The carver makes no sketches. He has the image in his mind and blocks out the large masses with his adze without benefit of mechanical means or guidelines of any kind. He then uses smaller and smaller adzes and knives until he has achieved the level of detail and kind of surface appropriate to the spirit in the mask.</p>
       <p className="fs-6">The image of the spirit comes from the collective imagination. While the people appreciate a degree of creativity, tradition dictates the way a mask must look in order to perform its spiritual function.
       of the of the extended group to which the craver belongs.Most Burkinabe masks are polychrome, painted in red, white and black. The red is ground iron oxide. The black is a tarry substance, made from boiling the seed pods of the Acacia tree. The white is chalk or the excrement of sun lizards, dug out of burrows by young boys.
       The binder for the paint is gum Arabic, used widely throughout the world. The colors are symbolic. Black is age, health, wisdom, and well-being. White is youth, inexperience, death and illness. Red is danger and the spirit world.</p>
       <h4 className="font-weight-bold">Wearing a Mask</h4>
       <p className="fs-6">A mask is a prized possession. It is commissioned by a family, clan or village and handed down through generations, gaining spiritual power with use and age. It is worn by a young man who belongs to the social unit that owns the mask. That young man has been initiated into the men’s secret society, where he is first introduced to the village masks, then taught their meanings, then their dances. Finally, he is allowed to wear a mask. The young women have been initiated into their own secret society. They dance along with the men, though not with masks.</p>
       <p className="fs-6">Some masks weigh as much as fifty pounds. With experience, dancers develop specialized muscles in the upper body and can perform extraordinary feats of agility and strength.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default CarvingandWearingaMaskXXIcontent;

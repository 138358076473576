import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const  TheLobiVIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case VI: The Lobi</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-VI.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The Lobi people inhabit an area at the meeting point of three countries: Burkina Faso, Ghana and Ivory Coast. The Lobi migrated to Burkina Faso in successive waves from northwestern Ghana, from the 18th to the end of the 19th centuries. In the past they were hunters and gatherers, now they are farmers, struggling for existence in the dry avannah. They are still moving southward, in search of more fertile soil for their slash and burn agriculture. They also raise goats, sheep, cattle and a great variety of fowl. During the dry season, when they cannot work in the fields, men build and repair houses and granaries, carve wooden sculptures and make musical instruments, such as the balophon. Women make pottery and baskets and work the marketplace.</p>
       <h4 className="font-weight-bold">The Bateba</h4>
       <p className="fs-6">Like most West African peoples, The Lobi believe that the universe is composed of a creator god, a host of supernatural creatures, and living and dead human beings. The structure of the universe is pyramidal. God, Tangba, is at the pinnacle, human beings are at the base. Under Tangba are the Thila, spirits with human characteristics. Below the Thila, but above human beings, are the spirits of the bush, the Kontuossi.
       The Lobi relate to the supernatural world – the Thila and the Kontuossi – by means of the Bateba, statuettes of wood, clay or metal, made by village craftsmen. From the moment they are offered to a Thil or set upon an altar, they are thought to be living beings, able to protect humans and help them in many ways.</p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default TheLobiVIcontent;

import React from 'react';
import { Row, Col, Card, Button} from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import useCustomLayout from 'hooks/useCustomLayout';
import { NavLink } from 'react-router-dom';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import HomeBasic from './HomeBasic';
import HomeVideo from './HomeVideo';
import HomeCarousel from './HomeCarousel';
import HomeVertical from './HomeVertical';
import HomeImages from './HomeImages';
import HomeProduct from './HomeProduct';
import LightboxGallery from './LightboxGallery';
import CarouselAutoplay from './CarouselAutoplay';
import CsLineIcons from 'cs-line-icons/CsLineIcons';


const HomePage = () => {
  const title = 'Welcome to S.M.A.';
  const description = 'Home';
  const breadcrumbs = [{ to: '', text: 'Home' }];



  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });
return (
    <>
      <Row >
        <Col>
          {/* Carousel Start */}
          <section className="scroll-section" id="carousel">
          <Row  xxl="1">
              <Col xs="auto" sm="auto" className="mb-3">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

          {/* Images Large Start */}
  
          <section className="scroll-section" >
   
            <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3 g-2 m-1" xxl="3"> 
              <Col xs="auto" sm="auto" className="mb-2 align-items-left" > 
              <Card className="hover-img-scale-up ">
          <img src="/img/banner/home2.webp" className="card-img h-100 scale" alt="card image" />
          <div className="card-img-overlay d-flex flex-column justify-content-between  align-items-end bg-transparent cta-2">

<div className="cta-2 mb-5 text-black w-80"></div> 
   
                         <NavLink to="/about#" >
                      <Button variant="primary" className="btn-icon btn-icon-start stretched-link">
                        <CsLineIcons icon="chevron-right" /> <span >About SMA (USA) .</span>{' '}
                      </Button>
                      </NavLink>
             
          </div>
        </Card>
      
              </Col>
              <Col xs="auto" sm="auto"  className="mb-2 align-items-center">
              <Card className="hover-img-scale-up align-items-center">
          <img src="/img/banner/voction.webp" className="card-img h-100 scale" alt="card image" />
          <div className="card-img-overlay d-flex flex-column justify-content-between  align-items-end bg-transparent cta-2">

<div className="cta-2 mb-5 text-black w-80"></div> 
  
            <NavLink to="/vocation" >
                      <Button variant="primary" className="btn-icon btn-icon-start stretched-link">
                        <CsLineIcons icon="chevron-right" /> <span>Vocations</span>{' '}
                      </Button>
                      </NavLink>
          
          </div>
        </Card>
              
              </Col>
              <Col xs="auto" sm="auto" className="mb-2">
              <Card className="hover-img-scale-up align-items-center">
                     <img src="/img/banner/home3new.webp" className="card-img h-100 scale" alt="card image" />
                     <div className="card-img-overlay d-flex flex-column justify-content-between  align-items-end bg-transparent cta-2">

                     <div className="cta-2 mb-5 text-black w-80"></div> 
                    
                         <NavLink to="/donations/home" >
                      <Button variant="primary" className="btn-icon btn-icon-start stretched-link" >
                        <CsLineIcons icon="chevron-right" /> <span>Donations</span>{' '}
                      </Button>
                      </NavLink>
                  
                  </div>
                </Card>
              </Col>
            </Row>

          {/* Images Large End */}

    

          {/* Images Square Start */}

          <Row className="row-cols-3 row-cols-md-2 row-cols-xl-4 g-2 m-1" > 
              <Col  xs="auto" sm="auto"   className="mb-1"> 
                <Card className="hover-img-scale-up">
                    <img src="/img/banner/card_home.webp" className="rounded mx-auto mb-1 d-block sw-32 card-img scale" alt="card image" />
                      <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                      <div className="d-flex flex-column h-100 justify-content-between align-items-end cta-2 text-white">
                        <div className="cta-2 mb-5 text-black w-80"></div>
                 
                        <NavLink to="/donations/detail" >
                        <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link" >
                        <CsLineIcons icon="chevron-right"  /> <span>Prepetual Mass Card</span>
                      </Button>
                    </NavLink>
                      </div>
                    </div>
                  </Card>
              </Col>
              <Col xs="auto" sm="auto"   className="mb-1">
                <Card className="hover-img-scale-up">
                  <img src="/img/banner/support_us.webp" className="rounded mx-auto mb-1 d-block sw-32 card-img scale" alt="card image" />
                    <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                     <div className="d-flex flex-column h-100 justify-content-between align-items-end cta-2 text-white">
                      <div className="cta-2 mb-5 text-black w-80"></div>

                      <NavLink to="/donations/home" >
                        <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link">
                        <CsLineIcons icon="chevron-right" /> <span>Support Us</span>
                      </Button>
                    </NavLink>
             
                    </div>
                  </div>
                </Card>
              </Col>
             <Col xs="auto" sm="auto"  className="mb-1">
              <Card className=" hover-img-scale-up">
                  <img src="/img/banner/we-2.webp" className="rounded mx-auto mb-1 d-block sw-32 card-img scale" alt="card image" />
                    <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                     <div className="d-flex flex-column h-100 justify-content-between align-items-end cta-2 text-white">
                      <div className="cta-2 mb-5 text-black w-80"></div>
                      <NavLink to="/whoweare" >
                      <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link">
                        <CsLineIcons icon="chevron-right" /> <span>Who We Are</span>
                      </Button>
                      </NavLink>
                      
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs="auto" sm="auto"  className="mb-1">
              <Card className=" hover-img-scale-up">
                  <img src="/img/banner/muesum_home.webp" className="rounded mx-auto mb-1 d-block sw-32 card-img scale" alt="card image" />
                    <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                     <div className="d-flex flex-column h-100 justify-content-between align-items-end cta-2 text-white">
                      <div className="cta-2 mb-5 text-black w-80"></div>
                      <NavLink to="/Museum" >
                      <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link"  >
                        <CsLineIcons icon="chevron-right" /> <span>Museum</span>
                      </Button>
                      </NavLink>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
  
            </section>
          {/* Images Square End */}
   
        </Col>

      </Row>

    </>
  );
};

export default HomePage;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Service= () => {
  return (
    <>
      <Card>
        <Row className="g-0 align-items-top">
          <Col xs="12" md="4">

                  <img src="/img/banner/JImPhoto1.jpg" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-0 ps-0">
            <Card.Body>
            <p><h4><b> 50 Years of Service </b></h4></p>
                <p>
    
              The year is 1972 Richard Nixon is the president, the Cowboys Win Super Bowl VI, The Country is at the end of the Vietnam War, The Godfather movie is released, and Father James “Jim” Joseph McConnell is ordained as a Priest.</p>

             <span className='g-0 align-items-center'> <b>
             Commonly known as Father Jim.</b></span>

           <div><br></br></div> 
      
<p>
There is so much to say about Father McConnell but one thing you will
always hear is, he has a great story to tell. Although, Father Jim was
ordained 50 years ago his story begins 20 years prior. Born James “Jim”
Joseph McConnell on July 26, 1942, in Morristown Pennsylvania, one of
seven children born of two amazing parent who were married for over 60
years.
Father Jim McConnell’s journey into priesthood starts at the age of 23. At
that time Father Jim was working as a reporter. While working he would
enjoy traveling and walking throughout the city to meet different people.
One day Father Jim and a childhood friend of his were taking a daily walk
through the neighborhood and walked pass a local church.
It was then when Father Jim was introduced to that Priest, and that
introduction would change his life forever. This priest later became his tutor
and taught him the life of Vocations. After a few years of studying and
training in the seminary, James McConnell. Became Father James “Jim”
Joseph McConnell on January 29, 1972.
I sat and spoke with Father Jim about his experiences, his advice, and the
next 50 years of Ministry. Father Jim took the time and went back to the
beginning with me to recap the last 50 years. After Father Jim was ordained,
it wasn’t long before he was set on his calling to work abroad.There was a multitude of work to be done and Father Jim felt drawn to
Liberia, more than any other location. Mostly because Liberia’s National
Independence Day is his Birthday. When Father Jim arrived, it was peaceful
in Liberia however some Missionary Workers experience hardship such as
racism and classism during that time.
When he arrived in Logan Town Liberia, there were no churches, so he
began to hold mass in Homes, then eventually local schools.</p></Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" className="ps-md-3 ps-0">
                   <Card.Body><p> At one point a
Local found out he was hold mass in homes and schools and gifted him land
to build a church. Unfortunately, the man who befriended Father Jim was
none other than Richard Abrom Henries a former Speaker of Liberian House
of Representative, who was one of the 13 Liberian Leaders shot and killed
during the Liberian unrest by soldiers on a beach in 1980. After that Liberia
became extremely dangerous, and Father Jim later dedicated the land in the
late Speakers Honor.
This gave Father Jim a since of purpose, which made him feel as if he was
right where he needed to be. Following, that Father Jim became close with
the High Priest in the area who introduced him to the people of Liberia and
their way of life. They respected and loved him but often warned him of the
dangers of staying in Liberia. However, it would be another 2 years before
Father Jim would leave at the advice of his friends and leaving it all behind
for safety. Spending a total of 8 years in Liberia Father Jim recalls the unrest,
the hardships, and the loss of many of his Christian Brothers, not to mention
leaving behind brothers, worshipers, and friends. Father Jim received
refugee from Liberia and returned to The United States to work in Newark,
New Jersey. He still speaks to some of his friends from Liberia and maintains
his roots to this very day.
Once Father Jim arrived in Newark to Christ the King Parish, the need to
do God’s work was no different. Father Jim was still making more
connections than ever, assisting people to become better through God’s
works. Not known how long he would be there Father Jim was ready to help
rehabilitate lives. He has always been known to look after people and
started every confessional with “Did you do Something Good today” and
believes you should always treat people the same way you wanted to be
treated. After 27 years in Newark, 2 years in the Bahamas and 5 years in Jersey City. </p>



<p>
Father Jim has always sough out the good in people and still
connects with them and their relatives. Father Jim says he still picks up the
phone for anyone who calls, even when they call to say don’t think they can
make it.
We celebrate not just 50 years of service for Father Jim; we celebrate a
great human being. Who has given more than half his life to strangers
through Christ. Father Jim still sees a future in ministry and says with the
energy he wishes he could open a storefront area where he could preach
and set up lay missions, with Nurses, Journalist, and vocations for any
amount of time anyone could provide. Father Jim’s advice for people
interested in Vocations would be to “Experience life, Before stepping in into
vocations. But I’ll be here to help.” Father Jim always tells everyone no
matter where he is, “James with Leave but Jesus will Stay.”
</p><p>    <h4>
Congratulations On 50 Years Father James “Jim” Joseph McConnell, here’s
to 50 more!!</h4>
              </p>
                   </Card.Body>
                 </Col>
                
               </Row>

      </Card>
    </>
  );
};

export default Service;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BeautyandtheBeastscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Beauty and the Beasts: Kifwebe Masks of the Songye, Luba and Related People
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      
   <Row className="g-0 align-items-center">
        

        <Col xs="12" md="12" className="ps-md-3 ps-0">
          <Card.Body>
          <h4 className="mb-4 fs-6 lead fw-bold text-center">From The Collection of Stewart J. Warkow
          
          </h4>

          

         
          
          </Card.Body>
        </Col>
      </Row>
     

        
        <Row className="g-0 align-items-center">
        

          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>

            <h4 className="mb-4 fs-6 lead fw-bold text-end">By Robert J. Koenig, Director</h4>

            <p className="fs-5">An exhibition of this depth and scope is always the work of many people, each qualified in his or her own way to make a contribution to the success of the project. First, we owe deep appreciation to Mr. Stewart J. Warkow, whose lifelong passion for collecting African art has led him to acquire not only rare and beautiful individual works from many of the cultures of sub-Saharan Africa, but also large groupings of the same types of works, such as Makonde masks from Tanzania and Kifwebe masks of the Songye, Luba and related peoples of the Democratic Republic of the Congo. To Mr. Marc Leo Felix, uniquely qualified to organize and document this exhibition through years of experience in studying, collecting, authenticating, selling and appraising the art of the Congolese peoples, I acknowledge a great debt of gratitude. Marc’s long and extensive work in the field – he maintains a residence in the Congo as well as in Brussels and New York – has given him an intimate understanding of the religious, socio-political, and artistic structures and sculptural canons of the Songye, Luba and their sub-groups in the Congo. His insatiable curiosity leads him to follow every clue, however tenuous, to the identification of a mask or figure or to a relationship between objects and cultures which has never before been perceived by the world of African art scholarship. In 1999, Marc introduced me to Mr. Dick Beaulieux of Brussels, Belgium, highly regarded as a photographer of African art par excellence. In 2000, Dick spent ten days with us in Tenafly shooting the transparencies which produced the color illustrations in this catalogue. To our colleagues at Holmes and Meier Publishers, Inc., I extend a warm and sincere thank you for their hard work, patience and perseverance in turning a set of manuscripts and photographs into a catalogue which does justice to the exhibition.</p>

            <p className="fs-5">To my colleagues at The African Art Museum of The S.M.A. Fathers I express my admiration and respect for their loyalty, dedication and professional expertise: Audrey F. Koenig: Registrar and Collections Manager; Tom E. Shannon: Exhibitions Designer; Pam Smith: Manager and Conservator, Textiles and Costumes; Boniface Kiamue: Consultant on the Museum Web Site, and to our invaluable volunteers Nancy Brown and Joel Krauser. To the staff at The Mission, gratitude for their unfailing spirit of cooperation and to the Council of The American Province of The Society of African Missions: Fr. Tom Wright, Provincial Superior; Father Ted Hayden, Vice Provincial; Father Jim Gessler, Councillor and Father Brendan Darcy, Local Superior, respect and affection for their continuing interest, friendship and support. To The Board of Trustees and The Acquisitions Committee of The African Art Museum of The S.M.A. Fathers I extend grateful appreciation for the gift of their enlightened expertise, service and collegial spirit. It is my pleasure and privilege to acknowledge the generous and continuing support of The New Jersey State Council on the Arts through funds distributed by The Bergen County Department of Parks, Division of Cultural and Historic Affairs. We have come to depend on the courteous assistance of Ann Romano, Director; Carol Messer, Grants Coordinator and Mr. Wolfgang Albrecht and Mr. William ‘Pat’ Schuber of the Bergen County Department of Parks.</p>
           

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default BeautyandtheBeastscontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const ACulturalHeritagethroughArtscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">A Cultural Heritage through Arts

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">How do we look upon African art? The West has long viewed those carved objects taken from Africa as "curiosities" or reflection of a "primitive" mind. The phrase "primitive art" has implicit derogatory implications. It was only when European artists early in this century "discovered" the aesthetic qualities of African carvings that the objects were finally dignified as art. Many Westerners considered them all the more remarkable since the objects had somehow emerged from the "untutored and savage world". African art was finally being acknowledged, but not African Culture.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Art and its cultural significance</h4>

            <p className="fs-5">In the last thirty years African Studies has emerged as a discipline, and serious research into African culture has sought to explain more about the art and its cultural significance. Classification and functional identification have greatly enhanced our understanding. Field studies continue to provide extensive documentation, but often minimizing the aesthetic qualities of the African art.
            Our challenge is to appreciate African art and culture, to acknowledge both equally, without the prejudices and stereotypes that have been set into our minds through decades of distortion. To view African art, Westerners must go through an evolution of sorts, pealing away attitudes of superiority, ethnocentric snobbery, and theoretical biases. We must try to confront the objects in their fullness, as representation of culture, civilization, and the human experience.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">African art and its social context</h4>
            <p className="fs-5">A symposium organized by the National Museum of African Art at the Smithsonian Institution explored the studying of African art in a broader contextual sphere. It affirmed that the social context in which African art appeared is an integral element in its appreciation. African art speaks from a unified milieu in which there is no clear separation between the social and aesthetic, the religious and the familial. This unity is visual, conceptual, and contextual elements requires more than a perfunctory identification of a piece…
            The more that we learn through research, the better able to de-code the art, which is so often viewed out of context, presented according to Western standards of taste, stripped of costumes, and of the songs which praised its values.</p>

            <h4 className="mb-4 fs-6 lead fw-bold">African art and African culture</h4>

            <p className="fs-5">African art is a great visual book of African culture, and we have been illiterate… As we seek to understand the art, we discover a complex conceptual world of ancient, elaborate systems of thought and belief. This is the world African art invites us to enter, a rich cultural expression which lives on vitally today in Africa as well as in the Americas.</p>
            <p className="fs-5">Hence, these exhibitions attempts to identify particular cultural values conveyed through traditional African art and to suggest continuities for these values within the African-American experience.</p>

            <p className="fs-5">Charles Bordogna
            
            </p>

            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default ACulturalHeritagethroughArtscontent;

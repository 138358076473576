import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Whoweare = () => {
  return (
    <>
      <Card className="cta-2">
        <Row className="g-0 align-items-center">
          <Col xs="12" md="4">

                  <img src="/img/banner/we.webp" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
                <p>The History of the Society of African Missions began on December 8, 1856, the feast of the immaculate conception in Lyon France by 
                Bishop Melchior De Marion Bresillac.</p>
              <p>
              Prior to the founding of the Society of African Missions Bishop Melchior De Marion Bresillac returned to Rome 
              from his diocese in Coimbatore, India in 1854 where he voiced his concerns about what he had witnessed. 
              Without a resolve in site Bishop Melchior De Marion Bresillac offered his resignation and placed himself 
              t the disposal of the Vatican Congregation for the evangelization of People for the appointment to a mission 
              in Africa. Bishop Melchior De Marion Bresillac then led a small group of priests to the Basilica of Our Lady 
              of Fourviere in Lyon, where Cardinal Barnabo at the time invited Bishop Melchior De Marion Bresillac to 
              create a new missionary society. Which he called Societas Missionum ad Afros also 
              known as Society of African Mission is English.
              </p>

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                              <p>
                              Bishop Melchior De Marion Bresillac traveled for two years around France to gain momentum for his newly 
                              founded Society to find volunteers and collect funding. 
                              In October of 1858 Bishop Bresillac sent the first three missionaries to Sierra Leone, West African. 
                              Several Months later in May of 1859 Bishop Bresillac and two others joined them to continue mission 
                              work on site in West Africa.  While traveling during high times of illness within six 
                              weeks 5 of the missionary members had passed away from the Yellow Fever Epidemic one of which being the founder, Bishop Bresillac.
                               With the only remaining brother in West African sent back to Europe, two priest and two seminarians remaining in Lyon,
                                the Society looked as though it was beginning to dissipate.
                     </p>
                   </Card.Body>
                 </Col>
                      <Col xs="12" md="4">
                                          <img src="/img/banner/we-2.webp" className="card-img h-100 scale" alt="card image" />
                                  </Col>
               </Row>
   <Row className="g-0 align-items-center">
          <Col xs="12" md="4">

                  <img src="/img/banner/we-3.webp" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <p>
              However, Father Augustin Planque whom was only 33 years old at the time continued the torch started by Bishop Bresillac. Over the course of the next 50 years Father Augustin found mission vocations, trained candidates, found financial support, and organized the missions that were entrusted to girls and women, which he founded and named Our Lady of Apostles (OLA) a community of missionary nuns and sisters. </p>
              <p>
              After 166 Years of Societas Missionum ad Afros also known as Society of African Mission our mission work still continues worldwide, there are now over 1,000 members as priests, brothers, and lay men and women coming from our 15 provinces, 8 districts, and 5 delegations which include Asia, Africa, America, and all over Europe.  Our missionaries joyfully witness the Gospel of Jesus Christ throughout Africa, and to people of African descent wherever they are. </p>

            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Whoweare;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NigeriaStationsOfCrossContent = () => {
  return (
    <>

      <Card>
      <Row className="align-center">

          <Col md="12" className="align-center">
                 <img src="/img/museum/Station1_web2.jpg" className="img-fluid rounded-xl"  alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">
              BRUCE ONOBRAKPEYA, URHOBO, NIGERIA.
THE FOURTEEN STATIONS OF THE CROSS
               </h4>

              <p>
              This rare set of linocuts was printed in 1969 in several editions of about fifty by Bruce Onobrakpeya, an Urhobo man who has become Nigeria’s mast famous – and arguably best – artist.
               </p>
              <p>
              Onobrakpeya came of age in the 1960s, the period when Nigeria won independence from Great Britain. Trained in schools with western-style courses of study, Onobrakpeya had to face the problem of creating an artistic persona that was both contemporary and African. This dual identity accounts for the presence of Hebrew soldiers dressed in nineteenth century English uniforms against a background of African patterns and images.
              </p>
              <p>
              Our complete set of the fourteen stations of the cross was purchased in 1974 for the African Art Museum of the SMA Fathers by Fr. Kevin Carroll, a distinguished scholar of the art of the Yoruba of Nigeria. Fr. Carroll is the author of Yoruba Religious Carving (1967), and Architectures of Nigeria (1992). The Kevin Carroll collection of Yoruba sculpture, beadwork, and textiles, is one of the proudest possessions of the Society of African Missions. The Fr. Kevin Carroll archive is in the SMA mission house at Cork, Ireland.
               </p>

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="12" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">Explore the Stations:</h4>

               
                     <div className="mb-3">
                     <NavLink to="/nigeriaStationsOfCrossOne" >
         <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station1_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations2ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station2_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations3ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station3_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations4ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station4_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations5ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station5_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations6ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station6_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations7ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station7_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations8ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station8_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations9ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station9_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations10ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station10_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations11ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station11_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations12ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station12_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations13ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station13_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>
        <NavLink to="/stations14ofCross" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Station14_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
        </div>
        </NavLink>

      </div>
           

                   </Card.Body>
                 </Col>
                  
               </Row>



      </Card>
    </>
  );
};

export default NigeriaStationsOfCrossContent;

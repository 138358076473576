import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const DoorNupeNigeriaCAcontent = () => {
  return (
    <>

      <Card>
      
        
        <Row className="g-0 align-items-center">
            

        <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
                   <h4 className="mb-4 fs-3">Door, Nupe, Nigeria, CA 1945</h4>

             
        
    
                   </Card.Body>
                 </Col>
   
 
          
        </Row>
              

              
              <Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
             

            
              <p className="fs-5">Wood, wood screws, iron loops, width 33” x length 64”
              </p>
              <p className="fs-5">Acquired by Dr. Mount in Nigeria in the early 1970s
              </p>
              <p className="fs-5">GIFT OF DR. MARSHALL W. AND CAROLINE MOUNT, 2012
              </p>

           

            </Card.Body>
          </Col>

</Row>
<Row className="g-0 align-items-center">
              <Col xl="20" md="25" className="ps-md-3 ps-0">
            <Card.Body>
             

            
              <p className="fs-5">We welcome your comments. Do you have a Nupe door in your collection? If so, we would love to see it. Please email us a photograph with whatever information you have about the object. And what do you think of our door? Thank you for your interest in the SMA African Art Museum. Robert J. Koenig, Director
              museum@smafathers.org
              </p>
              <p className="fs-5">Constructed of four boards of blackened wood, covered with well-designed and beautifully executed images in low relief. The flat surfaces of these relief shapes are entirely covered with incised lines and patterns. The images include a serpent, crocodile, two mammals, a gun, ceremonial sword, and twelve rectangles of varied proportions and sizes. The surface of the door is a rich blackish-brown, mellowed by age and use. The verso of each of the four boards is beautifully adzed in large, shallow, cup-shaped depressions. The boards are held together by two horizontal wooden braces fastened to the boards with steel wood screws. The adzing and condition of these braces suggest that they were made by the same craftsman who carved the four boards. On the back of one of the boards are two rusted iron loops.
              </p>
              <p className="fs-5">The Nupe live along the banks of the Niger River in Nigeria. They are famous for their terra cotta vessels for the storage of palm wine and water. These are distinguished by a globular body and a graceful neck surmounted by a cup. Two Nupe storage containers and a Nupe bowl are in the collections of the SMA African Art Museum.
              </p>

             

           

            </Card.Body>
          </Col>

</Row>

        

      </Card>
    </>
  );
};

export default DoorNupeNigeriaCAcontent;

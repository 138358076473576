import React from 'react';
import { Row, Col, Card,ListGroup,ButtonGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import useCustomLayout from 'hooks/useCustomLayout';
import CarouselAutoplay from './CarouselAutoplay';
import DetailsCarousel from './DetailsCarousel';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';

const VocationPage = () => {
  const title = 'Vocations';
  const description = 'Under Construction ';

  const breadcrumbs = [{ to: '', text: 'Vocations' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
                  {/* Carousel Start */}
                  <section className="scroll-section" id="carousel">
          <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5">
               <CarouselAutoplay />
               </Col>
           </Row>
             </section>
          {/* Carousel End */}

              {/* header Start */}
              <section className="scroll-section" id="carousel">
              <Row xxl="1">
              <Col xs="auto" sm="auto" className="mb-5 ps-3">
   
            <h2 className="cta-2 ">Vocations</h2>
            <div className="cta-2 mb-5">
              <DetailsCarousel />
            </div>
      
          </Col>
           </Row>
           </section>
          {/* header End */}

        </Col>
      </Row>
    </>
  );
};

export default VocationPage;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Stations12ofCrossContent = () => {
  return (
    <>

      <Card>
      <Row className="align-center">

          <Col md="12" className="align-center">
                 <img src="/img/museum/Station12_web1.jpg" className="img-fluid rounded-xl"  alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
  <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">
            JOHN 19
               </h4>
               <p className="fs-5">
               They then took charge of Jesus, and carrying His own cross, He went out of the city to the place of the skull or, as it was called in Hebrew, Golgotha. They crucified Him there with two others, one on either side with Jesus in the middle. Pilate wrote out a notice and had it fixed to the cross; it ran ‘Jesus the Nazarene, King of the Jews.’
               </p>
               <h4 className="mb-4">
               JESUS IS TAKEN BEFORE PILATE 
               </h4>

              <p className="fs-5">
              They offered Him wine mixed with myrrh, but He refused it. Then they crucified Him and shared out His clothing, casting lots to decide what each should get. It was the third hour when they crucified Him.</p>
    

            </Card.Body>
          </Col>
        </Row>
      
        <Row className="g-0 align-items-center">

<Col xs="12" md="12" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4">Explore the Stations:</h4>


    <div className="mb-3">
    <NavLink to="/nigeriaStationsOfCrossOne" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station1_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations2ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station2_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations3ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station3_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations4ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station4_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations5ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station5_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations6ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station6_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations7ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station7_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations8ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station8_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations9ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station9_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations10ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station10_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations11ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station11_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations12ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station12_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations13ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station13_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>
<NavLink to="/stations14ofCross" >
<div className="sw-19 me-1 mb-1 d-inline-block">
<img src="/img/museum/Station14_thumb.jpg" className="img-fluid rounded-md" alt="thumb" />
</div>
</NavLink>

</div>


  </Card.Body>
</Col>
 
</Row>


      </Card>
    </>
  );
};

export default Stations12ofCrossContent;



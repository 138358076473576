import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NakomseandNyonyoseXIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XI: Nakomse and Nyonyose</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XI1.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">The Nakomse are the descendents of the Mossi who invaded the area around the basin of the White Volta River about five hundred years ago. They were skilled cavalry men and easily defeated the indigenous residents, who were collectively known as the Tengabisi, ‘children of the earth’. The Tengabisi may be divided into clans of blacksmiths and clans of farmers. The farmers are called Nyonyose, ‘the old ones’ without regard for the ethnic group to which they belong.</p>
       <p className="fs-6">All the masks in this case are Mossi, but the mask on the left has some of the iconography and style characteristic of the old traditional masks of the farmer clans, the Nyonyose. The two masks on the right are classic Mossi style plank masks, with warlike visors that resemble ancient Greek helmets.</p>
       <p className="fs-6">Christopher Roy. Mossi Chiefs’ Figures. African Arts XV, IV p. 52-59</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default NakomseandNyonyoseXIcontent;

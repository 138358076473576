import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const GeledeMaskscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Gelede Masks, To Protect Man from Witches
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">In the southwestern corner of Yorubaland, among the Ketu, Shaki, Ohero-ljo, Anago, Ifonyin, Annori and Egbado Yoruba, there is a program of sculpture, dance and dress called Efe and Gelede. The sculpture for this society, numerically one of the largest programs in the history of art, involving thousands upon thousands of masks in wood, is a landmark in the cultural history of Africa.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Mediating objects</h4>

            <p className="fs-5">These masks in motion are mediating objects: they honor and cool magically active people of the night, dangerous beings whose very name is spoken with care. The Yoruba word for witch is Aje and would appear to be a contraction of Iya je meaning mother eat. A witch’s malignancy may be turned upon a man for almost any reason because of some impoliteness, or because he accuses her of being a witch, or because he is ‘getting too high in the world’ or often for no reason, just because they are evil women.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">To protect man</h4>
            <p className="fs-5">The goal of Efe and Gelede is to protect man from witches by aesthetic means, involving magic, percussion, sculpture, dress and dance. There is a belief that a witch can transform herself into a bird or animal. The animal on the superstructure of this mask is a witch transformed into a monkey eating a man’s substance, his lifeblood.</p>

            <h4 className="mb-4 fs-6 lead fw-bold">Masks worn by men</h4>

            <p className="fs-5">Gelede masks are worn by men, but may portray a wide range of male and female characters. The masks are Formally danced in identical pairs and adorned with elaborate, colorful costumes composed of numerous layers of expensive imported cloth, as well as locally made cloth. The mask is worn tilted over the forehead, allowing the dancer to see out beneath the rim through an attached veil of cloth. Note the ram’s horns at the lower rim of the mask. The ram is a symbol of power as well as an object of sacrifice.
            
            </p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default GeledeMaskscontent;

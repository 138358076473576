import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const AfricanArtandEuropeanArtistscontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">African Art and European Artists
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">Maurice de Vlaminck is generally considered to be the first European to recognize the intrinsic value of African art. He tells of having noted in 1905, at a bistro in Argenteuil, two statues from Dahomey painted in red and yellow ochre and white and one from the Ivory Coast in black. Although he and Andre Derain did not see other than a barbarous fetichism in the objects exhibited at the Trocadero museum in Paris, these statues had a profound effect on him, enough so that he bought them and took them with him. Other purchases were added to these: a large white mask and two superb statues from the Ivory Coast, which a friend of his father’s gave him, confiding that his wife wanted to throw those horrors out.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Cubism</h4>

            <p className="fs-5">One day Matisse and Picasso happened to catch sight of the mask at Derain’s, and they were jolted by it. In fact, from that moment the hunt for African art began, Vlaminck wrote, adding that Picasso, the first to intuit what aspects could be extrapolated from the plastic concepts of the blacks of Africa and of the islands of Oceania, introduced these aspects little by little into his painting, an action that led to the creation of a movement thought to be revolutionary in art: Cubism.</p>
            <p className="fs-5">Vlaminck thus boasted that it was he who really started the craze for African art in Europe. However, although he saw only barbarous fetishism in the Argenteuil figures, didn’t he write, soon afterward, that the white mask was fascinating and disturbing, the same black art that had appeared to him in all its primitivism and in all of its grandeur.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Barbarous fetishes and grandeur</h4>

            <p className="fs-5">Between the perception of barbarous fetishes and this “grandeur” the time had been short. One could say, although without absolute proof, that Cubism, derived principally from Cezanne’s lessons, was actually the result of an external fortuitous accident. Vlaminck, in reality, attributes to Picasso the true discovery: that of the aid in perception that such works could give to European artists in search of new techniques.
            In Vlaminck, as later in Amedeo Modigliani, the traces of African art are found, but their influence is purely stylistic, not derived from a profound reflection on the means of representation and of construction those of the African sculptor. Even the German expressionists were not involved in penetrating the structure of these works. Reacting in a romantic way they were above all emotionally moved. One of the most noted historians of African art, Eckart von Sydow, defined the German emotional content as mystical, while, also according to him, the Cubists were better able to extract the architectonic quality of the African sculpture.</p>
            <h4 className="mb-4 fs-6 lead fw-bold">Harmony of relationships</h4>

            <p className="fs-5">Realists, the cubists’ scrupulous attention to form is characteristic of their creators, preoccupied with the all-over plan, a harmony of relationships. Those parts that are accessories may disappear, devoured by time or sacrificed to taste, modesty, or even to the barbarism of the European.
            The whole however is not diminished: the imposed harmony is saved. That’s how Salmon explains it, emphasizing the realistic and constructivist tendencies once true of the majority of African sculptors, and also alluding to the degradation to which many works were submitted by their European owners – such as lopping off the male sex organ when deemed too obvious. They even eliminated elements that seemed superfluous to them – necklaces or other accessories in various materials that had been added to the sculpted wood. As for Lipchitz, his work is a testimonial to the historical effect of African art: African art has been a great influence, obviously, on our own: their clear understanding of proportion, their own sense of design, their acute sense of reality has affected our perceptions, even opening up new paths for us to dare to explore.</p>
            <p className="fs-5">Michel Leiris e Jacqueline Delange, Africa Nera, Feltrinelli, 1967, 7 ss.</p>

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default AfricanArtandEuropeanArtistscontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PassportsCase21Content = () => {
  return (
    <>

      <Card>
      <Row className="g-0 align-items-center">
   
 
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4">Passports – Case XXI</h4>
      

     </Card.Body>
   </Col>
 </Row>
    
        <Row className="g-0 align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
        <Card.Body>
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case6-1.jpg" className="img-fluid rounded-xl"   alt="card image" /> 
          </div>
          </Card.Body>
          </Col>
          
 
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4 fs-6 lead fw-bold">Bamileke and Kom</h4>

              <p className="fs-5">
              Here we have a potpourri of West African peoples. The Bamileke and Kom passports are good imitations of their counterparts, even to the use of human hair on the Kom. The horned example overlaid with leather is unusual.              </p>
           

            </Card.Body>
          </Col>
        </Row>

      <Row className="align-items-center">
      
          </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="4" className="ps-md-3 ps-0">
                   <Card.Body>
             
        <div className="me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case6-2.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
    
                   </Card.Body>
                 </Col>
                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                    <h4 className="mb-4 fs-6 lead fw-bold"> Suku style</h4>
             
                   <p className="fs-5">These are contrasting examples of the Suku style, but both are simple and refined. The elongated mask has a very heavily encrusted patina.</p>
      
                   </Card.Body>
                 </Col>
              </Row>

              <Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6-3.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
  <h4 className="mb-4 fs-6 lead fw-bold"> Baule</h4>
  <p className="fs-5">
  Baule masks have been admired by westerners ever since they showed up in the ethnological museums and flea markets of Paris in the early twentieth century. The family mask shown here is a reduction of a Baule ‘portrait mask’.  </p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6-5.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4 fs-6 lead fw-bold">  Kore</h4>

  <p className="fs-5">
This masquette belonged to a member of the Kore Society, one of the most important men’s secret associations of the Bamana people. Notice the repeated triangular decoration covering the surface of the mask.
</p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6-6.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
<Col xs="12" md="8" className="ps-md-3 ps-0">
  <Card.Body>
    <h4 className="mb-4 fs-6 lead fw-bold">  Eket</h4>

  <p className="fs-5">
 I find the familiar lunar influence in this large Eket family mask to be extremely expressive and fascinating.</p>

  </Card.Body>
</Col>
</Row>
<Row className="g-0 align-items-center">

<Col xs="12" md="4" className="ps-md-3 ps-0">
  <Card.Body>

<div className="me-1 mb-1 d-inline-block">
<img src="/img/museum/Passports_Case6-4b.jpg" className="img-fluid rounded-xl" alt="thumb" />
</div>

  </Card.Body>
</Col>
</Row>

<Row className="g-0 align-items-center">

                 <Col xs="12" md="12" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">Explore the exhibition case by case</h4>

               
                     <div className="mb-3">
                     <NavLink to="/passportsCaseIV" >
         <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case4_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase6" >
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case6_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase5" >     
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase7" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case7_thumb1.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase8" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case8_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseIX" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case9_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCaseXVI" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case16_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                      <NavLink to="/passportsCase17" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case17_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                       <NavLink to="/passportsCase18" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case18_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                        <NavLink to="/passportsCase19" > 
        <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case19_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
                         <NavLink to="/passportsCase20" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case20_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>

                         <NavLink to="/passportsCase21" > 
       <div className="sw-19 me-1 mb-1 d-inline-block">
          <img src="/img/museum/Passports_Case21_thumb.jpg" className="img-fluid rounded-xl" alt="thumb" />
        </div>
        </NavLink>
      </div>
           

                   </Card.Body>
                 </Col>
                  
               </Row>


      </Card>
    </>
  );
};

export default PassportsCase21Content;

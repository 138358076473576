import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Maccalli= () => {
  return (
    <>
      <Card>
        <Row className="cta-2 g-0 align-items-top">
          <Col xs="12" md="4">

                  <img src="/img/banner/mac.jpeg" className="card-img h-75 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-0 ps-0">
            <Card.Body>
                <p><h4><b>Father Maccalli Released.</b></h4></p>
              <p>
              <h4>Thank you for your prayers!</h4>
</p>
After Nearly two years of being held captive in Niger by
Jihadist insurgents. Father Maccalli is happy to return
home and thanks you all for your prayers.
Adjusting to life after taking pilgrimage in Portugal,
Father Maccalli is calling on the parish once more as
faithful servants to pray for those living the drama of
kidnapping. Reflecting on those still living in captivity
Father Maccalli reminds us we need to continue to pray.
He says “Prayer is a valuable aid for people of faith.”
When he was freed Father Maccalli recalls that he saw
the river of prayer that accompanied his two years in
captivity.</Card.Body>
          </Col>
        </Row>
            
      </Card>
    </>
  );
};

export default Maccalli;

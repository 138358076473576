import React from 'react';
import { Row,Col,Card } from 'react-bootstrap';

const TheInlandNigerDeltaIcontent = () => {
  return (
    
    <Row className="align-items-center">
          <Col>
          <h4 className="mb-4">Case I: The Inland Niger Delta</h4>
            <div className="me-1 mb-1 d-inline-block">
              <img src="\img\museum\Case-I1.jpg" className="img-fluid rounded-xl" alt="card image" />
            </div>
          </Col>
        
         <Col xs="12" md="12" className="ps-md-3 ps-0">
           <Card.Body>
             
             <p className="fs-6">Karl-Ferdinand Schaedler points out in Earth and Ore,1997, that ‘The early West African kingdoms – Ghana, Mali, Songhai and Bambara – all enclosed one territory in their sphere of power which is referred to as the Inland Niger Delta.’ Like the Nile in Upper and Lower Egypt, the Rivers Niger and Bani flooded the lowlands of the delta for roughly half the year. During that period the ancient populations withdrew to the surrounding highlands. There, they left a vivid record of their civilization in the form of terracotta vessels and figurative sculptures. All of these objects can be roughly dated to the 11th/12th to the 16th centuries AD. The Inland Niger Delta cultures have been identified as Djenne, Bankoni, Segou – or Bambara, and Tenenkun.</p>
             <p className="fs-6">Karl-Ferdinand Schaedler points out in Earth and Ore,1997, that ‘The early West African kingdoms – Ghana, Mali, Songhai and Bambara – all enclosed one territory in their sphere of power which is referred to as the Inland Niger Delta.’ Like the Nile in Upper and Lower Egypt, the Rivers Niger and Bani flooded the lowlands of the delta for roughly half the year. During that period the ancient populations withdrew to the surrounding highlands. There, they left a vivid record of their civilization in the form of terracotta vessels and figurative sculptures. All of these objects can be roughly dated to the 11th/12th to the 16th centuries AD. The Inland Niger Delta cultures have been identified as Djenne, Bankoni, Segou – or Bambara, and Tenenkun.</p>
             <p className="fs-6">Schaedler, Karl-Ferdinand , Earth and Ore: 2500 years of African Art in terra-cotta and Metal,.Munich (Panterra Verlag), 1997.</p>
           </Card.Body>
         </Col>
         </Row>
  );
};

export default TheInlandNigerDeltaIcontent;

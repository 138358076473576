import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheSMAFathersandtheMuseumofAfricanArtcontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The SMA Fathers and the Museum of African Art

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">
    The Society of African Missions (SMA.) is an international organization of Catholic priests and lay missionaries, which has served the peoples of Africa since 1856, when it was founded by Bishop Marion de Brésillac in Lyon. 
    In an enlightened spirit rare in the mid-nineteenth century, when the arts of the ‘primitive’ peoples of Africa, the Pacific and pre-Columbian North and South America were relegated to curiosity cabinets or ethnological collections, Monsignor de Brésillac urged his missionary priests to respect the cultures of the peoples with whom they worked and to develop an appreciation of the unique character and beauty of African art.
</p>
<h4 className="mb-4 fs-6 lead fw-bold">Unique Character and Beauty of African Art</h4>

<p className="fs-5">
    As a result, some of the SMA Fathers acquired pieces, which found their way to Europe, where the society gathered them together in museums of African art at Cadier en Keer Netherlands and Lyon, France. Today, the combined provinces of SMA, with headquarters in Tenafly, U.S.A.; Lyon and Strasbourg, France; Cadier en Keer, Netherlands; Cork, Ireland and Zaborow, Poland, work in many countries in sub-Saharan Africa. The American Province focuses on Liberia, Ghana and Kenya. The SMA Generalate is in Rome; the General Superior is Fr. Kieran O’Reilly. The Society of African Missions is a bridge between cultures, a facilitator of communication and mutual understanding and assistance.
</p>
<h4 className="mb-4 fs-6 lead fw-bold">We Have Much to Learn from Africa</h4>

<p className="fs-5">
    One of the things we hope to demonstrate to the western world is that we have much to learn from Africa: religious tolerance, profound respect, loyalty and affection for the extended family and its ancestors; the highest regard for the equality of the sexes; brotherhood and sharing; hospitality to all, patience and fortitude in adversity and abiding faith in God.
</p>
<h4 className="mb-4 fs-6 lead fw-bold">The Museum and the SMA</h4>

<p className="fs-5">
    African Art Museum of SMA. Fathers in New Jersey was a part of Father Patrick O’Donoghue’s plans for a new Mission structure in the early 1960s. These were further developed by his successor as Provincial Superior, Father Kevin Scanlan, who served from 1968 to 1978. Father Scanlan appointed as first Director of the museum Father Edward Biggane, SMA., who hired William Siegmann as first Curator of Collections. The core of the Tenafly collections, about 250 works, was acquired between 1963 and 1978. With the exception of a few ethnographic pieces from Cadier en Keer, these early acquisitions came not from the European SMA museums, but from colonial collectors and dealers contacted by Father Hubert Jacoby, an affiliate of the museum at en, and Father van Trigt, its Director. Many Yoruba pieces were acquired by Father Scanlan, working with Father Kevin Carroll, well-known pioneer scholar and patron of the arts of the Yoruba, who commissioned many works for Tenafly from carvers, beadworkers and weavers associated with the SMA workshop at Ekiti, a small town in southeastern Nigeria.
</p>

<h4 className="mb-4 fs-6 lead fw-bold">Today Yoruba Holdings</h4>

<p className="fs-5">
    Today our Yoruba holdings include a set of four doors carved in high relief, a crucifixion and a Virgin Mary by Bandele of Osi Ilorin, a much-published crèche set by Joseph Imale with beadwork by James Adetoyi of Owo, a superb Hausa and a set of fourteen linoleum cuts showing the stations of the cross by Bruce A. Onobrakpeya. The most recent addition to the Ekiti collection is a late work by Areogun, Esu published in Father Carroll’s Yoruba Religious Carving, 1954, given in 2000 by Father Sean O’Mahoney, an associate of Father Carroll in the workshop at Ekiti.
</p>

<h4 className="mb-4 fs-6 lead fw-bold">The Expansion of the Museum</h4>

<p className="fs-5">
    In the past two decades the collections of the African Art Museum of SMA. were greatly expanded in scope and depth under the supervision of a succession of brilliant and resourceful Provincial Superiors, including Father Ted Hayden, Father Tom Conlon, Father S. John Murray and Father Douglas B. Gilbert. Their efforts were enhanced and facilitated by the active involvement of Leonard Kahan, L. Kahan African Arts, who encouraged his clients to donate works to the collections, and volunteer Curator, Charles Bordogna. In 1998, The African Art Museum was incorporated as a not-for-profit corporation with the American Province of The Society of African Missions as the parent organization. As in the European SMA museums, the Province owns the collections. At Tenafly, they are managed by the Director and staff, working with a Board of Trustees and an Acquisitions Committee composed of SMA Fathers and lay members drawn from New Jersey and the tri-state metropolitan community.
</p>

<h4 className="mb-4 fs-6 lead fw-bold">A Small Collection but Very High Quality</h4>

<p className="fs-5">
    Our collections are truly ‘managed’ through judicious accession and de-accession in accordance with a board-endorsed Long Range Plan. The goal is to keep them relatively small, but very high in quality. To this end, we employ the wisdom and good judgment of a number of highly qualified scholars and experts on African art, both professional and amateur. Only pieces rated A and B by a consensus of opinion are retained, exhibited and published; those rated C or D are not accepted or e-accessioned by public auction. Works from the collection are rotated in exhibitions curated by the Director and staff; three to six a year are mounted and published in illustrated checklists generated on the computer.
</p>

<h4 className="mb-4 fs-6 lead fw-bold">Education Program</h4>

<p className="fs-5">
    The museum has always had a strong education program and serves approximately 3000 schoolchildren, college students and adults annually through gallery talks and tours by appointment, both on and off-site. In addition, the museum presents African films, public lectures and gallery talks as well as concerts in association with The Composers Guild of New Jersey. Through the SMA website, – www.smafathers.org – the museum serves a larger community whose size is impossible to estimate. 
    At the heart of the Mission of SMA is the willingness to serve those in dire need, those who are utterly destitute, those whom no one else is willing to serve. Reflecting this Mission of SMA, the African Art Museum, while open and welcoming to rich and poor alike, makes a special effort to reach the physically, intellectually and economically disadvantaged through intensive and targeted initiatives with such organizations as the Dwight Morrow School of Alternative Learning Styles, Englewood, New Jersey, and special programs for specialized Services such as The St. Joseph’s School for the Blind, Jersey City, New Jersey. At the beginning of the new millennium, with the Grace of God, we look forward to increasingly rich and mutually rewarding relationships with all our friends, associates and constituencies.
</p>

<p className="fs-5">Fr. Thomas Wright, Provincial</p>



           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheSMAFathersandtheMuseumofAfricanArtcontent;

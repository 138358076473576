import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TheArtistryofTraditionalAfricanSculpturecontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">The Artistry of Traditional African Sculpture

</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <h4 className="mb-4 fs-6 lead fw-bold text-end">By Charles Bordogna</h4>

            <p className="fs-5">The artistic qualities in these exhibitions affirm that shared aesthetic conscience exists across cultural barriers, expressed in distinctive and precise form, and that it can be jointly perceived by the creator as well as the perceptive observer. This sculpture is a metaphor for the African cosmology that dramatically harmonizes humanity and the environment, integrating individuals and art into larger structures of family, clan, communal groups, and tribal identity.</p>

            <p className="fs-5">The most notable characteristic of the objects presented in “The Artistry of Afri­can Traditional Sculpture” is their aesthetic quality. The pieces in this exhibition have been selected on the basis of their artistic merit. However, this is not to suggest as inconsequential the objects’ authenticity and ethnography. Nor is it to understate the educational value of the collection in furthering the public’s appreciation of Afri­can culture. To have been selected for this exhibition, an object must possess aesthetic presence and have unquestionable cultural relevance.</p>
            <p className="fs-5">The artistic qualities in this exhibition affirm that shared aesthetic conscience exi­sts across cultural barriers, expressed in distinctive and precise form, and that it can be jointly perceived by the creator as well as the perceptive observer. Art is a truly multicultural experience. If we are not going to attribute the most powerful and pleasing aesthetic creations of to chance, we must acknowledge that the arti­stic impulse existed in and was realized by thousands of nameless African artists whose works survive.</p>
            <p className="fs-5">The enjoyment of African art touches a range of human emotions, some subtle, some overt. Anthropological data may help us to understand an object and may enhance our appreciation, but ultimately the aesthe­tic response rests upon the objects presen­ce. To truly experience the object as art means to stand before it and perceive it clo­sely, being receptive to the emotions it generates. There is a joy in hearing African sculpture speak to us, to paraphrase Ladi­slav Segy; however, one must do more than be passive. The active aesthetic encounter is in itself intrinsically rewarding. We indulge our senses, challenge our perceptiveness, and go beyond the mere superficial. We savor the object’s subtle details, looking again and again at the form and surface, relishing the sensations provoked.</p>


            <p className="fs-5">The traditional sculpture of in its finest representations can be aesthetically examined in terms of form, composition, and presence. The objects in this exhibition possess these characteristics, often in boldly innovative design. This sculpture is a metaphor for the African cosmology that dramatically harmonizes humanity and the environment, integrating individuals and art into larger structures of family, clan, com­munal groups, and tribal identity. African aesthetics pattern African life.</p>

            <p className="fs-5">Is African traditional art really art or are these objects only ethnographic curiosities? Can African art held up to qualitative aesthetic analysis? Ten aesthetic principles can be found in traditional African art:</p>

            <ul>
  <li><i className="bi bi-dot"></i> <strong>Inventiveness</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Stylized realism</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Intricate details</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Dynamic form</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Symbolic imagery</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Geometric motifs</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Harmonious elements</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Subtle proportions</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Assemblages of natural elements with dramatic resonance</strong></li>
  <li><i className="bi bi-dot"></i> <strong>Affective surfaces</strong></li>
</ul>

<p className="fs-5">These ten principles evoke viewer response. In those pieces that have realized the highest level of the ten principles, the result is an image that transcends the common place.</p>

<h4 className="mb-4 fs-6 lead fw-bold">Inventiveness</h4>
<p className="fs-5">he first principle is inventiveness, the striking originality that goes beyond simple naturalism.

Consider the Yoruba iron figure. Here the blacksmith artist has transformed iron, a natural element of the earth, into an image that suggests the very powers of life. The arms and legs, elongated and enlarged, seem to swirl with energy around the static, yet potent looking torso.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Stylized Realism</h4>
<p className="fs-5">Many pieces of traditional African art have been described as having a stylized realism.

The human form is present, but the image seems idealized or exaggerated. As we look at the Bamana female statue, we see the conical breasts and the abbreviated legs ending with rounded hips. We clearly see human form, but the emphasis or de-emphasis of certain bodily parts suggests a new focus, a fresh way of representation. This quality is also apparent in seeing the human form, a release from the restrictions of strictly realistic the Baule Mask where the beard has been interpreted by small projections and the coiffure precisely crafted and balanced. The effect is one of timelessness, of a composure which extends beyond the transitory and changeable.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Intricate Details</h4>
<p className="fs-5">The finest examples of traditional African sculpture exhibit intricate workmanship. They are clearly from the hands of an artist. The Lumbo mother and child piece captures the essence of the maternal relationship. The tilt of the head and the kneeling pose with turned feet prevent any rigid lifelessness. The large left hand cradles the entire child while the heavily lidded, downcast eyes suggest a serenity and peace. The result is a gentle vitality, affirming the universal nurturing role of mother.

</p>
<h4 className="mb-4 fs-6 lead fw-bold">Dynamic Forms</h4>
<p className="fs-5">Form in traditional African sculpture is often dynamically rendered with a surprising boldness. The artist who carved the Senufo female breaks the bonds of realism by carving pointed breasts, an elongated jaw, and enlarged arms with tremendous kinetic vitality. These forceful volumes are further paralleled by the hips, all on the same jutting horizontal plane. The cumulative effect breaks the vertical torso adding vigor and movement.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Symbolic Imagery</h4>
<p className="fs-5">Many pieces of traditional African art are aesthetically enhanced by symbolic imagery.

The presence of this first principle on pieces is visually stimulating and can form patterns, such as those found on the Dogon door.
The recurring diamond motif and the open space with the figures is conceptually intriguing.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Geometric Forms</h4>
<p className="fs-5">The geometric forms in traditional African carvings drew the attention of the early Cubists. The repetition of geometric motifs gives a piece harmony. Consider the Boa plaque mask. Here the use of the circle and the oval gives structure to the face. Even the flange of the mask is integrated into the form as are the attachment holes on the perimeter. The results is an aural effect, causing us to focus our attention on an intense face.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Harmonious Elements</h4>
<p className="fs-5">Traditional African art is more than the sum of its parts; the harmonious combining of the elements is essential. A Mambila artist from Cameroon certainly achieved this. The arts of the figure are wonderful: the compact angular legs and thighs, the convex oval abdomen, the expressive circular arm to the chin, the concave face, and the radiating beard; however, they combine to create a completely realized piece. Each section of the figure is stimulating; there are no dead spots or unrealized form. This harmony is also seen on the Ngombe Mask. The face is carefully divided into three parts. While the eyes are most expressive as double ovals in concave convex opposition, the forehead and mouth are also made expressive; the former by a vertical ridge, the latter by the open rectangular mouth which balances the eyes.

</p>
<h4 className="mb-4 fs-6 lead fw-bold">Subtle Proportions</h4>
<p className="fs-5">In contrast to the obvious qualities, African artists often are masters of understatement. In the most subtle of proportions are contained brilliant sculptural resolutions. The Kissi stone figures possess tremendous tension in compact size. On the rider statue a thin line suggests the eye, an incised cut the wrist, a deeper vertical cut the jaw. Such elemental design is also found in Lega art. In their ivory art the Lega artists work within the limitations of their medium. Both of these pieces have simple faces accomplished with precision, yet both possess presence and energy.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Dramatic Resonance</h4>
<p className="fs-5">Dramatic resonance is created in many pieces by the assembling of varied elements: shells, bones, birds skulls, fur, grass, blood, feathers, earth…. The list is limitless.

The Bamana power figure, known as a boli seems to bulge with energy and potential force.

A Loma mask seems to suggest eyes in the depths created by two strategically pla­ced horns.</p>
<h4 className="mb-4 fs-6 lead fw-bold">Affective surfaces</h4>
<p className="fs-5">Finally, as we look on a piece of traditional African sculpture, we see that the surfaces are also aesthetically significant, for they often complement the form. Surfaces can be warmly patined or richly pigmented, eroded from weather or roughly hewed; whatever the quality, they are an essential element of the aesthetic appreciation and should be minimized. The elements of the earth form and affect the surfaces, extending to us, the observers, a participation in the African world. Organic pigments, fluids, and the elemental matter of life are aesthetically transformed onto the objects. The very forces of the natural world imbue the objects with the primal energies of African life. Art and life merge, not in theory, but in fact.</p>
<h4 className="mb-4 fs-6 lead fw-bold">African art: living entities with the aesthetic voice</h4>
<p className="fs-5">African traditional sculpture, the function of which is continuously being better understood through research, visually presents to us a complex world of emotions, ideas and associative states. Individuals are united through the aesthetic object to their cultural traditions. This art provided a means for continuity in the African world. The language of the art was readable to everyone in the tribe. Here is an art rooted in the existential but soaring beyond the mundane in its spiritual and social significance.

The aesthetic experience of traditional African sculpture renews, recreates, and regenerates the very people who possess it. The powerful images ignite the imagination in its quest for spiritual realities while making that quest vivid and concrete. Superb artistry without sentimentality or nostalgia speaks across time to our yearnings. African traditional art makes the spiritual tangible, giving it physical form, allowing us to participate in its wonder. The artistry of traditional African sculpture is an experience we can enjoy.</p>

<p className="fs-5">These objects are not dead relics of a fading world; they are living entities with the aesthetic voice to.

</p>


            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default TheArtistryofTraditionalAfricanSculpturecontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MasksWithCrestsXXcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case XX: Masks With Crests</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-XX.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">An archaic Middle English meaning of the word crest is ‘pride, courage, spirit’. We find crests on many birds; the male of the species spreads and displays the crest when he courts the female. In the late nineteenth and twentieth centuries many bird species were decimated because fashion decreed the wearing of hats with feathers. In ancient armor, the crest is the plume of feathers on top of a helmet. The word also refers to a tuft on an animal’s head or a mane on his neck.
       In Africa, a crest is frequently part of the coiffure of both men and women. The hair may be shaped over an armature of wood or fiber or it may be modeled with mud or other substance that will hold its form. In Burkina Faso, double crests are used only by the Winiama.</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default MasksWithCrestsXXcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MossiFigureSculptureXcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case X: Mossi Figure Sculpture</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-X.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">‘The literature on Mossi wooden figures indicates that they have two separate uses. Some are decorative figures of women or animals to placed in the chief’s hut. Other figures, commemorating ancestors, are perhaps carried on the head during burials or borne in funerary processions as images of the deceased. These same ancestral figures may appear in sacrifices when the spirits of the royal ancestors are asked to provide for the safety of the community. They are placed on altars during the intervening periods.’</p>
       <p className="fs-6">Christopher Roy. Mossi Chief’s Figures. African Arts XV, IV, p. 52-59</p>
     </Card.Body>
   </Col>
   </Row>
  );
};

export default MossiFigureSculptureXcontent;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BonduMaskcontent = () => {
  return (
    <>

      <Card>
      <Row className="align-items-center">
      
   
      <Col xs="12" md="8" className="ps-md-3 ps-0">
     <Card.Body>
       <h4 className="mb-4 fs-5 lead fw-bold">Bondu Mask – Learning from Beauty
</h4>
       
       
    

     </Card.Body>
   </Col>
   </Row>
      


        
        <Row className="g-0 align-items-center">

          
          <Col xs="12" md="12" className="ps-md-3 ps-0">
            <Card.Body>
            <p className="fs-5">By convention, masks of the Sande Society of the Mende are called Bundu. The Sande Society dedicates women to the learning of beauty, social usefulness and moral self-realization. This mask, an old and well-worn example, illustrates in its form and iconography what girls in Sande are taught in words i.e. to maintain youthful gracefulness, tempered by seriousness, an embodiment of ancient spiritual attributes.
            Fleshy cheeks and luminous rising brow fuse with serious mouth and closed, spiritualized eyes. The road to understanding, as always, lies within the commentary of indigenous connoisseurs. These laud the eyes of the Bundu, large or closed, but knowing. The shining black forehead that is broad, wise, unmarried and the rhythmic neck rings which communicate opulence tinged with mystic associations of the head of a spirit rising from concentric rings in the water.</p>
            <p className="fs-5">Delicately blended transitions, from one world to the next, are mirrored in the qualities of the Bundu dance, which is executed with “smooth perfection of their delicate movements, the precise rhythmic timing, the graceful complete execution of each step, the one mingling with the next, so that they cannot be separated by the eyes.”</p>

            <h4 className="mb-4 fs-6 lead fw-bold">Robert Farris Thompson,</h4>

            <p className="fs-5">African Art in Motion.
            Gift of Dr. Ken Rosenhaum</p>
            

           
            
            </Card.Body>
          </Col>
        </Row>
       
        


      </Card>
    </>
  );
};

export default BonduMaskcontent;

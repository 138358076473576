import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const  ChairsandStoolsIIIcontent = () => {
  return (
    <Row className="align-items-center">
    <Col>
    <h4 className="mb-4">Case III: Chairs and Stools</h4>
      <div className="me-1 mb-1 d-inline-block">
        <img src="\img\museum\BurkinaFaso-Case-III.jpg" className="img-fluid rounded-xl" alt="card image" />
      </div>
    </Col>
  
   <Col xs="12" md="12" className="ps-md-3 ps-0">
     <Card.Body>
       
       <p className="fs-6">Chairs and stools with three legs are for men. Stools with four legs are for women. Like most masks and figures, they are carved from one piece of wood. The beauty of the grain of the wood is revealed on the seat. Over time and with hard use the seat and handle acquire a patina, highly valued by the Burkinabe.</p>
       <p className="fs-6">Men’s reclining chairs, dangalo, are for relaxing in the cool of the evening after a hard day’s work. Men’s stools may be for working or sitting at a meeting (palaver). Women’s stools are used for working. The handle, in the form of a head on a long neck, makes the stool easy to carry around. The size and shape make it easy to tuck under the skirt and between the legs.</p>
       <p className="fs-6">‘Decoration of the chairs ranges from simple notches to elaborate patterns of triangles, rectangles, diamonds and extraordinary carvings of animals like the crocodile. Carvers still produce chairs and stools in the traditional forms.</p>
       <p className="fs-6">People treasure old chairs and stools as mementos, passing them down from generation to generation…Stools of the type shown here, from the Mouhoun River area, are sold and traded all over the border area between Mali and Burkina Faso. They are often mistakenly attributed to the Bwa, Bobo, Bamana, Mossi and other peoples.’ Roy, op cit.</p>
     </Card.Body>
   </Col>
   </Row>
     );
};

export default ChairsandStoolsIIIcontent;

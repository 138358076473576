import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const MuseumChristianArtContent = () => {
  return (
    <>

      <Card className='cta-2'>
      <Row className="align-items-center">
          <Col xs="12" md="4">

</Col>

          <Col  className="align-center">

                  <img src="/img/banner/title.png"   alt="card image" />
          </Col>
    
        </Row>
        <Row className="g-0 align-items-center">
   
          <Col xs="12" md="4">

                  <img src="/img/banner/book-cover.jpg" className="card-img h-100 scale" alt="card image" />
          </Col>
          <Col xs="12" md="8" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Africanizing Christian Art</h4>

              <p>
              Please join us for the opening of Africanizing Christian Art, an Exhibition of Kevin Carroll and Yoruba Christian Art in Nigeria. This ground breaking exhibition introduces the unique and radical work of Fr. Kevin Carroll, SMA(1920-1993) in Nigeria during thebook cover years leading up to African Independence following World War II and for decades after. Based on the new book (2012) of the same title by Dr. Nicholas Bridger (Ohlone College, CA), this first American exhibition brings together numerous works of eminent Nigerian artists from the specialized collections of both the Irish and the American Provinces of the SMA, who are our collaborating sponsors. The Vatican’s Pontifical Urbaniana University (Rome) has loaned a rare and significant set of pieces, as well. The exhibition is organized and curated by Dr. Bridger and consultant Dr. Catie Cadge-Moore (De Anza College, CA), both working closely with Robert Koenig, Director of the SMA African Art Museum.
              </p>
              <p>
              Africanizing Christian Art presents the mid-20th century encounter between Catholic Christianity and the visual culture of the Yoruba, a prominent west African people of southwest Nigeria, under the conditions of late colonialism. The first institutional project to develop an indigenous Christian art (a process called variously inculturation, indigenization and adaptation),the Oye-Ekiti Workshop (1947-1954), conceived by Dr. Patrick Kelly, head of the SMA’s Irish Province, and overseen by Frs. Kevin Carroll and Sean O’Mahoney, promoted 1) resistance to the colonial imposition of European religious imagery, 2) the development of a Yoruba Christian art, as well as 3) the preservation of customary Yoruba art practices, then suffering under the impact of British political and economic rule. Under Fr. Carroll’s creative direction, the Oye-Ekiti Workshop, and our exhibition, includes three generations of outstanding Yoruba artists, including Areogun of Osi-Ilorin (1880-1954), George Bandele Areogun of Osi-Ilorin (1910-1995) and Lamidi Olonade Fakeye of Ila-Orangun (1925-2009). Other Nigerian contemporary artists, who collaborated with Kevin Carroll, are featured in the exhibition, including Uhrobo painter and multimedia specialist Bruce Onobrakpeya of Lagos, who is still active. </p>

            </Card.Body>
          </Col>
        </Row>
               <Row className="g-0 align-items-center">

                 <Col xs="12" md="8" className="ps-md-3 ps-0">
                   <Card.Body>
                     <h4 className="mb-4">Lamidi Olonade Fakeye</h4>

                     <p>
                     Beginning in 1947, Father Carroll observed and first documented the traditional arts of the Ekiti area of Yorubaland and later introduced an Africanized Christian art in his Yoruba Religious Carving (1966), which became mandatory reading for an early generation of African art scholars. A long-overdue follow-up, Dr. Bridger’s new book is currently available both at the African Art Museum of the SMA Fathers and online at: www.AfricanizingChristianArt.com. Many of the artists Carroll worked with, and featured in this show, have become prominent in the world’s greatest African Art collections, including the Metropolitan Museum of Art in New York, the British Museum in London and the Musee du Quai Branly in Paris, to name a few.
                     </p>
                     <p>
                     Neither published nor shown in public before, many of the surprising art works presented here were recently located and documented by Dr. Bridger during a decade of archival and field research in Nigeria, Ireland and Vatican City. From the many pieces on display, a few of the most notable and unusual include:
                     </p>
                     <p>
                     Lamidi Olonade Fakeye: a panel representing the Yoruba Transfiguration, Carroll’s radical repositioning of Christ as the fulfillment of the Yoruba religion – Lamidi’s last Christian carving (2009).
                     </p>

                   </Card.Body>
                 </Col>
                      <Col xs="12" md="4">
                                          <img src="/img/banner/m_citizen_art.jpg" className="card-img h-100 scale" alt="card image" />
                                          <p>
                                          Yoruba Transfiguration panel by Lamidi Fakeye showing Christ as the fulfillment of Yoruba traditional religion.
                                          </p>
                                  </Col>
               </Row>
   <Row className="g-0 align-items-center">
          <Col xs="12" md="4">

                  <img src="/img/banner/king1-139x300.jpg"  className="card-img h-100 scale" alt="card image" />
                  <p>
                  Areogun of Osi carved this Christian King in 1954, his final work.
                  </p>
          </Col>
          <Col xs="12" md="4">

<img src="/img/banner/Ill-2-300x224.jpg" className="card-img" alt="card image" />
Carved Altar Missal Stand by George Bandele.
</Col>
          <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Areogun of Osi / George Bandele </h4>

              <p>
          * Areogun of Osi: two of his large Three Kings statues, the last pieces he carved (1954) and never shown together before this exhibition.</p>
              <p>
              * George Bandele: an altar missal stand with multiple carved biblical scenes, as well as a very special set of Three Yoruba Kings sent to Rome for the 1950 Mission lands Exhibition and subsequently out of public view for the last half a century.
          </p>

            </Card.Body>
          </Col>
        </Row>
        <Row className="align-items-center">
        <Col xs="12" md="4" className="ps-md-3 ps-0">
            <Card.Body>
              <h4 className="mb-4">Three Kings Nativity Set </h4>

              <p>
              Three Kings from the Nativity set sent by the Oye-Ekiti Workshop as the global debut of the Yoruba Christian genre to the Vatican’s Missionlands Exhibition (1950) representing the work of artists George Bandele, Christiana Ojo and Jimoh Adetoye (loaned by the Pontifical Urbaniana University).  
               </p>

            </Card.Body>
          </Col>
          <Col  className="align-center"  md="8">

                  <img src="/img/banner/Rome-3-Kings-688x600.jpg" className="card-img h-100 scale"  alt="card image" />
          </Col>
    
        </Row>
      </Card>
    </>
  );
};

export default MuseumChristianArtContent;
